import { apiGet, apiPut } from './apiWrapper';
import { NOTIFICATIONS_ENDPOINT } from './constants';

export const getNotificationsService = (roleId, roleType, offset) => {
  let queries = `?roleId=${roleId}&roleType=${roleType}`;

  if (offset !== undefined) {
    queries += `&offset=${offset}`;
  }

  return apiGet(`${NOTIFICATIONS_ENDPOINT}/${queries}`)();
};

export const updateLastReadNotificationService = (
  roleId,
  roleType,
  notificationTimestamp,
  type
) =>
  apiPut(NOTIFICATIONS_ENDPOINT)({
    roleId,
    roleType,
    notificationTimestamp,
    type,
  });
