import ElementWithTooltip from 'common/components/element-with-tooltip';
import MatchIcon from 'common/components/icons/match-icon';
import PropTypes from 'prop-types';
import { CREATE_MATCH_BTN_TOOLTIP } from './constants';

const CreateMatchButton = ({ onClick }) => (
  <ElementWithTooltip tooltip={CREATE_MATCH_BTN_TOOLTIP}>
    <div onClick={onClick}>
      <MatchIcon />
    </div>
  </ElementWithTooltip>
);

CreateMatchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateMatchButton;
