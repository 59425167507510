import { useCallback } from 'react';
import ItemsListAsideView from './components/ItemsListAsideView';
import { ITEMS_LIST_ASIDE_TYPE } from './constants';

const AsideView = props => {
  const renderAsideComponent = useCallback(() => {
    switch (props?.asideViewType) {
      case ITEMS_LIST_ASIDE_TYPE:
        const { asideViewTypeProps, isOpened, onClose } = props;

        return (
          <ItemsListAsideView
            {...asideViewTypeProps}
            isOpened={isOpened}
            onClose={onClose}
          />
        );
      default:
        return null;
    }
  }, [props]);

  return <>{renderAsideComponent()}</>;
};

AsideView.propTypes = {};

export default AsideView;
