import { COUNTRIES } from 'common/constants/geoData';
import { formatCountryOptions } from 'common/utils/formatting';
import { MEDIA_URL } from '_config';

export const BIO_VIEW = 'Bio';
export const MY_GAME_EVENTS = 'Games';
export const CLUBS_VIEW = 'Teams';
export const MATCH_LISTS_VIEW = 'Team Games';
export const STATS_VIEW = 'Stats';

export const tabURLPaths = {
  BIO_VIEW: '',
  MY_GAME_EVENTS: 'my-games', // TODO to constant
};

export const editBioModalTitle = [{ text: 'Edit Bio' }];
export const modals = {
  BIO: 'BIO_MODAL',
};
export const BIO_FORM_KEYS = [
  'firstName',
  'lastName',
  // 'birthDate',
  'city',
  'country',
];
export const bioFormFields = [
  { key: 'firstName', label: 'first name' },
  { key: 'lastName', label: 'last name' },
  // { key: 'birthDate', label: 'birth date', type: 'date' },
  { key: 'city', label: 'current city' },
  {
    key: 'country',
    label: 'nationality',
    type: 'select',
    options: formatCountryOptions(COUNTRIES),
  },
];

export const signatureFormFields = [
  { key: 'shirtNumber', label: 'number', type: 'number', min: 0, max: 99 },
  { key: 'nickname', label: 'call sign' },
  {
    key: 'color',
    label: 'color',
    type: 'select',
    options: [
      {
        value: 'violet',
        text: 'violet',
      },
      {
        value: 'blue',
        text: 'blue',
      },
      {
        value: 'yellow',
        text: 'yellow',
      },
      {
        value: 'green',
        text: 'green',
      },
    ],
  },
];
export const SIGNATURE_FORM_KEYS = ['nickname', 'shirtNumber', 'color'];

export const staticProps = {
  signatureSettings: {
    label: 'Your Signature',
    nicknameLabel: 'call sign',
    numberLabel: 'number',
    noSignatureLabel: 'none',
    onSaveLabel: 'rebrand',
  },
  playerBannerImgProps: {
    webpURL: MEDIA_URL + '/webp/player-profile-banner-v3.webp',
    jpgURL: MEDIA_URL + '/jpg/player-profile-banner-v3.jpeg',
    jpgDesktopURL: MEDIA_URL + '/jpg/player-profile-banner-v4.jpeg',
    webpDesktopURL: MEDIA_URL + '/webp/player-profile-banner-v4.webp',
    alt: 'player banner',
  },
};

export const NICKNAME_SIGNATURE_TYPE = 'NICKNAME_SIGNATURE_TYPE';
export const NUMBER_SIGNATURE_TYPE = 'NUMBER_SIGNATURE_TYPE';
export const NO_SIGNATURE = 'NO_SIGNATURE';
export const SIGNATURE_TYPES = [
  NICKNAME_SIGNATURE_TYPE,
  NUMBER_SIGNATURE_TYPE,
  NO_SIGNATURE,
];

export const USER_BIO_SUCCESSFULLY_UPDATED_MESSAGE = 'Bio updated.';
export const USER_SIGNATURE_SUCCESSFULLY_UPDATED_MESSAGE = 'Signature updated.';

export const ADD_PROFILE_IMAGE_TOOLTIP = 'add profile image';

export const PROFILE_USE_USER_IMAGE_SUBSCRIPTION_ID = 'profile-useUser-image';
export const PROFILE_USE_USER_PLAYER_CARD_SUBSCRIPTION_ID =
  'profile-useUser-playerCard';
