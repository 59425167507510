import { PrivatePageWrapper } from 'features/page-wrapper';
import { useAuthContext } from 'contexts/auth';
import React, { useState, useCallback, useEffect } from 'react';
import Button, {
  BUTTON_LIGHT_VARIANT,
  BUTTON_REGULAR_VARIANT,
} from 'common/components/button';
import {
  // ASIDE_HEADING_LABEL,
  PASSWORD_SETTINGS,
  RETIRE_SETTINGS,
  SETTINGS_ITEMS,
  initPasswordState,
  passwordFormFields,
  deleteProfileFormFields,
  CONFIRM_MODAL_BUTTON_LABEL,
  PASSWORD_UPDATE_CONFIRM_MESSAGE,
  DELETE_PROFILE_CONFIRM_MESSAGE,
  PASSWORD_SUCCESSFULLY_UPDATED_MESSAGE,
  PASSWORD_FORM_CTA_LABEL,
  RETIRE_FORM_CTA_LABEL,
} from './constants';
import AsideNav from 'common/components/aside-nav';
import Form from 'common/components/form';
import style from './Settings.module.scss';
import { useApi, useCommonClassNames, useForm } from 'common/hooks';
import { updatePasswordSchema, deleteProfileSchema } from './schemas';
import Modal, { DARK_THEME } from 'features/modal';
import {
  deleteProfileService,
  updatePasswordService,
} from 'common/api/settingsService';
import { DEFAULT_USER_IMAGE } from 'common/constants/common-props';
import Headline from 'common/components/headline/Headline';
import HeroHeading from 'common/components/hero-heading';
import { LANDING_PAGE } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { formatImage } from 'common/utils/formatting';
import ReactGA from 'react-ga';
import { REACT_GA_SETTINGS_PAGE } from 'common/constants/react-google-analytics/pages';
import {
  REACT_GA_DELETE_PROFILE_MODAL,
  REACT_GA_PASSWORD_MODAL,
} from 'common/constants/react-google-analytics/modals';
import {
  REACT_GA_CHANGE_PASSWORD_EVENT,
  REACT_GA_DELETE_PROFILE_EVENT,
} from 'common/constants/react-google-analytics/events';

const Settings = () => {
  const { user, setUser } = useAuthContext();
  const { apiWrapper } = useApi();
  const navigate = useNavigate();
  const [activeSettingsItem, setActiveSettingsItem] = useState(
    SETTINGS_ITEMS[0]
  );
  const [modalOpened, setModalOpened] = useState(null);

  const { animationClassName } = useCommonClassNames(modalOpened);

  const closeConfirmationModal = useCallback(() => {
    setModalOpened(null);
  }, []);

  const confirmPasswordUpdate = useCallback(
    async data => {
      await apiWrapper(
        () => updatePasswordService(data),
        PASSWORD_SUCCESSFULLY_UPDATED_MESSAGE
      );
      ReactGA.event(REACT_GA_CHANGE_PASSWORD_EVENT);
      closeConfirmationModal();
    },
    [apiWrapper, closeConfirmationModal]
  );
  const confirmProfileDelete = useCallback(
    async data => {
      const response = await apiWrapper(
        () => deleteProfileService(data),
        null,
        () => {},
        false,
        'Invalid password.'
      );

      if (!response.errors) {
        setUser(null);
        navigate(LANDING_PAGE);
      }
      ReactGA.event(REACT_GA_DELETE_PROFILE_EVENT);
      closeConfirmationModal();
    },

    [apiWrapper, closeConfirmationModal, navigate, setUser]
  );

  const openPasswordConfirmationModal = useCallback(
    data => {
      const modalProps = {
        type: PASSWORD_SETTINGS,
        question: PASSWORD_UPDATE_CONFIRM_MESSAGE,
        onClick: () => {
          confirmPasswordUpdate({
            password: data.password,
            newPassword: data.newPassword,
          });
        },
        btnLabel: CONFIRM_MODAL_BUTTON_LABEL,
        reactGAModalView: REACT_GA_PASSWORD_MODAL,
      };
      setModalOpened(modalProps);
    },
    [confirmPasswordUpdate]
  );

  const {
    submit: submitPasswordUpdate,
    getInputProps: getPasswordInputProps,
    resetForm: resetPasswordForm,
  } = useForm(
    initPasswordState,
    passwordFormFields,
    updatePasswordSchema,
    openPasswordConfirmationModal
  );

  const openDeleteProfileConfirmationModal = useCallback(
    data => {
      const modalProps = {
        type: RETIRE_SETTINGS,
        question: DELETE_PROFILE_CONFIRM_MESSAGE,
        onClick: () => confirmProfileDelete(data),
        btnLabel: CONFIRM_MODAL_BUTTON_LABEL,
        reactGAModalView: REACT_GA_DELETE_PROFILE_MODAL,
      };
      setModalOpened(modalProps);
    },
    [confirmProfileDelete]
  );

  const {
    submit: submitProfileDelete,
    getInputProps: getDeleteProfileInputProps,
    resetForm: resetDeleteProfileForm,
  } = useForm(
    { password: { value: '' } },
    deleteProfileFormFields,
    deleteProfileSchema,
    openDeleteProfileConfirmationModal
  );

  const selectSettingsItem = useCallback(
    settingsItem => () => {
      resetPasswordForm();
      resetDeleteProfileForm();
      setActiveSettingsItem(settingsItem);
    },
    [resetDeleteProfileForm, resetPasswordForm]
  );

  const getListItems = useCallback(
    () =>
      SETTINGS_ITEMS.map(item => ({
        label: item,
        onClick: selectSettingsItem(item),
        isActive: item === activeSettingsItem,
      })),
    [activeSettingsItem, selectSettingsItem]
  );

  const getActiveSettingsHeadingElm = useCallback(
    () => (
      <h1
        className={style.contentHeading}
      >{`${activeSettingsItem} Settings`}</h1>
    ),
    [activeSettingsItem]
  );

  const inputProps = {
    [PASSWORD_SETTINGS]: getPasswordInputProps(),
    [RETIRE_SETTINGS]: getDeleteProfileInputProps(),
  };

  const getSubmitFunction = useCallback(() => {
    const functions = {
      [PASSWORD_SETTINGS]: submitPasswordUpdate,
      [RETIRE_SETTINGS]: submitProfileDelete,
    };

    return functions[activeSettingsItem];
  }, [activeSettingsItem, submitPasswordUpdate, submitProfileDelete]);

  const activeFormCTAProps = {
    [PASSWORD_SETTINGS]: {
      label: PASSWORD_FORM_CTA_LABEL,
      className: '',
    },
    [RETIRE_SETTINGS]: {
      label: RETIRE_FORM_CTA_LABEL,
      className: style.retireForm,
    },
  };

  useEffect(() => {
    ReactGA.pageview(REACT_GA_SETTINGS_PAGE);
  }, []);

  return (
    <PrivatePageWrapper>
      <main
        className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
      >
        <HeroHeading
          img={
            user?.image?.url
              ? formatImage(user?.image?.url)
              : DEFAULT_USER_IMAGE
          }
          heading={`${user?.firstName} ${user?.lastName}`}
          subtext={user?.email}
        />

        {/* Settings section */}
        <div className={style.content}>
          <div>
            <AsideNav listItems={getListItems()} />
          </div>
          <section
            className={`${style.mainContent} ${style['wrapper__blowUpExit']}`}
            key={activeSettingsItem}
          >
            {getActiveSettingsHeadingElm()}
            <div
              className={`${activeFormCTAProps[activeSettingsItem].className}`}
            >
              {/* ActiveForm  */}
              <Form
                buttonVariant={BUTTON_REGULAR_VARIANT}
                inputs={inputProps[activeSettingsItem]}
                onConfirm={getSubmitFunction()}
                buttonLabel={activeFormCTAProps[activeSettingsItem].label}
              />
            </div>
          </section>
        </div>
      </main>

      {/* Confirmation Modal */}
      {modalOpened?.type && (
        <Modal
          title={[{ text: activeSettingsItem }]}
          onClose={closeConfirmationModal}
          variant='center'
          theme={DARK_THEME}
          reactGAModalView={modalOpened?.reactGAModalView}
        >
          <div
            className={`mobile-modal-content--center ${style.modalContentWrapper}`}
          >
            <Headline mainText={modalOpened?.question} size={3} />
            <Button
              onClick={modalOpened?.onClick}
              variant={BUTTON_LIGHT_VARIANT}
            >
              {modalOpened?.btnLabel}
            </Button>
          </div>
        </Modal>
      )}
    </PrivatePageWrapper>
  );
};

export default Settings;
