import PropTypes from 'prop-types';
import Cluster11Image from '../cluster-11-image';
import { BLUE_STARS_LOGO } from '../cluster-11-image/constants';
import Headline, { HeadlineTextDataType } from '../headline';
import './BlankScreenPlaceholder.scss';

const className = 'c-BlankScreenPlaceholder';

const BlankScreenPlaceholder = ({ mainText, subText }) => (
  <div className={className}>
    <Cluster11Image iconMode variant={BLUE_STARS_LOGO} />
    <Headline mainText={mainText} subText={subText} variant='light' size={3} />
  </div>
);

BlankScreenPlaceholder.propTypes = {
  mainText: PropTypes.shape(HeadlineTextDataType),
  subText: PropTypes.shape(HeadlineTextDataType),
};

export default BlankScreenPlaceholder;
