import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import IconWithLabelCTA from 'common/components/icon-with-label-cta';
import Image, { ImagePropTypes } from 'common/components/image';
import Text, { TextPropTypes } from 'common/components/text';
import {
  COMPONENT_VARIANT_MEDIUM,
  COMPONENT_VARIANT_SMALL,
  CONFIRM_ICON,
  icons,
  MORE_ICON,
  REJECT_ICON,
} from './constants';
import './NotificationOfferCard.scss';
import ClubLogo, {
  ClubLogoPropTypes,
} from 'common/components/club-logo/ClubLogo';

const className = 'c-NotificationOfferCard';

// NOTE bc of the currently mixed img system (img and club logo format) component is set to figure out what it should render based on the data format
const NotificationOfferCard = ({
  variant,
  img,
  onImgClick,
  message,
  subText,
  onMsgClick,
  controlButtons,
  isUnread,
  cardLevelClickHandler,
}) => {
  const clickableMessageClassName = onMsgClick ? `${className}__pointer` : '';
  const isUnreadClassName = isUnread ? `${className}--unread` : '';
  const fullCardClickableClassName = cardLevelClickHandler
    ? `${className}__pointer`
    : '';

  const variantClassName =
    variant === COMPONENT_VARIANT_MEDIUM
      ? `${className}__medium`
      : variant === COMPONENT_VARIANT_SMALL
      ? `${className}__small`
      : className;

  const renderControlButtons = useCallback(
    () =>
      Children.toArray(
        controlButtons?.map(({ iconType, onClick }) => (
          <IconWithLabelCTA
            handler={onClick}
            Icon={icons[iconType]}
            iconProps={{ color: '#aaa' }}
          />
        ))
      ),
    [controlButtons]
  );

  const renderImage = useCallback(
    () => (img?.shape ? <ClubLogo {...img} /> : <Image {...img} />),
    [img]
  );

  return (
    <div
      className={`${variantClassName} ${isUnreadClassName} ${fullCardClickableClassName}`}
      onClick={cardLevelClickHandler}
    >
      <div className={`${variantClassName}__img-wrapper`}>
        <button
          onClick={onImgClick}
          className={`${variantClassName}__img-button-wrapper`}
        >
          {renderImage()}
        </button>
      </div>

      <div
        className={`${variantClassName}__message-controls-wrapper ${clickableMessageClassName}`}
      >
        <div className={`${className}__text-wrapper`}>
          <Text data={message} color='light' />

          {subText && (
            <div className={`${className}__text-datetime`}>
              <Text data={subText} color='light' isSmaller={true} />
            </div>
          )}
        </div>

        {controlButtons.length > 0 && (
          <div className={`${variantClassName}__controls-wrapper`}>
            {renderControlButtons()}
          </div>
        )}
      </div>
    </div>
  );
};

NotificationOfferCard.defaultProps = {
  message: [],
  controlButtons: [],
};

export const NotificationOfferCardPropTypes = {
  variant: PropTypes.oneOf([COMPONENT_VARIANT_SMALL, COMPONENT_VARIANT_MEDIUM]),
  img: PropTypes.oneOf([
    PropTypes.shape(ImagePropTypes),
    PropTypes.shape(ClubLogoPropTypes),
  ]),
  onImgClick: PropTypes.func,
  cardLevelClickHandler: PropTypes.func,
  message: PropTypes.shape(TextPropTypes),
  subText: PropTypes.shape(TextPropTypes),
  onMsgClick: PropTypes.func,
  controlButtons: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: PropTypes.oneOf([CONFIRM_ICON, REJECT_ICON, MORE_ICON])
        .isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  isUnread: PropTypes.bool,
};

NotificationOfferCard.propTypes = NotificationOfferCardPropTypes;

export default NotificationOfferCard;
