import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import './ElementWithTooltip.scss';

const className = 'c-ElementWithTooltip';

const ElementWithTooltip = ({ children, tooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const activeTooltipClassName = showTooltip
    ? `${className}__tooltip--active`
    : '';

  const onHover = useCallback(e => {
    e.stopPropagation();
    setShowTooltip(true);
  }, []);

  const onHoverOff = useCallback(e => {
    e.stopPropagation();
    setShowTooltip(false);
  }, []);

  return (
    <div className={className}>
      <p className={`${className}__tooltip ${activeTooltipClassName}`}>
        {tooltip}
      </p>
      <div
        className={`${className}__element-wrapper`}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOff}
      >
        {children}
      </div>
    </div>
  );
};

ElementWithTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  element: PropTypes.element,
};

export default ElementWithTooltip;
