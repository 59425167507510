export const GAME_EVENTS_SUBSCRIPTIONS_TAB = 'Subscriptions';
export const ORGANIZED_GAME_EVENTS_TAB = 'Hosted';
export const noGamesMessageStaticProps = {
  mainText: [
    {
      text: ['No game events found.'],
    },
  ],
  subText: [
    { text: 'You can create or join a game event and it will appear here.' },
  ],
};
