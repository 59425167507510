import { useState, useCallback } from 'react';
import { modals } from '../constants';

const useModalAndTogglers = () => {
  // signature settings
  const [isSignatureSettingsExpanded, setIsSignatureSettingsExpanded] =
    useState(false);

  const toggleSignatureSettings = useCallback(() => {
    setIsSignatureSettingsExpanded(current => !current);
  }, []);

  // modal
  const [modalOpened, setModalOpened] = useState(null);

  const openBioModal = useCallback(() => {
    setModalOpened(modals.BIO);
  }, []);

  const isBioModalOpened = () => modalOpened === modals.BIO;

  const [selectedClubForMatchOffer, setSelectedClubForMatchOffer] = useState();

  const closeModal = useCallback(() => {
    setModalOpened(null);
    setSelectedClubForMatchOffer(null);
  }, [setModalOpened]);

  return {
    isSignatureSettingsExpanded,
    toggleSignatureSettings,
    isBioModalOpened,
    closeModal,
    modalOpened,
    openBioModal,

    // match modal
    selectedClubForMatchOffer,
    setSelectedClubForMatchOffer,
  };
};

export default useModalAndTogglers;
