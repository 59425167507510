import React from 'react';
import PropTypes from 'prop-types';
import './ButtonFloating.scss'; // Import your SCSS file
import EditIcon from '../icons/edit-icon';

const className = 'c-ButtonFloating';

const ButtonFloating = ({ onClick, Icon }) => {
  return (
    <div className={className}>
      <button className={`${className}__button`} onClick={onClick}>
        {/* <Icon /> */}
        <EditIcon />
      </button>
    </div>
  );
};

ButtonFloating.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonFloating;
