import { useCallback, Children } from 'react';
import PropTypes from 'prop-types';
import AsideView from '../aside-view';
import './ItemsListAsideView.scss';
import NotificationOfferCard, {
  NotificationOfferCardPropTypes,
} from 'common/components/cards/notification-offer-card';

const className = 'c-ItemsListAsideView';

const ItemsListAsideView = ({
  isOpened,
  onClose,
  title,
  viewLink,
  elements,
  items,
}) => {
  const renderItems = useCallback(() => {
    if (elements.length) {
      return elements;
    } else if (items.length) {
      return Children.toArray(
        items.map(item => <NotificationOfferCard {...item} />)
      );
    } else {
      return null;
    }
  }, [elements, items]);

  const toRender = useCallback(() => {
    return (
      <div className={`${className}__content`}>
        <h2 className={`${className}__title`}>{title}</h2>

        <div className={`${className}__elements`}>{renderItems()}</div>
      </div>
    );
  }, [renderItems, title]);

  return (
    <AsideView onClose={onClose} isOpened={isOpened} toRender={toRender} />
  );
};

ItemsListAsideView.defaultProps = {
  elements: [],
};

ItemsListAsideView.propTypes = {
  title: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  elements: PropTypes.array,
  items: PropTypes.arrayOf(PropTypes.shape(NotificationOfferCardPropTypes)),
};

export default ItemsListAsideView;
