// TODO review which of these methods are still needed
const getClubMembershipIds = activeRole =>
  activeRole?.activeClubMemberships?.map(item => item?.club);

const getPlayerMembershipIds = activeRole =>
  activeRole?.activeClubMemberships?.map(item => item?.player);

export const playerRoleCheckers = activeRole => ({
  isActiveClubMembership: club =>
    !!getClubMembershipIds(activeRole)?.find(item => item === club),
  getPendingTransferOffer: club => {
    const offer = activeRole?.pendingTransferOffers?.find(item =>
      item?.club?._id === club
        ? { id: item?._id, createdBy: item?.[item?.createdBy]?._id }
        : null
    );

    return offer
      ? { id: offer?._id, createdBy: offer?.[offer?.createdBy]?._id }
      : null;
  },
});

export const gmRoleCheckers = activeRole => ({
  isPlayerActiveMember: player =>
    !!getPlayerMembershipIds(activeRole)?.find(item => item === player),
  getPlayerOfferResponsePending: player => {
    const offer = activeRole?.pendingTransferOffers?.find(
      item => item?.player?._id === player
    );

    return offer
      ? {
          id: offer?._id,
          createdBy: offer?.[offer?.createdBy]?._id,
          responseFrom: offer?.responseFrom,
        }
      : null;
  },
});

export const markNotificationsAsRead = (notificationsData = []) => {
  const updatedData = notificationsData?.data?.map(item => {
    return Object.assign({}, item, { isUnread: false });
  });
  const formattedData = { data: updatedData, meta: { unreadNotifications: 0 } };

  return formattedData;
};
