import PropTypes from 'prop-types';
import { PRIVATE_PAGE_TYPE } from 'common/constants/general';
import PageWrapper from '../../PageWrapper';
import { ASIDE_VIEW_TYPES } from 'features/aside-view/constants';

const PrivatePageWrapper = ({
  children,
  classNames,
  fullscreen,
  asideViewProps,
}) => (
  <PageWrapper
    type={PRIVATE_PAGE_TYPE}
    classNames={classNames}
    fullscreen={fullscreen}
    asideViewProps={asideViewProps}
  >
    {children}
  </PageWrapper>
);

PrivatePageWrapper.propTypes = {
  asideViewProps: PropTypes.shape({
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    asideViewType: PropTypes.oneOf(ASIDE_VIEW_TYPES),
    asideViewTypeProps: PropTypes.object,
  }),
  classNames: PropTypes.string,
  fullscreen: PropTypes.bool,
};

export default PrivatePageWrapper;
