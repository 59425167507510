import PropTypes from 'prop-types';
import DropdownMenu, {
  // BOTTOM_DROPDOWN_SIDE,
  RIGHT_DROPDOWN_SIDE,
} from 'common/components/dropdown-menu';
import IconWithLabelCTA, {
  VERTICAL_LAYOUT,
} from 'common/components/icon-with-label-cta';
import MenuIcon from 'common/components/icons/menu-icon';
import LogoIconLink from 'features/navbar/components/LogoIconLink';
import Nav from 'features/navbar/components/nav';
import { SETTINGS_ITEM } from 'features/navbar/constants';
// import Notifications from 'features/notifications';
import Search from 'features/search';
import { Children, useCallback } from 'react';
import { STREET_PATH } from 'routes/constants';
import style from '../../Navbar.module.scss';
import { scrollToTop } from 'common/utils/dom';
import ClubLogo from 'common/components/club-logo';
import { useWindowSizeContext } from 'contexts/window-size';
import { INACTIVE_CLUB_VALUE } from 'common/api/constants';
import Image from 'common/components/image';
import AddCircleIcon from 'common/components/icons/add-circle-icon';
import { CREATE_GAME_EVENT_BTN_TOOLTIP } from 'features/game-event-features/create-edit-game-event/constants';
import { useNavigation } from 'common/hooks';
import FootballIcon from 'common/components/icons/football-icon';

const DesktopNavigation = ({
  // openCreateClubModal,
  openCreateGameEventModal,
  // toggleOffersAside,
  getActiveRoleProps,
  // getRoleItems,
  // userRoleDropdownOpened,
  userDropdownOpened,
  // toggleUserRoleDropdown,
  toggleUserDropdown,
  // playerRoleItemProps,
  logoutItem,
  numberOfOffers,
}) => {
  const { isDesktopView } = useWindowSizeContext();
  const { navigateToMyGames } = useNavigation();
  const getSettingsDropdownItems = useCallback(
    () => [SETTINGS_ITEM, logoutItem],
    [logoutItem]
  );

  const renderNavItems = useCallback(
    items =>
      Children.toArray(
        items.map(item => {
          const { ComponentItem, ...otherProps } = item;

          return (
            <li>
              {ComponentItem ? (
                <ComponentItem {...otherProps} />
              ) : (
                <IconWithLabelCTA {...item} layout={VERTICAL_LAYOUT} />
              )}
            </li>
          );
        })
      ),
    []
  );

  const renderNavItemGroups = useCallback(() => {
    const items = [
      [
        {
          Icon: AddCircleIcon,
          label: isDesktopView && CREATE_GAME_EVENT_BTN_TOOLTIP,
          onlyLabel: isDesktopView,
          handler: openCreateGameEventModal,
        },
        // NOTE FAS switching off create team feature
        // {
        //   Icon: CreateClubIcon,
        //   label: isDesktopView && 'create team',
        //   onlyLabel: isDesktopView,
        //   handler: openCreateClubModal,
        // },
        // NOTE FAS switching off offers
        // {
        //   Icon: OffersIcon,
        //   label: isDesktopView && 'offers',
        //   onlyLabel: isDesktopView,
        //   handler: toggleOffersAside,
        //   numberOfUnread: numberOfOffers,
        // },
        // {
        //   ComponentItem: Notifications,
        //   iconLabel: isDesktopView && 'notifications',
        //   onlyLabel: isDesktopView,
        //   dropdownSide: BOTTOM_DROPDOWN_SIDE,
        //   smallVariantNotificationCard: true,
        // },
        {
          Icon: FootballIcon,
          label: isDesktopView && 'my games',
          onlyLabel: isDesktopView,
          handler: navigateToMyGames,
        },
      ],
    ];

    return Children.toArray(
      items.map(item => (
        <div className={style['nav-desktop-items']}>{renderNavItems(item)}</div>
      ))
    );
  }, [
    isDesktopView,
    navigateToMyGames,
    openCreateGameEventModal,
    renderNavItems,
  ]);

  const renderActiveRoleIcon = useCallback(() => {
    const props = getActiveRoleProps();
    const retiredItemClassName =
      props?.status === INACTIVE_CLUB_VALUE ? style['retired-item'] : '';

    return props?.logo || props?.image ? (
      <div
        className={`${style['gm-role-icon-wrapper-desktop']} ${retiredItemClassName}`}
        onClick={props.handler}
      >
        {props?.logo && <ClubLogo {...props.logo} />}
        {props?.image && <Image {...props?.image} />}
        <span>{props?.label}</span>
      </div>
    ) : (
      <div className={retiredItemClassName}>
        <IconWithLabelCTA {...props} />
      </div>
    );
  }, [getActiveRoleProps]);

  return (
    <Nav>
      <div onClick={scrollToTop}>
        <LogoIconLink to={STREET_PATH} />
      </div>

      {renderNavItemGroups()}

      <Search />
      <div className={style['user-links-wrapper']}>
        <div className={style['active-role-wrapper']}>
          {renderActiveRoleIcon()}
        </div>

        {/* NOTE FAS switching off the roles feature as a part of the switching off the Club feature */}
        {/* User Role Dropdown */}
        {/* <DropdownMenu
          items={getRoleItems()}
          pinnedItems={[playerRoleItemProps]}
          isOpened={userRoleDropdownOpened}
          onToggle={toggleUserRoleDropdown}
          dropdownSide={RIGHT_DROPDOWN_SIDE}
          onlyLabel={isDesktopView}
          iconLabel={isDesktopView && 'profiles'}
          {...SWITCH_ROLE_ITEM}
        /> */}

        <DropdownMenu
          items={getSettingsDropdownItems()}
          isOpened={userDropdownOpened}
          onlyLabel={isDesktopView}
          iconLabel={isDesktopView && 'settings'}
          onToggle={toggleUserDropdown}
          Icon={MenuIcon}
          dropdownSide={RIGHT_DROPDOWN_SIDE}
        />
      </div>
    </Nav>
  );
};

DesktopNavigation.propTypes = {
  // openCreateClubModal: PropTypes.func.isRequired,
  openCreateGameEventModal: PropTypes.func.isRequired,
  // toggleOffersAside: PropTypes.func.isRequired,
  getActiveRoleProps: PropTypes.func.isRequired,
  // getRoleItems: PropTypes.func.isRequired,
  // userRoleDropdownOpened: PropTypes.bool,
  userDropdownOpened: PropTypes.bool,
  // toggleUserRoleDropdown: PropTypes.func.isRequired,
  toggleUserDropdown: PropTypes.func.isRequired,
  // playerRoleItemProps: PropTypes.object.isRequired,
  logoutItem: PropTypes.any,
  numberOfOffers: PropTypes.number,
};

export default DesktopNavigation;
