import PropTypes from 'prop-types';
import FeatureActionButton from 'common/components/feature-action-button';
import { useProps } from './hooks';
import { useWindowSizeContext } from 'contexts/window-size';
import ImageUploadForm from './components/image-upload-form';
import {
  CLUB_PROFILE_IMAGE_UPLOAD_TYPE,
  USER_PROFILE_IMAGE_UPLOAD_TYPE,
} from './constants';

const className = 'c-ImageUploader';

const ImageUploader = ({ addImageTooltip, type, reactGAModalView }) => {
  const { isMobileView } = useWindowSizeContext();
  const {
    isUploadModalOpened,
    renderAddImageIcon,
    openUploadModal,
    closeUploadModal,
    uploadButtonDisabled,
    selectedFile,
    fileSource,
    onFileChange,
    onFileUpload,
    invalidFileErrorMessage,
  } = useProps({ type });

  return (
    <div className={className}>
      <FeatureActionButton
        renderIcon={renderAddImageIcon}
        onClick={openUploadModal}
        tooltip={addImageTooltip}
      />

      {/* Modals */}
      {isUploadModalOpened && (
        <ImageUploadForm
          isMobileView={isMobileView}
          selectedFile={selectedFile}
          fileSource={fileSource}
          uploadButtonDisabled={uploadButtonDisabled}
          onFileChange={onFileChange}
          onFileUpload={onFileUpload}
          onClose={closeUploadModal}
          invalidFileErrorMessage={invalidFileErrorMessage}
          reactGAModalView={reactGAModalView}
        />
      )}
    </div>
  );
};

ImageUploader.propTypes = {
  addImageTooltip: PropTypes.string,
  reactGAModalView: PropTypes.string,
  type: PropTypes.oneOf([
    USER_PROFILE_IMAGE_UPLOAD_TYPE,
    CLUB_PROFILE_IMAGE_UPLOAD_TYPE,
  ]),
};

export default ImageUploader;
