import { useCallback, useState, useMemo } from 'react';
import { useWindowSizeContext } from 'contexts/window-size';
import {
  CREATE_CLUB_MODAL_TYPE,
  CREATE_GAME_EVENT_MODAL_TYPE,
  LOGOUT_ITEM,
  modalStaticProps,
} from './constants';
import { usePrivateNavContext } from 'contexts/private-nav';
import { useNavigation } from 'common/hooks';
import Modal from 'features/modal/components/modal';
import { DARK_THEME } from 'features/modal';
import Form from 'common/components/form';
import style from './Navbar.module.scss';
import ClubIcon from 'common/components/icons/club-icon';
import { useCreateClub, useProps } from './hooks';
import AsideView from 'features/aside-view';
import { useUserSessionContext } from 'contexts/user-session';
import { PLAYER_USER_ROLE } from 'contexts/user-session/hooks/constants';
import Match from 'features/match';
import MobileNavigation from './subfeatures/mobile-navigation';
import DesktopNavigation from './subfeatures/desktop-navigation';
import InstallButton from 'features/install-button';
import { INACTIVE_CLUB_VALUE } from 'common/api/constants';
import { formatImage } from 'common/utils/formatting';
import { REACT_GA_CREATE_TEAM_MODAL } from 'common/constants/react-google-analytics/modals';
import {
  REACT_GA_ACTIVE_CLUB_PROFILE_GO_TO_PAGE_EVENT,
  REACT_GA_ACTIVE_PLAYER_PROFILE_GO_TO_PAGE_EVENT,
} from 'common/constants/react-google-analytics/events';
import ReactGA from 'react-ga';
import { CreateEditGameEvent } from 'features/game-event-features';

const Navbar = () => {
  const { navigateToUserPage, navigateToClubPage } = useNavigation();
  const { notificationsDropdownOpened, searchOpened } = usePrivateNavContext();
  const { isMobileView, isTabletView, isDesktopView } = useWindowSizeContext();
  const { activeRole, switchToPlayerRole } = useUserSessionContext();
  const {
    user,
    gmItems,
    logout,
    offersAsideOpened,
    toggleOffersAside,
    offersAsideViewProps,
    selectedMatch,
    closeMatchModal,
  } = useProps();

  const [modalOpened, setModalOpened] = useState(null);

  const openCreateClubModal = useCallback(() => {
    setModalOpened(CREATE_CLUB_MODAL_TYPE);
  }, []);

  const openCreateGameEventModal = useCallback(() => {
    setModalOpened(CREATE_GAME_EVENT_MODAL_TYPE);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(null);
  }, []);

  const goToProfilePage = useCallback(() => {
    ReactGA.event(REACT_GA_ACTIVE_PLAYER_PROFILE_GO_TO_PAGE_EVENT);
    navigateToUserPage(user?._id);
  }, [navigateToUserPage, user?._id]);

  const handleNavigateToClubPage = useCallback(
    id => {
      ReactGA.event(REACT_GA_ACTIVE_CLUB_PROFILE_GO_TO_PAGE_EVENT);
      navigateToClubPage(id);
    },
    [navigateToClubPage]
  );

  const {
    userDropdownOpened,
    toggleUserDropdown,
    userRoleDropdownOpened,
    toggleUserRoleDropdown,
  } = usePrivateNavContext();

  const getActiveRoleProps = useCallback(() => {
    if (activeRole?.type === PLAYER_USER_ROLE) {
      return {
        image: formatImage(activeRole?.image),
        label: activeRole?.label,
        onlyLabel: isDesktopView,
        handler: goToProfilePage,
      };
    } else {
      return {
        Icon: ClubIcon,
        // logo: activeRole?.logo,
        image: formatImage(activeRole?.logo),
        status: activeRole?.status,
        onlyLabel: isDesktopView,
        label: activeRole?.label,
        handler: () => handleNavigateToClubPage(activeRole?.id),
      };
    }
  }, [activeRole, goToProfilePage, handleNavigateToClubPage, isDesktopView]);

  const getRoleItems = useCallback(
    () =>
      gmItems?.map(item => ({
        label: item?.name,
        status: item?.status,
        // logo: item?.logo,
        image: formatImage(item?.image),
        Icon: ClubIcon,
        handler: () => {
          if (item?.status === INACTIVE_CLUB_VALUE) {
            handleNavigateToClubPage(item?._id);
          } else {
            // TODO delete this
            // switchToGMRole(
            //   item?.name,
            //   item?._id,
            //   // item?.logo,
            //   item?.image,
            //   item?.status
            // );
          }
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gmItems]
  );

  const playerRoleItemProps = useMemo(
    () => ({
      label: 'Player',
      image: formatImage(user?.image?.url),
      handler: switchToPlayerRole,
    }),
    [switchToPlayerRole, user?.image?.url]
  );

  const getLogoutItem = useCallback(
    () => ({ ...LOGOUT_ITEM, handler: logout }),
    [logout]
  );

  const { onCreateClub, getCreateClubInputProps } = useCreateClub({
    closeModal,
  });

  return (
    <div>
      <div className={style['navbar-wrapper']}>
        {isTabletView ? (
          <MobileNavigation
            notificationsDropdownOpened={notificationsDropdownOpened}
            searchOpened={searchOpened}
            openCreateClubModal={openCreateClubModal}
            openCreateGameEventModal={openCreateGameEventModal}
            toggleOffersAside={toggleOffersAside}
            getActiveRoleProps={getActiveRoleProps}
            getRoleItems={getRoleItems}
            userRoleDropdownOpened={userRoleDropdownOpened}
            userDropdownOpened={userDropdownOpened}
            toggleUserRoleDropdown={toggleUserRoleDropdown}
            toggleUserDropdown={toggleUserDropdown}
            playerRoleItemProps={playerRoleItemProps}
            logoutItem={getLogoutItem()}
            numberOfOffers={activeRole?.offers?.length}
          />
        ) : (
          <DesktopNavigation
            openCreateClubModal={openCreateClubModal}
            openCreateGameEventModal={openCreateGameEventModal}
            toggleOffersAside={toggleOffersAside}
            getActiveRoleProps={getActiveRoleProps}
            getRoleItems={getRoleItems}
            userRoleDropdownOpened={userRoleDropdownOpened}
            userDropdownOpened={userDropdownOpened}
            toggleUserRoleDropdown={toggleUserRoleDropdown}
            toggleUserDropdown={toggleUserDropdown}
            playerRoleItemProps={playerRoleItemProps}
            logoutItem={getLogoutItem()}
            numberOfOffers={activeRole?.offers?.length}
          />
        )}

        <InstallButton />
      </div>

      {/* -- MODALS -- */}
      {modalOpened === CREATE_CLUB_MODAL_TYPE && (
        <Modal
          fullScreen={isMobileView}
          title={modalStaticProps[CREATE_CLUB_MODAL_TYPE].title}
          onClose={closeModal}
          variant='center'
          theme={DARK_THEME}
          customClassName={style.modal}
          reactGAModalView={REACT_GA_CREATE_TEAM_MODAL}
        >
          <Form inputs={getCreateClubInputProps()} onConfirm={onCreateClub} />
        </Modal>
      )}

      {modalOpened === CREATE_GAME_EVENT_MODAL_TYPE && (
        <CreateEditGameEvent onClose={closeModal} />
      )}

      {selectedMatch && (
        <Match onClose={closeMatchModal} data={selectedMatch} />
      )}

      {/* -- ASIDE VIEWS -- */}
      {offersAsideOpened && <AsideView {...offersAsideViewProps} />}
    </div>
  );
};

export default Navbar;
