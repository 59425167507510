import AddToShortlistIcon from 'common/components/icons/add-to-shortlist-icon';
import RemoveFromShortlistIcon from 'common/components/icons/remove-from-shortlist-icon';
import { useUserSessionContext } from 'contexts/user-session';
import {
  ADD_CLUB_TO_SHORTLIST_ACTION_TYPE,
  ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE,
  ADD_TO_SHORTLIST_METHOD,
  REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE,
  REMOVE_FROM_SHORTLIST_METHOD,
  REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE,
} from 'contexts/user-session/hooks/constants';
import { useMemo } from 'react';
import { shortlistLabels } from '../constants';
import ReactGA from 'react-ga';
import {
  REACT_GA_CLUB_SHORTLIST_EVENT,
  REACT_GA_PLAYER_SHORTLIST_EVENT,
} from 'common/constants/react-google-analytics/events';

const useProps = ({
  clubId,
  playerId,
  addToShortlistCB,
  removeFromShortlistCB,
}) => {
  const { activeRole, updateShortlistData, getShortlistMethodType } =
    useUserSessionContext();

  const shortlistMethodType = useMemo(
    () => getShortlistMethodType({ clubId, playerId }),
    [clubId, getShortlistMethodType, playerId]
  );

  const rGAEvent = useMemo(() => {
    if (
      shortlistMethodType === REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE ||
      shortlistMethodType === ADD_CLUB_TO_SHORTLIST_ACTION_TYPE
    ) {
      return REACT_GA_CLUB_SHORTLIST_EVENT;
    } else if (
      shortlistMethodType === REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE ||
      shortlistMethodType === ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE
    ) {
      return REACT_GA_PLAYER_SHORTLIST_EVENT;
    }
  }, [shortlistMethodType]);

  return {
    roleId: activeRole?.id,
    featureActionButtonProps: {
      renderIcon: () =>
        shortlistMethodType === ADD_TO_SHORTLIST_METHOD ? (
          <AddToShortlistIcon />
        ) : shortlistMethodType === REMOVE_FROM_SHORTLIST_METHOD ? (
          <RemoveFromShortlistIcon />
        ) : null,
      onClick: async () => {
        const cbToUse =
          shortlistMethodType === ADD_TO_SHORTLIST_METHOD && addToShortlistCB
            ? addToShortlistCB
            : shortlistMethodType === REMOVE_FROM_SHORTLIST_METHOD &&
              removeFromShortlistCB
            ? removeFromShortlistCB
            : () => {};
        await updateShortlistData({ clubId, playerId });
        cbToUse({ clubId, playerId });
        ReactGA.event(rGAEvent);
      },
      tooltip: shortlistLabels[shortlistMethodType],
    },
  };
};

export default useProps;
