import PropTypes from 'prop-types';
import style from './PlayersView.module.scss';
import Button from 'common/components/button';
import GoBackIcon from 'common/components/icons/go-back-icon';
import BlankScreenPlaceholder from 'common/components/blank-screen-placeholder';
import { blankSpaceText } from 'pages/street/constants';
import { BUTTON_LIGHT_VARIANT } from 'common/components/button';
import PlayerCardV2 from 'features/player-card-v2';
import { useCommonClassNames } from 'common/hooks';

const PlayersView = ({ items, goBackHandler }) => {
  const { animationClassName } = useCommonClassNames();
  return (
    <div
      className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
    >
      <div className={style.goBackWrapper}>
        <Button
          variant={BUTTON_LIGHT_VARIANT}
          standardWidth
          onClick={goBackHandler}
          withRipples
        >
          <GoBackIcon color='#f1daff' />
          go back
        </Button>
      </div>
      {/* <Tabs tabs={tabs} /> */}

      {/* grid here */}
      <div className={style.items}>
        {items?.map(item => (
          <div className={style.itemWrapper}>
            <PlayerCardV2 data={item} key={item._id} />
          </div>
        ))}
      </div>

      {items.length === 0 && <BlankScreenPlaceholder {...blankSpaceText} />}
    </div>
  );
};

PlayersView.defaultProps = {
  items: [],
};

PlayersView.propTypes = {
  goBackHandler: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
};

export default PlayersView;
