import { updateShortlistService } from 'common/api/shortlistsService';
import { apiServiceRolesMap } from 'common/constants/apis';
import { useCallback, useEffect, useState } from 'react';
import {
  ADD_CLUB_TO_SHORTLIST_ACTION_TYPE,
  ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE,
  ADD_TO_SHORTLIST_METHOD,
  REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE,
  REMOVE_FROM_SHORTLIST_METHOD,
  REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE,
} from './constants';

const getActionType = ({ clubId, playerId, shortlistData }) => {
  const idToCheck = clubId || playerId;

  const isAlreadyOnShortlist = shortlistData?.find(item => item === idToCheck);

  if (isAlreadyOnShortlist) {
    return clubId
      ? REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE
      : playerId
      ? REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE
      : null;
  } else {
    return clubId
      ? ADD_CLUB_TO_SHORTLIST_ACTION_TYPE
      : playerId
      ? ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE
      : null;
  }
};

const useShortlists = ({ roleType, id, initShortlistData }) => {
  const [shortlistData, setShortlistData] = useState([]);

  const updateShortlistData = useCallback(
    async ({ clubId, playerId }) => {
      const actionType = getActionType({ clubId, playerId, shortlistData });
      // NOTE not used ApiWrapper as we dont want loading here
      const response = await updateShortlistService(id, {
        roleType: apiServiceRolesMap?.[roleType],
        actionType,
        clubId,
        playerId,
      });

      const responseData = response?.data;
      const combinedShortlists = [
        ...responseData?.clubsShortlist,
        ...responseData?.playersShortlist,
      ];

      setShortlistData(combinedShortlists);
    },
    [id, roleType, shortlistData]
  );

  // NOTE either add or remove
  const getShortlistMethodType = useCallback(
    ({ clubId, playerId }) => {
      // NOTE players & clubs can not shortlist themselves
      if (id === clubId || playerId === id) return null;
      const actionType = getActionType({ clubId, playerId, shortlistData });
      switch (actionType) {
        case REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE:
        case REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE:
          return REMOVE_FROM_SHORTLIST_METHOD;
        case ADD_CLUB_TO_SHORTLIST_ACTION_TYPE:
        case ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE:
          return ADD_TO_SHORTLIST_METHOD;
        default:
          return null;
      }
    },
    [id, shortlistData]
  );

  useEffect(() => {
    setShortlistData(initShortlistData);
  }, [initShortlistData]);

  return {
    shortlistData,
    updateShortlistData,
    getShortlistMethodType,
  };
};

export default useShortlists;
