import ButtonSelectors from 'common/components/button-selectors';
import { SelectPropTypes } from 'common/components/select';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './Tags.module.scss';

const Tags = ({ tagGroups }) => {
  const renderTagGroup = useCallback(
    options => (
      <div className={style.selectors}>
        <ButtonSelectors {...options} />
      </div>
    ),
    []
  );

  const renderAllTags = useCallback(
    () => Children.toArray(tagGroups.map(renderTagGroup)),
    [renderTagGroup, tagGroups]
  );

  return <div className={style.wrapper}>{renderAllTags()}</div>;
};

Tags.propTypes = {
  tagGroups: PropTypes.arrayOf(PropTypes.shape(SelectPropTypes)),
};

export default Tags;
