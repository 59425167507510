import { useCallback, useState } from 'react';

const useProps = () => {
  const [selectedMatch, setSelectedMatch] = useState(null);

  const closeMatchModal = useCallback(() => {
    setSelectedMatch(null);
  }, []);

  return {
    selectedMatch,
    setSelectedMatch,
    closeMatchModal,
  };
};

export default useProps;
