import GameEventsList from './components/game-events-list';
import { useProps } from './hooks';
import PropTypes from 'prop-types';
import style from './GameEventsListView.module.scss';
import Tabs from 'features/tabs';
import { GameEvent } from 'features/game-event-features';
import { noGamesMessageStaticProps } from './constants';
import BlankScreenPlaceholder from 'common/components/blank-screen-placeholder';

const GameEventsListView = () => {
  const {
    tabsData,
    formattedGameEventItems,
    selectedGameEvent,
    closeGameEvent,
  } = useProps();

  return (
    <div className={style.wrapper}>
      <Tabs tabs={tabsData} />

      {formattedGameEventItems.length > 0 ? (
        <GameEventsList items={formattedGameEventItems} />
      ) : (
        <BlankScreenPlaceholder {...noGamesMessageStaticProps} />
      )}

      {selectedGameEvent && (
        <GameEvent gameData={selectedGameEvent} onClose={closeGameEvent} />
      )}
    </div>
  );
};

GameEventsListView.defaultProps = {
  items: [],
};

GameEventsListView.propTypes = {
  items: PropTypes.array,
};

export default GameEventsListView;
