import { useCallback, useState } from 'react';
import {
  CLICK_VIEW,
  dynamicClassNames,
  FRONT_FACE_VIEW,
  HOVER_VIEW,
} from '../constants';

const useProps = () => {
  // active card view
  const [activeView, setActiveView] = useState(FRONT_FACE_VIEW);

  const setFrontFaceView = useCallback(() => {
    if (activeView === HOVER_VIEW) {
      setActiveView(FRONT_FACE_VIEW);
    }
  }, [activeView]);

  const setHoverView = useCallback(() => {
    if (activeView === FRONT_FACE_VIEW) {
      setActiveView(HOVER_VIEW);
    }
  }, [activeView]);

  const setClickView = useCallback(() => {
    setActiveView(CLICK_VIEW);
  }, []);

  const onCloseClickView = useCallback(() => {
    setActiveView(FRONT_FACE_VIEW);
  }, []);

  return {
    // active card view
    activeView,
    setFrontFaceView,
    setHoverView,
    setClickView,
    onCloseClickView,
    dynamicClassNamesSufix: dynamicClassNames[activeView],
  };
};

export default useProps;
