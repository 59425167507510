import style from './MatchCardOverlay.module.scss';
import PropTypes from 'prop-types';

const MatchCardOverlay = ({ bgVariant }) => (
  <div className={`${style.wrapper} ${style[`wrapper--${bgVariant}-bg`]}`}>
    <div className={style.overlay}></div>
  </div>
);

MatchCardOverlay.defaultProps = {
  bgVariant: 'neutral',
};

MatchCardOverlay.propTypes = {
  bgVariant: PropTypes.oneOf(['neutral', 'dark', 'lose', 'win', 'draw']),
};

export default MatchCardOverlay;
