import PropTypes from 'prop-types';
import { useNavigation } from 'common/hooks';
import { useOnCardClickHandlers } from 'features/base-3-part-card/hooks';
import { Children, useCallback } from 'react';
import style from './ClubsView.module.scss';
import Match from 'features/match';
import { useProps } from './hooks';

// NOTE replica of RosterView feature
// TODO refactor
const ClubsView = ({ clubItems }) => {
  const { selectedMatch, setSelectedMatch, closeMatchModal } = useProps();
  const { navigateToClubPage } = useNavigation();
  const { passOnClickToCards } = useOnCardClickHandlers(setSelectedMatch);

  const renderCards = useCallback(
    data =>
      Children.toArray(
        data?.map(({ Component, props }) => {
          const onClick = () =>
            navigateToClubPage(props?.frontFaceViewProps?._id);

          const { frontFaceViewProps, clickViewProps, ...otherProps } = props;
          const dynamicFrontFaceViewProps = { ...frontFaceViewProps, onClick };
          const dynamicClickViewRowsDataProps =
            clickViewProps?.rowsData?.map(passOnClickToCards);
          const dynamicClickViewProps = {
            ...clickViewProps,
            rowsData: dynamicClickViewRowsDataProps,
          };

          return (
            <div className={style.cardWrapper}>
              <Component
                {...otherProps}
                frontFaceViewProps={dynamicFrontFaceViewProps}
                clickViewProps={dynamicClickViewProps}
              />
            </div>
          );
        })
      ),
    [navigateToClubPage, passOnClickToCards]
  );

  return (
    <div className={style.wrapper}>
      {renderCards(clubItems)}

      {!!selectedMatch && (
        <Match onClose={closeMatchModal} data={selectedMatch} forMatchSection />
      )}
    </div>
  );
};

ClubsView.defaultProps = {
  clubItems: [],
};

ClubsView.propTypes = {
  clubItems: PropTypes.arrayOf(PropTypes.object),
};

export default ClubsView;
