import { COUNTRY_CODES } from 'common/constants/geoData';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import './HeadingWithSubtext.scss';

const className = 'c-HeadingWithSubtext';

const HeadingWithSubtext = ({ heading, subtext, subtext2, withFlag }) => (
  <div className={className}>
    <h1 className={`${className}__heading`}>{heading}</h1>
    <p className={`${className}__subtext`}>
      {subtext}
      {subtext2 && (
        <span>
          <span className={`${className}__divider`}>|</span>
          {withFlag && (
            <ReactCountryFlag countryCode={COUNTRY_CODES?.[withFlag]} svg />
          )}
          {subtext2}
        </span>
      )}
    </p>
  </div>
);

HeadingWithSubtext.propTypes = {
  heading: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  subtext2: PropTypes.string,
  withFlag: PropTypes.string,
};

export default HeadingWithSubtext;
