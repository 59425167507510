import { MEDIA_URL } from '_config';

/* eslint-disable max-len */
export const CLUB_MEMBERSHIP_STREET_ITEM_TYPE = 'club-membership';
export const CLUB_STREET_ITEM_TYPE = 'club';
export const MATCH_STREET_ITEM_TYPE = 'match';
export const PLAYER_STREET_ITEM_TYPE = 'player';

export const blankSpaceText = {
  mainText: [{ text: 'No Activities at the moment...' }],
  subText: [
    {
      text: 'Start by creating a game and sharing it to your friends.',
    },
  ],
};

// street screens
export const CREATE_GAME_STREET_SCREEN = 'CREATE_GAME_STREET_SCREEN';
export const GAMES_STREET_SCREEN = 'GAMES_STREET_SCREEN';
export const TEAMS_STREET_SCREEN = 'TEAMS_STREET_SCREEN';
export const PLAYERS_STREET_SCREEN = 'PLAYERS_STREET_SCREEN';

export const GAMES_STREET_DATA_TYPE = 'games';
export const PLAYERS_STREET_DATA_TYPE = 'players';
export const CLUBS_STREET_DATA_TYPE = 'clubs';

// -- new format
export const GAMES_SCREEN_TEXT = {
  name: 'Games',
  subtitle: 'Find and Join Football Games',
  description: `Browse through a variety of football games happening in your area, and join in on the fun with local players.`,
};

export const playersScreenStaticProps = {
  name: 'Players',
  subtitle: `Build Your Football Network`,
  description: `Discover local football players and connect with them to join your team`,
  banner: {
    jpgURL: MEDIA_URL + '/jpg/c-11-players-view-m.jpeg',
    jpgDesktopURL: MEDIA_URL + '/jpg/c-11-players-view-d.jpeg',
    webpURL: MEDIA_URL + '/webp/c-11-players-view-m.webp',
    webpDesktopURL: MEDIA_URL + '/webp/c-11-players-view-d.webp',
    alt: 'player banner',
  },
};

export const SCREEN_CARD_PATTERN_BG = MEDIA_URL + '/jpg/card-pattern-bg.png';

export const TEAMS_SCREEN_TEXT = {
  name: 'Teams',
  subtitle: `Compete with Other Football Teams in Your City`,
  description: `Challenge other local football teams to games and compete for bragging rights as the best team in your city.`,
};

export const CREATE_PICKUP_GAME_TEXT = {
  name: 'Create Game',
  subtitle: `Create and Schedule a Football Game in Just a Few Clicks`,
  description: `Organize a pickup game with ease and share a public game card with all the details on social media and messaging apps to invite your friends to join.`,
};

export const streetDataTypeMap = {
  [GAMES_STREET_SCREEN]: GAMES_STREET_DATA_TYPE,
  [TEAMS_STREET_SCREEN]: CLUBS_STREET_DATA_TYPE,
  [PLAYERS_STREET_SCREEN]: PLAYERS_STREET_DATA_TYPE,
};

export const existingStreetDataTypes = [
  GAMES_STREET_DATA_TYPE,
  CLUBS_STREET_DATA_TYPE,
  PLAYERS_STREET_DATA_TYPE,
];

export const teamsScreenTips = [
  'Use one of your Team profiles to schedule a game with one of teams.',
  'Use your Player profile to ask teams to join their roster.',
];
export const playersScreenTips = [
  'Use one of your Team profiles to invite players to join your team.',
];

// subscription IDs
export const STREET_GAME_EVENT_UPDATE_SUBSCRIPTION_ID =
  'STREET_GAME_EVENT_UPDATE_SUBSCRIPTION_ID';
export const STREET_GAME_EVENT_DELETE_SUBSCRIPTION_ID =
  'STREET_GAME_EVENT_DELETE_SUBSCRIPTION_ID';
export const STREET_GAME_EVENT_SELECTED_GAME_EVENT_SUBSCRIPTION_ID =
  'STREET_GAME_EVENT_SELECTED_GAME_EVENT_SUBSCRIPTION_ID';
