import { useState, useEffect, useCallback, useMemo } from 'react';
import { useEventsContext } from 'contexts/events';
import { mapExperienceToClassName } from '../mappers';
import { OUTSIDE_CLICK_EVENT } from 'contexts/events/constants';

const useProps = data => {
  const { subscribe } = useEventsContext();

  // skill level props
  const [showSkillLevel, setShowSkillLevel] = useState(false);

  const showSkillLevelHandler = useCallback(() => {
    setShowSkillLevel(true);
  }, []);
  const hideSkillLevelHandler = useCallback(() => {
    setShowSkillLevel(false);
  }, []);

  const experienceLevelClassName = useMemo(
    () =>
      `${mapExperienceToClassName(data?.playerGeneralInfo?.experienceLevel)}`,
    [data?.playerGeneralInfo?.experienceLevel]
  );

  // nationality props
  const [showNationality, setShowNationality] = useState(false);

  const showNationalityHandler = useCallback(() => {
    setShowNationality(true);
  }, []);

  const hideNationalityHandler = useCallback(() => {
    setShowNationality(false);
  }, []);

  const showNationalityClassName = useMemo(
    () =>
      showNationality ? 'playerNationality--show' : 'playerNationality--hide',
    [showNationality]
  );

  // positions props
  const [showSecondaryPositions, setShowSecondaryPositions] = useState(false);

  const showSecondaryPositionsHandler = useCallback(() => {
    setShowSecondaryPositions(true);
  }, []);

  const hideSecondaryPositionsHandler = useCallback(() => {
    setShowSecondaryPositions(false);
  }, []);

  const showSecondaryPositionsClassName = useMemo(
    () =>
      showSecondaryPositions
        ? 'secondaryPositionsWrapper--show'
        : 'secondaryPositionsWrapper--hide',
    [showSecondaryPositions]
  );

  const aBoxClassNameVariant = useMemo(
    () => `aBox--${data?.positions?.primaryArea}`,
    [data?.positions?.primaryArea]
  );

  useEffect(() => {
    subscribe(OUTSIDE_CLICK_EVENT, () => {
      hideSkillLevelHandler();
      hideNationalityHandler();
    });
  }, [hideNationalityHandler, hideSkillLevelHandler, subscribe]);

  return {
    showSkillLevel,
    showSkillLevelHandler,
    hideSkillLevelHandler,

    aBoxClassNameVariant,
    experienceLevelClassName,

    showNationalityHandler,
    hideNationalityHandler,
    showNationalityClassName,

    showSecondaryPositions,
    showSecondaryPositionsHandler,
    hideSecondaryPositionsHandler,
    showSecondaryPositionsClassName,
  };
};

export default useProps;
