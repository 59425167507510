import { EMPTY_INPUT_VALUE } from 'common/constants/forms';
import { CONFIRM_LABEL } from 'common/constants/labels';

export const PASSWORD_SETTINGS = 'Password';
export const RETIRE_SETTINGS = 'Retire';

export const SETTINGS_ITEMS = [PASSWORD_SETTINGS, RETIRE_SETTINGS];

// form fields
const CURRENT_PASSWORD_KEY = 'password';
const NEW_PASSWORD_KEY = 'newPassword';
const CONFIRMED_NEW_PASSWORD_KEY = 'confirmedNewPassword';

export const passwordFormFields = [
  {
    key: CURRENT_PASSWORD_KEY,
    label: 'current password',
    type: 'password',
  },
  {
    key: NEW_PASSWORD_KEY,
    label: 'new password',
    type: 'password',
  },
  {
    key: CONFIRMED_NEW_PASSWORD_KEY,
    label: 'confirm new password',
    type: 'password',
  },
];

export const deleteProfileFormFields = [
  {
    key: 'password',
    label: 'password',
    type: 'password',
  },
];

export const initPasswordState = {
  [CURRENT_PASSWORD_KEY]: { value: '' },
  [NEW_PASSWORD_KEY]: { ...EMPTY_INPUT_VALUE },
  [CONFIRMED_NEW_PASSWORD_KEY]: { ...EMPTY_INPUT_VALUE },
};

export const CONFIRM_MODAL_BUTTON_LABEL = CONFIRM_LABEL;
export const PASSWORD_UPDATE_CONFIRM_MESSAGE = [
  { text: 'Are you sure you want to change your password?' },
];
export const DELETE_PROFILE_CONFIRM_MESSAGE = [
  {
    text: 'When you click confirm, your profile will be permanently deleted.',
  },
  { text: 'Are you sure you want to delete your profile?', isNewLine: true },
];
export const PASSWORD_SUCCESSFULLY_UPDATED_MESSAGE = 'Password updated.';

export const PASSWORD_FORM_CTA_LABEL = 'Update';
export const RETIRE_FORM_CTA_LABEL = 'Retire';
