import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Input, { InputPropTypes } from '../input';
import Button, { BUTTON_LIGHT_VARIANT } from '../button';
import Select, { SelectPropTypes } from '../select';
import DangerText from '../danger-text';
import CalendarInput, { CalendarInputPropTypes } from '../calendar-input';
import PasswordInput from 'features/password-input';

import './Form.scss';
import { BUTTON_SELECTORS } from 'common/hooks/constants';
import ButtonSelectors from '../button-selectors';

const className = 'c-Form';

const Form = ({ inputs, buttonLabel, buttonVariant, onConfirm }) => {
  const renderItemWithErrorWarnings = (item, Component) => {
    const { error, ...inputProps } = item;
    const errorMessage = [{ text: error }];

    return (
      <div className={`${className}__input`}>
        <Component {...inputProps} />
        {item.error && <DangerText text={errorMessage} />}
      </div>
    );
  };

  const renderInputs = () =>
    Children.toArray(
      inputs.map(item => {
        const { type, ...inputProps } = item;

        switch (type) {
          case 'select':
            return renderItemWithErrorWarnings(inputProps, Select);

          case 'date':
            return renderItemWithErrorWarnings(inputProps, CalendarInput);

          case 'password':
            return renderItemWithErrorWarnings(inputProps, PasswordInput);

          case BUTTON_SELECTORS:
            return renderItemWithErrorWarnings(inputProps, ButtonSelectors);

          case 'text':
          default:
            return renderItemWithErrorWarnings(item, Input);
        }
      })
    );

  return (
    <form className={className}>
      <div className={`${className}__inputs-wrapper`}>{renderInputs()}</div>
      {onConfirm && (
        <Button withRipples variant={buttonVariant} onClick={onConfirm}>
          {buttonLabel}
        </Button>
      )}
    </form>
  );
};

Form.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(InputPropTypes),
      PropTypes.shape(SelectPropTypes),
      PropTypes.shape(CalendarInputPropTypes),
    ])
  ),
  buttonLabel: PropTypes.string,
  buttonVariant: PropTypes.string,
  onConfirm: PropTypes.func,
};

Form.defaultProps = {
  buttonLabel: 'submit',
  buttonVariant: BUTTON_LIGHT_VARIANT,
};

export default Form;
