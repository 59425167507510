import FeatureActionButton from 'common/components/feature-action-button';
import PropTypes from 'prop-types';
import { useProps } from './hooks';
import './ShortlistButton.module.scss';

const ShortlistButton = ({
  clubId,
  playerId,
  addToShortlistCB,
  removeFromShortlistCB,
}) => {
  const { featureActionButtonProps, roleId } = useProps({
    clubId,
    playerId,
    addToShortlistCB,
    removeFromShortlistCB,
  });

  return roleId === clubId || roleId === playerId ? null : (
    <FeatureActionButton {...featureActionButtonProps} />
  );
};

ShortlistButton.propTypes = {
  clubId: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  isOnShortlist: PropTypes.bool,
  addToShortlistCB: PropTypes.func,
  removeFromShortlistCB: PropTypes.func,
};

export default ShortlistButton;
