import PropTypes from 'prop-types';
import GeneralScreen from './components/general-screen';
import SkillsScreen from './components/skills-screen';
import PositionsScreen from './components/positions-screen';
import style from './PlayerForm.module.scss';
import Modal, { DARK_THEME } from 'features/modal';
import { useWindowSizeContext } from 'contexts/window-size';
import { useProps } from './hooks';
import { GENERAL_SCREEN, POSITIONS_SCREEN, SKILLS_SCREEN } from './constants';
import { useMetaContext } from 'contexts/meta';
import Tabs from 'features/tabs';
import Button from 'common/components/button';
import FadeInOutWrapper from 'common/components/fade-in-out-wrapper';
import { useCallback, useEffect, useRef } from 'react';
import Image from 'common/components/image';
import { formatImage } from 'common/utils/formatting';
import { SCREEN_CARD_PATTERN_BG } from 'pages/street/constants';

const PlayerForm = ({ onClose, playerData }) => {
  const { isMobileView } = useWindowSizeContext();
  const { loading } = useMetaContext();
  const {
    // screen props
    screenTabsProps,
    flowButtonVariant,
    currentScreen,
    relativeModalNavigationProps,

    // individual screen props
    generalProps,
    skillsProps,
    positionsProps,
  } = useProps(onClose);
  const activeScreenRef = useRef();

  const renderScreens = useCallback(() => {
    const renderProps = [
      {
        isVisible: currentScreen === GENERAL_SCREEN,
        initVisible: true,
        props: generalProps,
        Component: GeneralScreen,
      },
      {
        isVisible: currentScreen === SKILLS_SCREEN,
        props: skillsProps,
        Component: SkillsScreen,
      },
      {
        isVisible: currentScreen === POSITIONS_SCREEN,
        props: positionsProps,
        Component: PositionsScreen,
      },
    ];

    return renderProps.map(({ isVisible, initVisible, props, Component }) => {
      const activeViewWrapperClassName = isVisible
        ? style['view-wrapper--active']
        : '';

      return (
        <div
          className={`${style['view-wrapper']} ${activeViewWrapperClassName}`}
        >
          <FadeInOutWrapper
            isVisible={isVisible}
            initVisible={initVisible}
            customClassName={style[`fade-in-out-wrapper`]}
          >
            <div className={style['screen-inner-wrapper']}>
              <Component {...props} />
              <div className={style.flowBottomControls}>
                {relativeModalNavigationProps.onClick && (
                  <Button
                    onClick={relativeModalNavigationProps.onClick}
                    variant={flowButtonVariant}
                  >
                    {relativeModalNavigationProps.label}
                  </Button>
                )}
              </div>
            </div>
          </FadeInOutWrapper>
        </div>
      );
    });
  }, [
    currentScreen,
    flowButtonVariant,
    generalProps,
    positionsProps,
    relativeModalNavigationProps.label,
    relativeModalNavigationProps.onClick,
    skillsProps,
  ]);

  useEffect(() => {
    if (activeScreenRef.current) {
      setTimeout(() => {
        activeScreenRef.current?.scrollIntoView({ block: 'nearest' });
      }, 100);
    }
  }, [currentScreen]);

  return (
    <Modal
      theme={DARK_THEME}
      fullScreen={isMobileView}
      onClose={onClose}
      withoutWrapper
      customModalContentClassName={style.modalContent}
      customClassName={style.modal}
      highlightCloseButton
      loading={!!loading}
    >
      <div className={style.patternBgWrapper}>
        <Image {...formatImage(SCREEN_CARD_PATTERN_BG)} />
      </div>
      <div className={style.tabs} ref={activeScreenRef}>
        <Tabs {...screenTabsProps} />
      </div>
      <div className={style.wrapper}>{renderScreens()}</div>
    </Modal>
  );
};

PlayerForm.defaultProps = {
  playerData: {},
};

PlayerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  playerData: PropTypes.object.isRequired,
};

export default PlayerForm;
