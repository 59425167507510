import PropTypes from 'prop-types';
import Base3PartCard from 'features/base-3-part-card';
import { ClickViewPropTypes } from 'features/base-3-part-card/components/click-view';
import { FrontFaceViewPropTypes } from 'features/base-3-part-card/components/front-face-view';
import { HoverViewProps } from 'features/base-3-part-card/components/hover-view';
import './Club3PartCard.scss';
import { REACT_GA_TEAM_CARD_MODAL } from 'common/constants/react-google-analytics/modals';

const className = 'c-Club3PartCard';

const Club3PartCard = ({
  frontFaceViewProps,
  hoverViewProps,
  clickViewProps,
  isSmallCard,
}) => (
  <div className={className}>
    <Base3PartCard
      frontFaceViewProps={frontFaceViewProps}
      hoverViewProps={hoverViewProps}
      clickViewProps={clickViewProps}
      onlyFrontFaceView={isSmallCard}
      isSmallCard={isSmallCard}
      reactGAModalView={REACT_GA_TEAM_CARD_MODAL}
    />
  </div>
);

Club3PartCard.propTypes = {
  frontFaceViewProps: PropTypes.shape(FrontFaceViewPropTypes),
  hoverViewProps: PropTypes.shape(HoverViewProps),
  clickViewProps: PropTypes.shape(ClickViewPropTypes),
  isSmallCard: PropTypes.bool,
};

export default Club3PartCard;
