import PropTypes from 'prop-types';
import DropdownMenu, {
  // BOTTOM_DROPDOWN_SIDE,
  RIGHT_DROPDOWN_SIDE,
} from 'common/components/dropdown-menu';
import IconWithLabelCTA from 'common/components/icon-with-label-cta';
import ClubIcon from 'common/components/icons/club-icon';
import PersonIcon from 'common/components/icons/person-icon';
import { PLAYER_USER_ROLE } from 'contexts/user-session/hooks/constants';
import LogoIconLink from 'features/navbar/components/LogoIconLink';
import Nav from 'features/navbar/components/nav';
import { BOTTOM_NAV, SETTINGS_ITEM, TOP_NAV } from 'features/navbar/constants';
import Search from 'features/search';
import { Children, useCallback } from 'react';
import { STREET_PATH } from 'routes/constants';
import style from '../../Navbar.module.scss';
import { useClassName } from 'features/navbar/hooks';
import { useUserSessionContext } from 'contexts/user-session';
import { useCallbackOnScroll, useNavigation } from 'common/hooks';
import { scrollToTop } from 'common/utils/dom';
import ClubLogo from 'common/components/club-logo';
import Image from 'common/components/image';
import AddCircleIcon from 'common/components/icons/add-circle-icon';
import FootballIcon from 'common/components/icons/football-icon';

const MobileNavigation = ({
  // openCreateClubModal,
  openCreateGameEventModal,
  // toggleOffersAside,
  notificationsDropdownOpened,
  searchOpened,
  getActiveRoleProps,
  // getRoleItems,
  // userRoleDropdownOpened,
  userDropdownOpened,
  // toggleUserRoleDropdown,
  toggleUserDropdown,
  // playerRoleItemProps,
  logoutItem,
  numberOfOffers,
}) => {
  const {
    visibilityTopClassName,
    visibilityBottomClassName,
    setNavbarVisibilityOnClassName,
    setNavbarVisibilityOffClassName,
  } = useClassName();

  const { activeRole } = useUserSessionContext();
  const { navigateToMyGames } = useNavigation();

  const conditionallySetNavbarVisibilityOffClassName = useCallback(() => {
    if (!notificationsDropdownOpened && !searchOpened)
      setNavbarVisibilityOffClassName();
  }, [
    notificationsDropdownOpened,
    searchOpened,
    setNavbarVisibilityOffClassName,
  ]);

  useCallbackOnScroll(
    setNavbarVisibilityOnClassName,
    conditionallySetNavbarVisibilityOffClassName
  );

  const renderBottomStickyActionControls = useCallback(() => {
    return [
      // NOTE FAS switching off create team feature
      // {
      //   Icon: CreateClubIcon,
      //   handler: openCreateClubModal,
      //   ComponentItem: IconWithLabelCTA,
      // },
      // NOTE FAS switching off offers
      // {
      //   Icon: OffersIcon,
      //   handler: toggleOffersAside,
      //   withBadge: true,
      //   ComponentItem: IconWithLabelCTA,
      //   numberOfUnread: numberOfOffers,
      // },
      {
        Icon: AddCircleIcon,
        ComponentItem: IconWithLabelCTA,
        handler: openCreateGameEventModal,
      },
      {
        Icon: FootballIcon,
        handler: navigateToMyGames,
        withBadge: true,
        ComponentItem: IconWithLabelCTA,
      },
    ].map((item, i) => {
      const { ComponentItem, withBadge, ...otherProps } = item;

      return <ComponentItem {...otherProps} />;
    });
  }, [navigateToMyGames, openCreateGameEventModal]);

  const renderMobileDropdowns = useCallback(() => {
    const RoleIcon =
      activeRole?.type === PLAYER_USER_ROLE ? PersonIcon : ClubIcon;

    const activeRoleProps = getActiveRoleProps();
    const withClubLogo = activeRoleProps?.logo;
    const items = [
      // {
      //   ComponentItem: Notifications,
      //   dropdownSide: BOTTOM_DROPDOWN_SIDE,
      //   smallVariantNotificationCard: true,
      // },
      {
        items: [getActiveRoleProps(), SETTINGS_ITEM, logoutItem],
        isOpened: userDropdownOpened,
        onToggle: toggleUserDropdown,
        Icon: RoleIcon,
        renderElement: () => {
          if (activeRoleProps?.image) {
            return <Image {...activeRoleProps?.image} />;
          } else {
            return withClubLogo ? (
              <ClubLogo {...activeRoleProps?.logo} />
            ) : (
              <ClubIcon />
            );
          }
        },
        dropdownSide: RIGHT_DROPDOWN_SIDE,
        key: activeRole?.type,
      },
      /* NOTE FAS switching off the roles feature as a part of the switching off the Club feature */
      // {
      //   pinnedItems: [playerRoleItemProps],
      //   items: getRoleItems(),
      //   isOpened: userRoleDropdownOpened,
      //   onToggle: toggleUserRoleDropdown,
      //   Icon: SwitchAccessIcon,
      //   dropdownSide: RIGHT_DROPDOWN_SIDE,
      //   key: 'switch-role',
      // },
    ];

    return items.map(item => {
      const { ComponentItem, ...otherProps } = item;

      return ComponentItem ? (
        <ComponentItem {...otherProps} />
      ) : (
        <DropdownMenu key={item.key} {...item} iconLabel={null} />
      );
    });
  }, [
    activeRole?.type,
    getActiveRoleProps,
    logoutItem,
    toggleUserDropdown,
    userDropdownOpened,
  ]);

  const items = [
    {
      position: TOP_NAV,
      render: (
        <div className={style['mobile-top-nav']}>
          <div onClick={scrollToTop}>
            <LogoIconLink to={STREET_PATH} />
          </div>
          <div className={visibilityTopClassName}>
            <Search />
          </div>
          {renderMobileDropdowns()}
        </div>
      ),
    },
    { position: BOTTOM_NAV, render: renderBottomStickyActionControls() },
  ];

  return (
    <div>
      {Children.toArray(
        items.map(({ position, render, icon }, i) =>
          icon ? (
            render()
          ) : (
            <Nav
              classNames={`${style['nav-wrapper']} ${
                i === 0 ? visibilityTopClassName : visibilityBottomClassName
              }`}
              position={position}
            >
              {render}
            </Nav>
          )
        )
      )}
    </div>
  );
};

MobileNavigation.propTypes = {
  // openCreateClubModal: PropTypes.func.isRequired,
  openCreateGameEventModal: PropTypes.func.isRequired,
  // toggleOffersAside: PropTypes.func.isRequired,
  notificationsDropdownOpened: PropTypes.bool,
  searchOpened: PropTypes.bool,
  getActiveRoleProps: PropTypes.func.isRequired,
  // getRoleItems: PropTypes.func.isRequired,
  // userRoleDropdownOpened: PropTypes.bool,
  userDropdownOpened: PropTypes.bool,
  // toggleUserRoleDropdown: PropTypes.func.isRequired,
  toggleUserDropdown: PropTypes.func.isRequired,
  // playerRoleItemProps: PropTypes.object.isRequired,
  logoutItem: PropTypes.any,
  numberOfOffers: PropTypes.number,
};

export default MobileNavigation;
