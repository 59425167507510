import PropTypes from 'prop-types';

const RemoveFromShortlistIcon = ({ color }) => (
  <svg
    enableBackground='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#fafafacc'
  >
    <rect fill='none' height='24' width='24' />
    <path
      stroke={color}
      d='M21,7h-6V5h6V7z M19,10.9c-0.32,0.07-0.66,0.1-1,0.1c-2.76,0-5-2.24-5-5c0-1.13,0.37-2.16,1-3L7,3C5.9,3,5,3.9,5,5v16l7-3 l7,3V10.9z'
    />
  </svg>
);

RemoveFromShortlistIcon.defaultProps = { color: '#111314' };
RemoveFromShortlistIcon.propTypes = { color: PropTypes.string };

export default RemoveFromShortlistIcon;
