import { useCallback } from 'react';
import PropTypes from 'prop-types';
import ElementWithTooltip from 'common/components/element-with-tooltip';
import Button from 'common/components/button';
import EditIcon from 'common/components/icons/edit-icon';
import InfoCard from 'common/components/info-card';
import {
  BIO_VIEW,
  CLUBS_VIEW,
  MATCH_LISTS_VIEW,
  MY_GAME_EVENTS,
} from 'pages/profile/constants';
import style from '../../Profile.module.scss';
import ClubsView from 'features/clubs-view';
import MatchListsView from 'features/match-lists-view';
import GameEventsListView from 'features/game-events-view';
import PlayerCardV2 from 'features/player-card-v2/PlayerCardV2';

const ActiveSection = ({
  activeNavItem,
  isProfileOwner,
  infoProps,
  clubsDataProps,
  matchItemProps,
  userProfile,
}) => {
  const renderActiveSection = useCallback(() => {
    switch (activeNavItem) {
      case BIO_VIEW:
      default:
        const { openBioModal, tooltip, bioItems } = infoProps;

        return (
          <div className={`${style[`bioWrapper`]}`}>
            <div className={style.playerCardWrapper}>
              <PlayerCardV2 data={userProfile} />
            </div>
            <div
              key={activeNavItem}
              className={`${style[`wrapper__blowUpExit`]} ${
                style['infoViewWrapper']
              }`}
            >
              {isProfileOwner && (
                <div className='editIconWrapper'>
                  <ElementWithTooltip tooltip={tooltip}>
                    <Button narrow animate onClick={openBioModal}>
                      <EditIcon />
                    </Button>
                  </ElementWithTooltip>
                </div>
              )}
              <div className={style.infoViewInnerWrapper}>
                <InfoCard items={bioItems} />
              </div>
            </div>
          </div>
        );
      case MY_GAME_EVENTS:
        return (
          <div
            key={activeNavItem}
            className={`${style[`wrapper__blowUpExit`]}`}
          >
            <GameEventsListView />
          </div>
        );
      case CLUBS_VIEW:
        return (
          <div
            key={activeNavItem}
            className={`${style[`wrapper__blowUpExit`]}`}
          >
            <ClubsView clubItems={clubsDataProps} />
          </div>
        );

      case MATCH_LISTS_VIEW:
        return (
          <div
            key={activeNavItem}
            className={`${style[`wrapper__blowUpExit`]}`}
          >
            <MatchListsView {...matchItemProps} />
          </div>
        );
    }
  }, [
    activeNavItem,
    infoProps,
    isProfileOwner,
    userProfile,
    clubsDataProps,
    matchItemProps,
  ]);

  return <>{renderActiveSection()}</>;
};

ActiveSection.defaultProps = {
  infoProps: { bioItems: [] },
};

ActiveSection.propTypes = {
  activeNavItem: PropTypes.string,
  isProfileOwner: PropTypes.bool,
  infoProps: PropTypes.shape({
    tooltip: PropTypes.string.isRequired,
    openBioModal: PropTypes.func.isRequired,
    bioItems: PropTypes.array,
    userProfile: PropTypes.object, // TODO-BACKLOG - add shape
  }),
  clubsDataProps: PropTypes.shape({}),
  matchItemProps: PropTypes.shape({}),
};

export default ActiveSection;
