import { useState, useEffect, useCallback } from 'react';

const useCallbackOnScroll = (onScrollUp, onScrollDown) => {
  const [currentHeight, setCurrentHeight] = useState(0);

  const handleScrolling = useCallback(() => {
    const activeHeight = window.pageYOffset;

    if (activeHeight > currentHeight) {
      onScrollDown();
    } else if (activeHeight <= currentHeight) {
      onScrollUp();
    }

    setCurrentHeight(window.pageYOffset);
  }, [currentHeight, onScrollDown, onScrollUp]);

  useEffect(() => {
    setCurrentHeight(window.pageYOffset);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScrolling, {
      once: true,
      capture: true,
    });

    return window.removeEventListener('scroll', handleScrolling);
  }, [handleScrolling]);
};

export default useCallbackOnScroll;
