import IconWithLabelCTA from 'common/components/icon-with-label-cta';
import { HORIZONTAL_LAYOUT } from 'common/components/icon-with-label-cta';
import { useAnimationExit } from 'common/hooks';
import PropTypes from 'prop-types';
import { Children } from 'react';
import NotificationOfferCard, {
  NotificationOfferCardPropTypes,
} from 'common/components/cards/notification-offer-card';
import { COMPONENT_VARIANT_SMALL } from 'common/components/cards/notification-offer-card/constants';
import style from './DropdownMenu.module.scss';
import { useCallback } from 'react';
import ClubLogo from '../club-logo';
import NotificationsBadge from '../notifications-badge';
import { INACTIVE_CLUB_VALUE } from 'common/api/constants';
import Image from '../image';

export const RIGHT_DROPDOWN_SIDE = 'right';
export const LEFT_DROPDOWN_SIDE = 'left';
export const BOTTOM_DROPDOWN_SIDE = 'bottom';
const className = 'c-DropdownMenu';

const DropdownMenu = ({
  pinnedItems,
  items,
  isOpened,
  onToggle,
  Icon,
  renderElement,
  iconLabel,
  onlyLabel,
  dropdownSide,
  smallVariantNotificationCard,
  numberOfUnread,
}) => {
  // allow different drop-items

  // 1 - itemWithIcon
  // 2 - itemWithImg
  const dropdownExitClassName =
    dropdownSide === RIGHT_DROPDOWN_SIDE
      ? style['right-dropdown-exit']
      : dropdownSide === BOTTOM_DROPDOWN_SIDE
      ? style['bottom-dropdown-exit']
      : style['left-dropdown-exit'];
  const { exitFlag: isClosed, exitClassNames } = useAnimationExit(
    !isOpened,
    dropdownExitClassName,
    300
  );

  const notificationCardItemVariantClassName = smallVariantNotificationCard
    ? `${style['item--notification-card-variant']}`
    : '';

  const openedDropdownClassName = isOpened ? style['items--opened'] : '';
  const bottomDropdownClassName =
    isOpened && dropdownSide === BOTTOM_DROPDOWN_SIDE
      ? style['items--bottom--opened']
      : '';
  const dropdownSideClassName = `${style[`items--${dropdownSide}`]}`;
  const renderItems = _items => {
    const renderIcon = item => {
      const retiredItemClassName =
        item?.status === INACTIVE_CLUB_VALUE ? style['retired-item'] : '';
      if (item?.logo || item?.image) {
        return (
          <div
            className={`${style['logo-wrapper']} ${retiredItemClassName}`}
            onClick={item?.handler}
          >
            {item?.logo && <ClubLogo {...item?.logo} />}
            {item?.image && <Image {...item?.image} />}
            <span>{item?.label}</span>
          </div>
        );
      } else if (item?.Icon) {
        // NOTE was used when an icon was shown for a player role
        // TODO check if this is still needed
        return (
          <div className={retiredItemClassName}>
            <IconWithLabelCTA {...item} layout={HORIZONTAL_LAYOUT} />
          </div>
        );
      }
    };
    return Children.toArray(
      _items?.map(item => (
        <li
          className={`${style.item} ${notificationCardItemVariantClassName} ${className}__notification-item`}
        >
          {renderIcon(item)}
          {item?.notificationCardProps && (
            <NotificationOfferCard
              variant={COMPONENT_VARIANT_SMALL}
              {...item?.notificationCardProps}
            />
          )}
        </li>
      ))
    );
  };

  const renderIcon = useCallback(() => {
    if (renderElement) {
      return (
        <div
          className={style['gm-role-icon-wrapper-mobile']}
          onClick={onToggle}
        >
          {renderElement()}
          <NotificationsBadge numberOfUnread={numberOfUnread} />
        </div>
      );
    } else {
      return (
        <IconWithLabelCTA
          Icon={Icon}
          handler={onToggle}
          label={iconLabel}
          onlyLabel={onlyLabel}
          numberOfUnread={numberOfUnread}
        />
      );
    }
  }, [Icon, iconLabel, numberOfUnread, onToggle, onlyLabel, renderElement]);

  return (
    <div className={`${className} ${style.wrapper}`}>
      {/* Icon */}
      {renderIcon()}

      {/* Menu */}
      <div
        // eslint-disable-next-line max-len
        className={`${className}__items ${openedDropdownClassName} ${bottomDropdownClassName} ${style.items} ${dropdownSideClassName} ${exitClassNames}`}
      >
        {!isClosed && (
          <ul className={style['pinned-items']}>{renderItems(pinnedItems)}</ul>
        )}
        {!isClosed && (
          <ul className={style['scrollable-items']}>{renderItems(items)}</ul>
        )}
      </div>
    </div>
  );
};

const DropdownMenuItemPropTypes = PropTypes.shape({
  Icon: PropTypes.func.isRequired,
  iconLabel: PropTypes.string,
  onlyLabel: PropTypes.bool,
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  dropdownSide: PropTypes.oneOf([RIGHT_DROPDOWN_SIDE, LEFT_DROPDOWN_SIDE]),
  notificationCardProps: PropTypes.shape(NotificationOfferCardPropTypes),
});

DropdownMenu.defaultProps = {
  items: [],
  pinnedItems: [],
};

DropdownMenu.propTypes = {
  pinnedItems: PropTypes.arrayOf(DropdownMenuItemPropTypes),
  items: PropTypes.arrayOf(DropdownMenuItemPropTypes),
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
  Icon: PropTypes.func.isRequired,
  smallVariantNotificationCard: PropTypes.bool,
  renderElement: PropTypes.func,
  numberOfUnread: PropTypes.number,
};

export default DropdownMenu;
