import { ClubLogoPropTypes } from 'common/components/club-logo/ClubLogo';
import SmartLogo from 'common/components/smart-logo';
import Text, { TextPropTypes } from 'common/components/text';
import { AWAY_TEAM_WIN, DRAW, HOME_TEAM_WIN } from 'features/match/constants';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useMemo } from 'react';
import style from './TrianglesCard.module.scss';

// NOTE infoBottom is called because of the original format for the standard FrontFaceView card
// needed: outcome, date, location
const TrianglesCard = ({ infoBottom, matchMeta }) => {
  const homeTeam = useMemo(
    () => infoBottom?.doubleVariantData?.leftSideData,
    [infoBottom?.doubleVariantData?.leftSideData]
  );
  const awayTeam = useMemo(
    () => infoBottom?.doubleVariantData?.rightSideData,
    [infoBottom?.doubleVariantData?.rightSideData]
  );
  const leftLogo = useMemo(() => homeTeam?.infoLogo, [homeTeam?.infoLogo]);
  const rightLogo = useMemo(() => awayTeam?.infoLogo, [awayTeam?.infoLogo]);

  const leftTeamName = useMemo(
    () => homeTeam?.infoOne?.data?.[0]?.text,
    [homeTeam?.infoOne?.data]
  );
  const leftTeamNameText = useMemo(
    () => [
      { text: leftTeamName, isBolder: true },
      { text: `, ${homeTeam?.infoTwo?.data?.[0]?.text.split(',')?.[0]}` },
    ],
    [homeTeam?.infoTwo?.data, leftTeamName]
  );

  const rightTeamName = useMemo(
    () => awayTeam?.infoOne?.data?.[0]?.text,
    [awayTeam?.infoOne?.data]
  );
  const rightTeamNameText = useMemo(
    () => [
      { text: rightTeamName, isBolder: true },
      { text: `, ${awayTeam?.infoTwo?.data?.[0]?.text.split(',')?.[0]}` },
    ],
    [awayTeam?.infoTwo?.data, rightTeamName]
  );

  const renderCenterInfo = useCallback(() => {
    const outcome =
      matchMeta?.outcome === AWAY_TEAM_WIN
        ? rightTeamName
        : matchMeta?.outcome === HOME_TEAM_WIN
        ? leftTeamName
        : 'Draw';

    const textData = [
      { text: outcome, isBolder: true, isNewLine: true },
      { text: `${matchMeta?.city}, ${matchMeta?.date}` },
      {},
    ];

    return (
      <div className={style.centerInfo}>
        <Text data={textData} color='light' />
      </div>
    );
  }, [leftTeamName, matchMeta, rightTeamName]);

  return (
    <div className={style.wrapper}>
      <div className={style.cardOverlay}>
        <div className={style['leftTriangle--text']}>
          <Text data={leftTeamNameText} color='light' />
        </div>
        {renderCenterInfo()}
        <div className={style['rightTriangle--text']}>
          <Text data={rightTeamNameText} color='light' />
        </div>
      </div>
      {/* Triangle Wrapper */}
      <div className={style.triangle}>
        {/* Box */}
        <div className={`${style.box} ${style.leftTriangle}`}>
          <SmartLogo clubLogo={leftLogo} />
        </div>
      </div>

      {/* Box */}
      <div className={`${style.box} ${style.rightTriangle}`}>
        <SmartLogo clubLogo={rightLogo} />
      </div>
    </div>
  );
};

TrianglesCard.propTypes = {
  matchMeta: PropTypes.shape({
    date: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    outcome: PropTypes.oneOf([AWAY_TEAM_WIN, DRAW, HOME_TEAM_WIN]),
  }),
  infoBottom: PropTypes.shape({
    doubleVariantData: PropTypes.arrayOf(
      PropTypes.shape({
        leftSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
        rightSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
      })
    ),
  }),
};

export default TrianglesCard;
