import { useWindowSizeContext } from 'contexts/window-size';
import Match from 'features/match/Match';
import Tabs from 'features/tabs';
import MatchList from './components/match-list';
import { useProps } from './hooks';
import PropTypes from 'prop-types';
import style from './MatchListsView.module.scss';

const MatchListsView = ({ matchItems }) => {
  const { tabsData, formattedMatchItems, selectedMatch, closeMatchModal } =
    useProps({
      matchItems,
    });
  const { isDesktopView } = useWindowSizeContext();

  return (
    <div className={style.wrapper}>
      <Tabs tabs={tabsData} />

      <MatchList isDesktopView={isDesktopView} items={formattedMatchItems} />

      {!!selectedMatch && (
        <Match onClose={closeMatchModal} data={selectedMatch} forMatchSection />
      )}
    </div>
  );
};

MatchListsView.propTypes = {
  matchItems: PropTypes.array,
};

export default MatchListsView;
