import NotificationOfferCard from 'common/components/cards/notification-offer-card';
import { COMPONENT_VARIANT_SMALL } from 'common/components/cards/notification-offer-card/constants';
import { ImagePropTypes } from 'common/components/image';
import { TextPropTypes } from 'common/components/text';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './SearchCards.module.scss';

const SearchCards = ({ items }) => {
  const renderItems = useCallback(
    () =>
      Children.toArray(
        items.map(item => (
          <li className={style.card}>
            <NotificationOfferCard
              {...item}
              variant={COMPONENT_VARIANT_SMALL}
            />
          </li>
        ))
      ),
    [items]
  );

  return <ul className={style.listWrapper}>{renderItems()}</ul>;
};

SearchCards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.shape(TextPropTypes).isRequired,
      img: PropTypes.shape(ImagePropTypes).isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default SearchCards;
