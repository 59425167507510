export const ICON_DARK_THEME = '#f2f2f2';
export const PRIMARY_DARK_COLOR = '#006db3';
export const SUCCESS_COLOR = '#4caf50ee';
export const SUCCESS_COLOR_SOLID = '#4caf50';
export const DANGER_COLOR = '#ef5350ee';
export const DANGER_COLOR_SOLID = '#ef5350';
export const WARNING_COLOR = '#ffeb3bee';
export const WARNING_COLOR_SOLID = '#ffeb3b';
export const WHITE_COLOR = '#f2f2f2';
export const DARK_MATERIAL_PRIMARY_COLOR = '#90cdff';
export const DARK_MATERIAL_SECONDARY_COLOR = '#5ed4fd';
export const DARK_MATERIAL_TERTIARY_COLOR = '#dfb7ff';
