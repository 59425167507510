import { BOTTOM_NAV, TOP_NAV } from 'features/navbar/constants';
import PropTypes from 'prop-types';
import style from './Nav.module.scss';

const Nav = ({ position, classNames, children }) => {
  const positionClassName =
    position === TOP_NAV
      ? style['top-position']
      : position === BOTTOM_NAV
      ? style['bottom-position']
      : '';

  return (
    <nav className={`${style.wrapper} ${positionClassName} ${classNames}`}>
      {children}
    </nav>
  );
};

Nav.propTypes = {
  position: PropTypes.oneOf([TOP_NAV, BOTTOM_NAV]),
  classNames: PropTypes.string,
};

export default Nav;
