import Loading from 'common/components/loading';
import React, { Suspense } from 'react';
import { PublicNavContextProvider } from 'contexts/public-nav';
import { PrivateNavContextProvider } from 'contexts/private-nav';
import { WindowSizeContextProvider } from 'contexts/window-size';
import { MetaContextProvider } from 'contexts/meta';
import { AuthContextProvider } from 'contexts/auth';
import { UserSessionContextProvider } from 'contexts/user-session';
import { EventsContextProvider } from 'contexts/events';
import ErrorBoundary from 'pages/error-boundary';
import AppContainer from './AppContainer';
import CacheBuster from 'react-cache-buster';
import packageJson from '../../package.json';
import ReactGA from 'react-ga';
import './App.scss';

const isProduction =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

if (isProduction) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => (
  <CacheBuster
    currentVersion={packageJson.version}
    isEnabled={isProduction}
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <Suspense fallback={Loading}>
      <ErrorBoundary>
        <EventsContextProvider>
          <MetaContextProvider>
            <AuthContextProvider>
              <UserSessionContextProvider>
                <WindowSizeContextProvider>
                  <PublicNavContextProvider>
                    <PrivateNavContextProvider>
                      <AppContainer />
                    </PrivateNavContextProvider>
                  </PublicNavContextProvider>
                </WindowSizeContextProvider>
              </UserSessionContextProvider>
            </AuthContextProvider>
          </MetaContextProvider>
        </EventsContextProvider>
      </ErrorBoundary>
    </Suspense>
  </CacheBuster>
);

export default App;
