import PlayerCardV2PropTypes from './PlayerCardV2PropTypes';
import { useCallback } from 'react';
import style from './PlayerCardV2.module.scss';
import ReactCountryFlag from 'react-country-flag';
import { formatImage } from 'common/utils/formatting';
import Image from 'common/components/image';
import {
  mapExperienceToStars,
  mapSkillsType,
  sortSkillsByRate,
} from './mappers';
import { COUNTRY_CODES } from 'common/constants/geoData';
import { useProps } from './hooks';
import { BEGINNER_SKILL_LEVEL } from 'features/game-event-features/create-edit-game-event/constants';
import { useNavigation } from 'common/hooks';

const PlayerCardV2 = ({ data }) => {
  const {
    showSkillLevel,
    showSkillLevelHandler,
    hideSkillLevelHandler,

    aBoxClassNameVariant,
    experienceLevelClassName,

    showNationalityHandler,
    hideNationalityHandler,
    showNationalityClassName,

    showSecondaryPositions,
    showSecondaryPositionsHandler,
    hideSecondaryPositionsHandler,
    showSecondaryPositionsClassName,
  } = useProps(data);
  const { navigateToUserPage } = useNavigation();

  const renderSkillItemsColumn = useCallback((skills, type) => {
    const skillItems = skills
      ?.map(([skill, skillType]) => {
        const skillItemClassName = `${style.skillItem} ${
          style[`skillItem__${type}--${skillType}`]
        }`;

        return (
          <p key={skill} className={skillItemClassName}>
            {skill || '---'}
          </p>
        );
      })
      .slice(0, 3);

    return <div className={style.rowItemWrapper}>{skillItems}</div>;
  }, []);

  const renderSkills = useCallback(
    skills => {
      const technicalSkills = mapSkillsType(skills?.technical);
      const otherSkills = sortSkillsByRate([
        ...mapSkillsType(skills?.physical),
        ...mapSkillsType(skills?.mental),
      ]);

      const transparencyClassName = showSecondaryPositions
        ? style.transparent
        : style['semi-transparent'];

      return (
        <div className={`${style.skillsWrapper} ${transparencyClassName}`}>
          {renderSkillItemsColumn(technicalSkills, 'technical')}
          {renderSkillItemsColumn(otherSkills, 'other')}
        </div>
      );
    },
    [renderSkillItemsColumn, showSecondaryPositions]
  );

  const renderSecondaryPositions = useCallback(() => {
    const secondaryPositionsItems = data?.positions?.secondary
      ?.map(position => {
        return <p key={position}>{position || '---'}</p>;
      })
      .splice(0, 3);

    if (secondaryPositionsItems?.length === 0) return null;

    return (
      <div
        className={`${style.secondaryPositionsWrapper} ${style[showSecondaryPositionsClassName]}`}
      >
        {secondaryPositionsItems}
      </div>
    );
  }, [data?.positions?.secondary, showSecondaryPositionsClassName]);

  const goToUserProfile = useCallback(() => {
    navigateToUserPage(data?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id]);

  return (
    <div className={style.wrapper}>
      <div className={style.overlay}></div>

      {/* Upper part wrapper */}
      <div className={style.upperWrapper}>
        {/* First Row */}
        <div className={style['upperWrapper__firstRow']}>
          {/* A */}
          <div
            className={`${style.rowItemWrapper} ${style['upperWrapper__badges-wrapper']}`}
          >
            <div
              className={`${style.aBox} ${style[aBoxClassNameVariant]}`}
              onMouseEnter={e => {
                e.stopPropagation();
                showSecondaryPositionsHandler();
              }}
              onMouseLeave={e => {
                e.stopPropagation();
                hideSecondaryPositionsHandler();
              }}
              onClick={e => {
                e.stopPropagation();
                showSecondaryPositionsHandler();
              }}
            >
              {data?.positions?.shortNaturalPosition ? (
                <>
                  <p>{data?.positions?.shortNaturalPosition}</p>
                  <p>{data?.positions?.primaryArea || '---'}</p>
                </>
              ) : (
                'N/A'
              )}
            </div>
          </div>
          <div className={style.playerImg} onClick={goToUserProfile}>
            <Image {...formatImage(data?.image?.url)} />
            {data?.geoInfo?.nationality && (
              <div
                className={`${style.playerNationality} ${style[showNationalityClassName]}`}
              >
                <ReactCountryFlag
                  countryCode={COUNTRY_CODES[data?.geoInfo?.nationality]}
                  svg
                />
              </div>
            )}
          </div>

          {/* B wrapper */}
          <div
            className={`${style.rowItemWrapper} ${style['upperWrapper__badges-wrapper']}`}
          >
            <div
              className={style.bBox}
              onMouseEnter={showSkillLevelHandler}
              onMouseLeave={hideSkillLevelHandler}
              onClick={e => {
                e.stopPropagation();
                showSkillLevelHandler();
              }}
            >
              {mapExperienceToStars(
                data?.playerGeneralInfo?.experienceLevel || BEGINNER_SKILL_LEVEL
              )}
            </div>
            <div
              className={style.bBox}
              onClick={e => {
                e.stopPropagation();
                showNationalityHandler();
              }}
              onMouseEnter={showNationalityHandler}
              onMouseLeave={hideNationalityHandler}
            >
              <p className='text-overflow no-spacing'>{data?.geoInfo?.city}</p>
            </div>
          </div>
        </div>
        {/* Second Row */}
        <div
          className={style['upperWrapper__secondRow']}
          onClick={goToUserProfile}
        >
          <p className={`${style['upperWrapper__secondRow--sub-info']}`}>
            {data?.firstName}
          </p>
          <p className={`${style['upperWrapper__secondRow--main-info']}`}>
            {data?.lastName}
          </p>

          {showSkillLevel ? (
            <p
              className={`${style.skillLevel__base} ${style[experienceLevelClassName]}`}
            >
              {data?.playerGeneralInfo?.experienceLevel || BEGINNER_SKILL_LEVEL}
            </p>
          ) : (
            <p className={style['upperWrapper__secondRow--third-info']}>
              {data?.positions?.natural || '---'}
            </p>
          )}
        </div>
      </div>

      {/* Bottom part wrapper */}
      <div className={style.bottomWrapper}>
        {data?.skills && renderSkills(data?.skills)}
        {renderSecondaryPositions()}
      </div>
    </div>
  );
};

PlayerCardV2.propTypes = PlayerCardV2PropTypes;
export default PlayerCardV2;
