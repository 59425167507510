import {
  BLUE_NEON_COLOR,
  GREEN_NEON_COLOR,
  VIOLET_NEON_COLOR,
  YELLOW_NEON_COLOR,
} from './general';

// TODO LEGACY code using these variables should be updated to read from
// `features/logo-creator/constants`
export const YELLOW_CLUB_NEON_COLOR = YELLOW_NEON_COLOR;
export const BLUE_CLUB_NEON_COLOR = BLUE_NEON_COLOR;
export const GREEN_CLUB_NEON_COLOR = GREEN_NEON_COLOR;
export const VIOLET_CLUB_NEON_COLOR = VIOLET_NEON_COLOR;

export const CLUB_COLORS = [
  YELLOW_CLUB_NEON_COLOR,
  BLUE_CLUB_NEON_COLOR,
  GREEN_CLUB_NEON_COLOR,
  VIOLET_CLUB_NEON_COLOR,
];

export const CLUB_WIN_OUTCOME = 'win';
export const CLUB_LOSE_OUTCOME = 'lose';
export const CLUB_DRAW_OUTCOME = 'draw';
