import { useAnimationExit } from 'common/hooks';
import PropTypes from 'prop-types';
import CloseButton from 'common/components/use-case-buttons/close-button';
import style from './AsideView.module.scss';

const viewExitClassNames = `${style.exit}`;

const AsideView = ({ onClose, toRender, isOpened }) => {
  const { exitFlag: isClosed, exitClassNames } = useAnimationExit(
    !isOpened,
    viewExitClassNames,
    150
  );

  if (isClosed) return null;

  return (
    <div
      className={`${style.wrapper} ${exitClassNames}`}
      onClick={e => e.stopPropagation()}
    >
      <CloseButton onClose={onClose} />
      {toRender()}
    </div>
  );
};

export const AsideViewPropTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  toRender: PropTypes.func.isRequired,
};

AsideView.propTypes = PropTypes.shape(AsideViewPropTypes);

export default AsideView;
