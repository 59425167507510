import { ClubLogoPropTypes } from 'common/components/club-logo/ClubLogo';
import Image, { ImagePropTypes } from 'common/components/image';
import PlayerSignature, {
  PlayerSignaturePropTypes,
} from 'common/components/player-signature';
import SmartLogo from 'common/components/smart-logo';
import Text, { TextPropTypes } from 'common/components/text';
import {
  INFO_BOTTOM_TYPE_DOUBLE,
  INFO_BOTTOM_TYPE_SINGLE,
  INFO_TOP_TYPE_IMAGE,
  INFO_TOP_TYPE_TEXT,
} from 'features/base-3-part-card/constants';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import MatchCardOverlay from '../match-card-overlay';
import style from './FrontFaceView.module.scss';

const FrontFaceView = ({
  dynamicClassNameSufix,
  infoTop,
  infoCenter,
  infoBottom,
  bgVariant,
  onClick,
  signature,
  smallFrontFaceCardVariant,
}) => {
  const renderInfoCenter = useCallback(() => {
    if (infoCenter.type === INFO_TOP_TYPE_TEXT) {
      return (
        <div className={style['infoCenter--text-wrapper']}>
          <MatchCardOverlay bgVariant={bgVariant} />
          <Text {...infoCenter.value} />
        </div>
      );
    } else if (infoCenter.type === INFO_TOP_TYPE_IMAGE) {
      return (
        <div className={style[`infoCenter--logo-wrapper`]}>
          <div className={style['infoCenter--logo-overlay']}></div>
          {infoCenter?.image ? (
            <Image {...infoCenter?.image} />
          ) : (
            <SmartLogo clubLogo={infoCenter.value} />
          )}
        </div>
      );
    }
  }, [bgVariant, infoCenter?.image, infoCenter.type, infoCenter.value]);

  const renderInfoBottomDoubleSide = useCallback(
    data => (
      <div className={style['infoBottomDouble__side']} onClick={data?.onClick}>
        <div className={style['infoBottomDouble__side__info-one']}>
          <Text {...data?.infoOne} />
        </div>
        <div className={style['infoBottomDouble__side__info-two']}>
          <Text {...data?.infoTwo} />
        </div>
        <div className={style.infoBottomLogo}>
          <SmartLogo clubLogo={data?.infoLogo} />
        </div>
      </div>
    ),
    []
  );

  const renderInfoBottom = useCallback(() => {
    let toRender;

    if (infoBottom.type === INFO_BOTTOM_TYPE_SINGLE) {
      toRender = (
        <div
          className={`${style.infoBottomSingle} c-FrontFaceView--infoBottomSingle`}
          onClick={infoBottom?.singleVariantData?.onClick}
        >
          {/* Info Bottom 1 */}
          <div className={style[`infoBottomSingle--info-one`]}>
            <Text {...infoBottom?.singleVariantData?.infoOne} />
          </div>

          {/* Info Bottom 2 */}
          <div className={style[`infoBottomSingle--info-two`]}>
            <Text {...infoBottom?.singleVariantData?.infoTwo} />
          </div>
        </div>
      );
    } else if (infoBottom.type === INFO_BOTTOM_TYPE_DOUBLE) {
      toRender = (
        <div className={style.infoBottomDouble}>
          {/* Info Bottom Left */}

          {renderInfoBottomDoubleSide(
            infoBottom?.doubleVariantData?.leftSideData
          )}

          {/* Info Bottom Right */}

          {renderInfoBottomDoubleSide(
            infoBottom?.doubleVariantData?.rightSideData
          )}
        </div>
      );
    }

    return <div className={style.infoBottom}>{toRender}</div>;
  }, [
    infoBottom?.doubleVariantData?.leftSideData,
    infoBottom?.doubleVariantData?.rightSideData,
    infoBottom?.singleVariantData?.infoOne,
    infoBottom?.singleVariantData?.infoTwo,
    infoBottom?.singleVariantData?.onClick,
    infoBottom.type,
    renderInfoBottomDoubleSide,
  ]);

  const frontFaceSufixSmall = smallFrontFaceCardVariant ? '--small' : '';

  return (
    <div
      className={`${style.wrapper} ${
        style[`wrapper--${dynamicClassNameSufix}`]
      } ${style[`wrapper--${dynamicClassNameSufix}${frontFaceSufixSmall}`]}
       c-FrontFaceView`}
      onClick={onClick}
    >
      {/* info top row */}
      {infoTop && (
        <div className={style.infoTop}>
          <Text {...infoTop} />
          {signature && (
            <div className={style.signatureWrapper}>
              <PlayerSignature {...signature} />
            </div>
          )}
          {signature && (
            <div className={style.signatureWrapper}>
              <PlayerSignature {...signature} />
            </div>
          )}
        </div>
      )}

      {/* info center row */}
      <div className={style.infoCenter}>{renderInfoCenter()}</div>

      {/* info bottom row */}
      {renderInfoBottom()}
    </div>
  );
};

FrontFaceView.defaultProps = {
  infoTop: {
    data: [{ text: '' }],
  },
};

export const FrontFaceViewPropTypes = {
  bgVariant: PropTypes.oneOf(['neutral', 'dark', 'win', 'lose', 'draw']),
  onClick: PropTypes.func,
  dynamicClassNameSufix: PropTypes.string,
  infoTop: PropTypes.shape(TextPropTypes),
  infoCenter: PropTypes.shape({
    type: PropTypes.oneOf(['image', 'text']),
    image: PropTypes.shape(ImagePropTypes),
    value: PropTypes.oneOfType([
      PropTypes.shape(ClubLogoPropTypes),
      PropTypes.shape(TextPropTypes),
    ]),
  }),
  infoBottom: PropTypes.shape({
    type: PropTypes.oneOf(['single', 'double']),
    singleVariantData: PropTypes.shape({
      infoOne: PropTypes.shape(TextPropTypes),
      infoTwo: PropTypes.shape(TextPropTypes),
    }),
    doubleVariantData: PropTypes.arrayOf(
      PropTypes.shape({
        leftSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
        rightSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
      })
    ),
  }),
  signature: PropTypes.shape(PlayerSignaturePropTypes),
  smallFrontFaceCardVariant: PropTypes.bool,
};

FrontFaceView.propTypes = FrontFaceViewPropTypes;

export default FrontFaceView;
