import Headline from 'common/components/headline';
import PropTypes from 'prop-types';
import style from './ScreenCardV2.module.scss';
import { formatImage } from 'common/utils/formatting';
import { SCREEN_CARD_PATTERN_BG } from 'pages/street/constants';
import Image from 'common/components/image';

const ScreenCardV2 = ({
  name,
  subtitle = 'Screen Subtitle',
  description,
  Icon,
  onClick,
  isComingSoon,
  coverVariant,
}) => {
  const withHoverClassName = !isComingSoon ? style['wrapper--with-hover'] : '';

  return (
    <div onClick={onClick} className={`${style.wrapper} ${withHoverClassName}`}>
      <div className={style.patternBgWrapper}>
        <Image {...formatImage(SCREEN_CARD_PATTERN_BG)} />
      </div>
      {/* Cover Box */}
      <div
        className={`${style.coverBox} ${style[`coverBox--${coverVariant}`]}`}
      >
        {isComingSoon && (
          <div className={style.comingSoonOverlay}>
            <Headline mainText={[{ text: 'Coming Soon' }]} size={2} />
          </div>
        )}
        {/* Icon */}
        <div className={style.iconWrapper}>
          <Icon />
        </div>

        {/* Title */}
        <div className={style.coverTitleWrapper}>
          <Headline mainText={[{ text: name }]} size={2} variant='primary' />
        </div>
      </div>

      {/* Text Box */}
      <div className={style.textBox}>
        <h4 className={style.subtitle}>{subtitle}</h4>
        <p className={style.description}>{description}</p>
      </div>
    </div>
  );
};

export const ScreenCardV2PropTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  isComingSoon: PropTypes.bool,
  coverVariant: PropTypes.number.isRequired,
};

ScreenCardV2.propTypes = ScreenCardV2PropTypes;

export default ScreenCardV2;
