import Button from 'common/components/button';
import CloseButton from 'common/components/use-case-buttons/close-button';
import { useMetaContext } from 'contexts/meta';
import './InstallButton.scss';

const className = 'c-InstallButton';

const InstallButton = () => {
  const { shouldShowInstallButton, installAppHandler, clearInstallPrompt } =
    useMetaContext();

  if (!shouldShowInstallButton) return null;

  return (
    <div className={className}>
      <div className={`${className}__inner-wrapper`}>
        <Button narrow withRipples onClick={installAppHandler}>
          Install
        </Button>
        <p>Become fully a part of our Universe 🙌!</p>
        <CloseButton onClose={clearInstallPrompt} />
      </div>
    </div>
  );
};

export default InstallButton;
