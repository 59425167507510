import { TWO_MINUTES } from 'common/constants/integers';
import { API_BASE } from '_config';

const API_BASE_PATH = `${API_BASE}/v1`;
const PUBLIC_API_BASE_PATH = `${API_BASE}/public/v1`;

const API_V1_BASE_PATH = `${API_BASE_PATH}/api`;
const API_V2_BASE_PATH = `${API_BASE}/v2/api`;
const PUBLIC_API_V1_BASE_PATH = `${PUBLIC_API_BASE_PATH}/api`;
const AUTH_V1_BASE_PATH = `${API_BASE_PATH}/auth`;

// API
// USERS
export const REFRESH_TOKEN_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/refresh-token`;
export const LOGIN_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/login`;
export const LOGOUT_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/logout`;
export const REGISTER_API_ENDPOINT = `${API_V2_BASE_PATH}/users`;
export const UPLOAD_USER_PROFILE_IMAGE_ENDPOINT = `${AUTH_V1_BASE_PATH}/profile-image`;
export const VALIDATE_ACCOUNT_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/validate-user`;
export const RESET_PASSWORD_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/password-reset`;
export const USER_API_ENDPOINT = `${AUTH_V1_BASE_PATH}/users`;
export const UPDATE_EMAIL_ENDPOINT = `${AUTH_V1_BASE_PATH}/settings`;
export const UDPATE_PASSWORD_ENDPOINT = `${AUTH_V1_BASE_PATH}/settings`;
export const DELETE_PROFILE_ENDPOINT = `${AUTH_V1_BASE_PATH}/profile-delete`;
export const UPDATE_USER_BIO_ENDPOINT = `${API_V1_BASE_PATH}/user-profile`;

// --- v2
export const GET_USER_ENDPOINT_V2 = `${API_V2_BASE_PATH}/users`;
export const UPDATE_USER_BIO_ENDPOINT_V2 = `${API_V2_BASE_PATH}/users`;

// TODO refactor users api endpoints

// AFTER THE UPDATE TODO comeback to this
// export const USERS_ENDPOINT = `${API_V1_BASE_PATH}/users`;
export const USERS_ENDPOINT = `${API_V2_BASE_PATH}/users`;

// CLUBS
export const CLUBS_ENDPOINT = `${API_V1_BASE_PATH}/clubs`;
export const UPLOAD_CLUB_PROFILE_IMAGE_ENDPOINT = `${CLUBS_ENDPOINT}/club-profile-image`;
export const CLUB_MEMBERSHIPS_ENDPOINT = `${API_V1_BASE_PATH}/memberships`;

// TRANSFERS
export const TRANSFERS_ENDPOINT = `${API_V1_BASE_PATH}/transfers`;
export const OFFERS_ENDPOINT = `${API_V1_BASE_PATH}/offers`;
export const PENDING_OFFERS_STATUS = 'pending';

// SHORTLISTS
export const SHORTLISTS_ENDPOINT = `${API_V1_BASE_PATH}/shortlists`;

// NOTIFICATIONS
export const NOTIFICATIONS_ENDPOINT = `${API_V1_BASE_PATH}/notifications`;

// OTHER
export const SHORT_REFRESH_INTERVAL = 1000 * 60 * 45;
export const DEFAULT_DATA_REFRESH_INTERVAL = TWO_MINUTES;

// MESSAGES
export const NOT_LOGGED_IN_ERR_MSG = 'Not logged in.';

// BE constants
export const ACTIVE_CLUB_VALUE = 'active';
export const INACTIVE_CLUB_VALUE = 'retired';

// MATCHES
export const MATCHES_ENDPOINT = `${API_V1_BASE_PATH}/matches`;
export const PUBLIC_MATCHES_ENDPOINT = `${PUBLIC_API_V1_BASE_PATH}/matches`;

// GAME EVENTS
export const GAME_EVENTS_ENDPOINT = `${API_V1_BASE_PATH}/game-events`;
export const PUBLIC_GAME_EVENTS_ENDPOINT = `${PUBLIC_API_V1_BASE_PATH}/game-events`;

// STREET
export const STREET_FEED_ENDPOINT = `${API_V1_BASE_PATH}/street-feed`;

// SEARCH
export const SEARCH_ENDPOINT = `${API_V1_BASE_PATH}/search`;
