import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loading from 'common/components/loading';
import { createPortal } from 'react-dom';
import ErrorBoundary from 'pages/error-boundary';
import Modal from './components/modal';
import { TEST_ENVIRONMENT } from 'common/constants/config';
import { PORTAL_NODE_ID } from 'common/constants/domData';

const portal = document.getElementById(PORTAL_NODE_ID);
const isTestEnv = process?.env?.NODE_ENV === TEST_ENVIRONMENT;

const ModalContainer = ({ children, fullScreen, ...props }) =>
  createPortal(
    <Suspense fallback={Loading}>
      <ErrorBoundary>
        {fullScreen || isTestEnv ? (
          <Modal {...props} fullScreen={fullScreen}>
            {children}
          </Modal>
        ) : (
          <Modal {...props} fullScreen={fullScreen}>
            {children}
          </Modal>
        )}
      </ErrorBoundary>
    </Suspense>,
    portal
  );

ModalContainer.propTypes = {
  fullScreen: PropTypes.bool,
};

export default ModalContainer;
