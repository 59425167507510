import {
  BLUE_CLUB_NEON_COLOR,
  GREEN_CLUB_NEON_COLOR,
  VIOLET_CLUB_NEON_COLOR,
  YELLOW_CLUB_NEON_COLOR,
} from 'common/constants/clubs';
import AlienIcon from 'features/logo-creator/components/icons/alien-icon';
import ColonyIcon from 'features/logo-creator/components/icons/colony-icon';
import CraterIcon from 'features/logo-creator/components/icons/crater-icon';
import ExplorersIcon from 'features/logo-creator/components/icons/explorers-icon';
import PyramidIcon from 'features/logo-creator/components/icons/pyramid-icon';
import SphereIcon from 'features/logo-creator/components/icons/sphere-icon';
import StarshipIcon from 'features/logo-creator/components/icons/starship-icon';
import TesseractIcon from 'features/logo-creator/components/icons/tesseract-icon';
import {
  ALIEN_LOGO_SYMBOL,
  COLONY_LOGO_SYMBOL,
  CRATER_LOGO_SHAPE,
  EXPLORERS_LOGO_SYMBOL,
  PYRAMID_LOGO_SHAPE,
  SPHERE_LOGO_SHAPE,
  STARSHIP_LOGO_SYMBOL,
  TESSERACT_LOGO_SHAPE,
} from 'features/logo-creator/constants';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import './ClubLogo.scss';

const className = 'c-ClubLogo';

const ClubLogo = ({ shape, symbol, color, name, city, mini }) => {
  const renderShape = useCallback(() => {
    switch (shape) {
      case SPHERE_LOGO_SHAPE:
        return <SphereIcon />;
      case PYRAMID_LOGO_SHAPE:
        return <PyramidIcon />;
      case TESSERACT_LOGO_SHAPE:
        return <TesseractIcon />;
      case CRATER_LOGO_SHAPE:
        return <CraterIcon />;
      default:
        return null;
    }
  }, [shape]);

  const renderSymbol = useCallback(() => {
    switch (symbol) {
      case ALIEN_LOGO_SYMBOL:
        return <AlienIcon />;
      case EXPLORERS_LOGO_SYMBOL:
        return <ExplorersIcon />;
      case COLONY_LOGO_SYMBOL:
        return <ColonyIcon />;
      case STARSHIP_LOGO_SYMBOL:
        return <StarshipIcon />;
      default:
        return null;
    }
  }, [symbol]);

  const miniClassName = mini ? `${className}--mini` : '';

  return (
    <div
      className={`${className} ${miniClassName} ${className}__card--${color}`}
    >
      {/* Shape */}
      <div className={`${className}__shape`}>{renderShape()}</div>

      {/* Symbol */}
      <div
        className={`${className}__symbol ${className}__symbol--${symbol} ${className}__symbol--${color}`}
      >
        {renderSymbol()}
      </div>

      {/* Name */}
      <div className={`${className}__name`}>
        {/* Name */}
        <h3>{name}</h3>

        {/* City */}
        <h4 className={`${className}__name--city`}>{city}</h4>
      </div>
    </div>
  );
};

export const ClubLogoPropTypes = {
  shape: PropTypes.oneOf([
    SPHERE_LOGO_SHAPE,
    PYRAMID_LOGO_SHAPE,
    TESSERACT_LOGO_SHAPE,
    CRATER_LOGO_SHAPE,
  ]),
  symbol: PropTypes.oneOf([
    ALIEN_LOGO_SYMBOL,
    EXPLORERS_LOGO_SYMBOL,
    COLONY_LOGO_SYMBOL,
    STARSHIP_LOGO_SYMBOL,
  ]),
  color: PropTypes.oneOf([
    YELLOW_CLUB_NEON_COLOR,
    BLUE_CLUB_NEON_COLOR,
    GREEN_CLUB_NEON_COLOR,
    VIOLET_CLUB_NEON_COLOR,
  ]),
  name: PropTypes.string,
  city: PropTypes.string,
  mini: PropTypes.bool,
};

ClubLogo.propTypes = ClubLogoPropTypes;

export default ClubLogo;
