import {
  // getNotificationsService,
  updateLastReadNotificationService,
} from 'common/api/notificationsService';
// import { getOffersService } from 'common/api/offersService';
import {
  // REACT_GA_OFFERS_CLEARED_NON_USER_EVENT,
  REACT_GA_SELECT_TEAM_PROFILE_EVENT,
} from 'common/constants/react-google-analytics/events';
import { useAuthContext } from 'contexts/auth';
import { useState, useCallback, useEffect } from 'react';
import {
  // ACTIVE_GM_ROLE_CLUB_STORAGE_ITEM,
  ACTIVE_ROLE_LOCAL_STORAGE_ITEM,
  LAST_READ_NOTIFICATION_TIMESTAMP_TYPE,
  // liveOfferEvents,
  notificationsRoleTypes,
  PLAYER_USER_ROLE,
  // standardSubscriptionEvents,
} from './constants';
import useShortlists from './useShortlists';
import {
  // gmRoleCheckers,
  markNotificationsAsRead,
  // playerRoleCheckers,
} from './utils';
import ReactGA from 'react-ga';
import { getUser } from 'common/api/userService';

const useActiveRole = () => {
  const { user } = useAuthContext();

  const [activeRole, setActiveRole] = useState({
    id: user?._id,
    type: PLAYER_USER_ROLE,
    label: '',
    city: user?.geoInfo?.city,
  });
  const [notificationsData, setNotificationsData] = useState({});

  // NOTE atm used only for useShortlist needs
  // TODO see how this can be used for other use cases in order to improve the performances
  const [baseRoleData, setBaseRoleData] = useState({
    baseRoleShortlistData: [],
  });

  const { updateShortlistData, getShortlistMethodType } = useShortlists({
    roleType: activeRole?.type,
    gmId: activeRole?.gmId,
    initShortlistData: baseRoleData?.baseRoleShortlistData,
    id: activeRole?.id,
  });

  const switchRole = useCallback(role => {
    const { id, type, label, logo, gmId, status, city, image } = role;

    const stringifiedData = JSON.stringify({
      id,
      type,
      label,
      logo,
      gmId,
      status,
      city,
      image,
    });
    localStorage.setItem(ACTIVE_ROLE_LOCAL_STORAGE_ITEM, stringifiedData);
    setActiveRole(role);
  }, []);

  const switchToPlayerRole = useCallback(async () => {
    const id = user?._id;
    const label = user?.firstName;
    const image = user?.image?.url;

    const roleToSet = {
      type: PLAYER_USER_ROLE,
      id,
      label,
      city: user?.city,
      image,
    };

    switchRole(roleToSet);
    ReactGA.event(REACT_GA_SELECT_TEAM_PROFILE_EVENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeRole.image,
    user?._id,
    user?.city,
    user?.firstName,
    user?.image,
    user?.image?.url,
  ]);

  const updateBaseRoleData = useCallback(async () => {
    // TODO only load session info (name img id)
    const response = await getUser(
      activeRole?.id,
      activeRole?.type,
      activeRole?.id
    );
    const data = response?.data;
    const baseRoleShortlistData = [
      ...data?.clubsShortlist,
      ...data?.playersShortlist,
    ];
    setBaseRoleData({ baseRoleShortlistData });
  }, [activeRole?.id, activeRole?.type]);

  // const fetchOffersData = useCallback(async () => {
  //   const offersData = await getOffersService(
  //     activeRole?.id,
  //     notificationsRoleTypes[activeRole.type]
  //   );

  //   setOffersData(offersData.data);
  // }, [activeRole?.id, activeRole.type]);

  // const fetchNotificationsData = useCallback(async () => {
  //   const notificationsData = await getNotificationsService(
  //     activeRole?.id,
  //     notificationsRoleTypes[activeRole.type]
  //   );

  //   setNotificationsData(notificationsData?.data);
  //   setGmClubData({ gmClub: notificationsData?.data?.meta?.club });
  // }, [activeRole?.id, activeRole.type]);

  const sendLastReadNotificationTypeUpdate = useCallback(
    async type => {
      const shouldUpdate = notificationsData?.meta?.unreadNotifications;
      if (shouldUpdate) {
        const lastNotificationTimestamp =
          notificationsData?.data?.[0]?.updatedAt;
        await updateLastReadNotificationService(
          activeRole?.id,
          notificationsRoleTypes[activeRole?.type],
          lastNotificationTimestamp,
          type
        );

        setNotificationsData(curr => markNotificationsAsRead(curr));
      }
    },
    [
      activeRole?.id,
      activeRole?.type,
      notificationsData?.data,
      notificationsData?.meta?.unreadNotifications,
    ]
  );

  const sendLastReadNotificationUpdate = useCallback(
    () =>
      sendLastReadNotificationTypeUpdate(LAST_READ_NOTIFICATION_TIMESTAMP_TYPE),
    [sendLastReadNotificationTypeUpdate]
  );

  // const emitRGAOffersCleared = useCallback(offersCount => {
  //   if (offersCount === 0)
  //     ReactGA.event(REACT_GA_OFFERS_CLEARED_NON_USER_EVENT);
  // }, []);

  // const removeResolvedOfferFromList = useCallback(
  //   id => {
  //     setOffersData(curr => {
  //       const offersCopy = curr.filter(({ _id }) => _id !== id);
  //       emitRGAOffersCleared(offersCopy?.length);
  //       return offersCopy;
  //     });
  //   },
  //   [emitRGAOffersCleared]
  // );

  // useEffect(() => {
  //   subscribe(OFFER_RESOLVED_EVENT, removeResolvedOfferFromList);

  //   return subscribe(OFFER_RESOLVED_EVENT, removeResolvedOfferFromList);
  // }, [removeResolvedOfferFromList, subscribe]);

  useEffect(() => {
    switchToPlayerRole();
  }, [switchToPlayerRole]);

  useEffect(() => {
    if (activeRole?.id && activeRole?.id !== 'undefined') {
      updateBaseRoleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole?.id]);

  // useEffect(() => {
  //   subscribe(CLUB_LOGO_MINTED_EVENT, eventData => {
  //     const updatedNotificationsData = notificationsData?.data?.map(item => {
  //       if (item?.club?._id === eventData?.clubId) {
  //         item.club.logo = eventData?.clubLogo;
  //       }

  //       return item;
  //     });

  //     const updatedData = {
  //       ...notificationsData,
  //       data: updatedNotificationsData,
  //     };

  //     setNotificationsData(updatedData);
  //   });
  // }, [notificationsData, subscribe]);

  // const addLiveOffer = useCallback(data => {
  //   setOffersData(curr => {
  //     const currItems = curr.map(item => item?.id || item?._id || item);
  //     return [...new Set([...currItems, data?._id])];
  //   });
  // }, []);

  // useEffect(() => {
  //   // updating the number of offers badge on incoming live offers
  //   // when offers aside is opened, another data fetching is happening
  //   liveOfferEvents.forEach(event => {
  //     subscribe(event, addLiveOffer);
  //   });
  // }, [addLiveOffer, subscribe]);

  // const updateNotificationsWithLiveEvent = useCallback(data => {
  //   setNotificationsData(curr => {
  //     const updatedDataItems = [...new Set([data, ...curr?.data])];
  //     const uniqueIds = {};
  //     const updatedMeta = Object.assign({}, curr?.meta, {
  //       unreadNotifications: 'new',
  //     });

  //     updatedDataItems[0].isUnread = true;

  //     const updatedNotifications = {
  //       data: updatedDataItems.filter(item => {
  //         if (uniqueIds?.[item?._id]) {
  //           return false;
  //         } else {
  //           uniqueIds[item?._id] = true;
  //           return true;
  //         }
  //       }),
  //       meta: updatedMeta,
  //     };

  //     return updatedNotifications;
  //   });
  // }, []);

  // useEffect(() => {
  //   // subscribe to new live transfer resolved notification

  //   subscribe(NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION, data => {
  //     const formattedData = { ...data };
  //     if (formattedData.clubData && data?.createdBy === 'player')
  //       formattedData.club = formattedData.clubData;
  //     if (formattedData.playerData && data?.createdBy === 'club')
  //       formattedData.player = formattedData.playerData;

  //     updateNotificationsWithLiveEvent(formattedData);
  //   });
  // }, [subscribe, updateNotificationsWithLiveEvent]);

  // useEffect(() => {
  //   standardSubscriptionEvents.forEach(event => {
  //     subscribe(event, updateNotificationsWithLiveEvent);
  //   });
  // }, [subscribe, updateNotificationsWithLiveEvent]);

  return {
    activeRole,
    switchToPlayerRole,
    // playerRoleCheckers: playerRoleCheckers(completeActiveRoleData),
    // gmRoleCheckers: gmRoleCheckers(completeActiveRoleData),
    refreshRoleData: () => {
      // NOTE turning off
    },
    sendLastReadNotificationUpdate,
    updateShortlistData,
    getShortlistMethodType,
  };
};

export default useActiveRole;
