import { PASSWORD_PATTERN } from 'common/constants/regex-patterns';
import {
  MAX_VALID_LENGTH,
  MAX_VALID_LENGTH_ERR_MSG,
  MIN_VALID_LENGTH,
  MIN_VALID_LENGTH_ERR_MSG,
  MISSING_COUNTRY_ERR_MSG,
  MISSING_FIRST_NAME_ERR_MSG,
  MISSING_BIRTHDATE_ERR_MSG,
  MISSING_LAST_NAME_ERR_MSG,
  MISSING_CITY_ERR_MSG,
  MISSING_EMAIL_ERR_MSG,
  INVALID_EMAIL_FORMAT_ERR_MSG,
  MISSING_PASSWORD_ERR_MSG,
  INVALID_PASSWORD_FORMAT_ERR_MSG,
  MISSING_CONFIRMED_PASSWORD_ERR_MSG,
  PASSWORDS_NOT_MATCHING_ERR_MSG,
} from 'common/constants/validations';

const yup = require('yup');

export const firstName = yup
  .string()
  .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
  .max(MAX_VALID_LENGTH, MAX_VALID_LENGTH_ERR_MSG)
  .required(MISSING_FIRST_NAME_ERR_MSG);
export const lastName = yup
  .string()
  .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
  .max(MAX_VALID_LENGTH, MAX_VALID_LENGTH_ERR_MSG)
  .required(MISSING_LAST_NAME_ERR_MSG);
export const birthDate = yup.string().required(MISSING_BIRTHDATE_ERR_MSG);
export const country = yup.string().required(MISSING_COUNTRY_ERR_MSG);
export const city = yup
  .string()
  .min(MIN_VALID_LENGTH, MIN_VALID_LENGTH_ERR_MSG)
  .max(MAX_VALID_LENGTH, MAX_VALID_LENGTH_ERR_MSG)
  .required(MISSING_CITY_ERR_MSG);
export const email = yup
  .string()
  .email(INVALID_EMAIL_FORMAT_ERR_MSG)
  .required(MISSING_EMAIL_ERR_MSG);
export const password = yup
  .string()
  .matches(PASSWORD_PATTERN, INVALID_PASSWORD_FORMAT_ERR_MSG)
  .required(MISSING_PASSWORD_ERR_MSG);
export const confirmedPassword = yup
  .string()
  .required(MISSING_CONFIRMED_PASSWORD_ERR_MSG)
  .oneOf([yup.ref('password')], PASSWORDS_NOT_MATCHING_ERR_MSG);
