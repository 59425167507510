import PropTypes from 'prop-types';
import Image, { ImagePropTypes } from '../image';
import { formatImageWithWebp } from 'common/utils/formatting';
import './Banner.scss';

const className = 'c-Banner';

const Banner = ({ img }) => (
  <div className={className}>
    <Image {...formatImageWithWebp(img)} />
    <div className={`${className}__layer`}></div>
  </div>
);

Banner.propTypes = {
  img: PropTypes.shape(ImagePropTypes),
};

export default Banner;
