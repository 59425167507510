import { skillLevelStaticProps } from 'features/game-event-features/create-edit-game-event/constants';

export const GENERAL_SCREEN = 'General';
export const SKILLS_SCREEN = 'Skills';
export const POSITIONS_SCREEN = 'Positions';

export const NEXT_SCREEN_LABEL = 'Next';
export const SAVE_PROFILE_LABEL = 'Save';

export const SUCCESS_SUBMIT_MESSAGE = 'Successfully updated your profile!';
export const SUBMIT_ERROR_MESSAGE =
  'Error updating your profile. Please try again.';

// general
export const descriptionStaticProps = {
  inputLabel: 'About me',
  valueInlineLabel: 'Description',
  type: 'textarea',
  placeholder: 'Tell us about yourself...',
  optional: true,
};
export const interestedInStaticProps = {
  inputLabel: 'Interested in',
  optionProps: [
    {
      type: 'pickup games',
      description: 'Enjoys participating in casual pickup games.',
    },
    {
      type: 'leagues',
      description: 'Prefers playing in organized leagues and competitions.',
    },
    {
      type: 'all',
      description: 'Open to both pickup games and organized leagues.',
    },
  ],
};

export const experienceStaticProps = { ...skillLevelStaticProps };

export const footStaticProps = {
  inputLabel: 'Foot',
  optionProps: [
    {
      type: 'left',
      description: 'Predominantly uses the left foot.',
    },
    {
      type: 'right',
      description: 'Predominantly uses the right foot.',
    },
    {
      type: 'both with left dominant',
      description: 'Able to use both feet with a dominant left foot.',
    },
    {
      type: 'both with right dominant',
      description: 'Able to use both feet with a dominant right foot.',
    },
  ],
};

export const personalityStaticProps = {
  inputLabel: 'Personality',
  optionProps: [
    {
      type: 'Perfecionist',
      description: 'Strives for perfection in everything they do.',
    },
    {
      type: 'Professional',
      description: 'Approaches the game with a high level of professionalism.',
    },
    {
      type: 'Determined',
      description: 'Shows strong determination and focus on achieving goals.',
    },
    {
      type: 'Resilient',
      description:
        'Demonstrates resilience and the ability to bounce back from setbacks.',
    },
    {
      type: 'Leader',
      description: 'Natural leader both on and off the field.',
    },
    {
      type: 'Ambitious',
      description: 'Driven by ambition to succeed and reach new heights.',
    },
    {
      type: 'Mercenary',
      description: 'Plays with a self-centered focus, seeking personal gains.',
    },
    {
      type: 'Temperamental',
      description:
        'Exhibits mood swings and emotional fluctuations during games.',
    },
    {
      type: 'Chilled',
      description: 'Maintains a calm and composed demeanor during matches.',
    },
    {
      type: 'Sporting',
      description: 'Displays a sportsmanlike attitude and fair play.',
    },
    {
      type: 'Balanced',
      description: 'Maintains a balanced and steady approach to the game.',
    },
    {
      type: 'Reserved',
      description: 'Quiet and reserved, not openly expressive during games.',
    },
    {
      type: 'Competitive',
      description: 'Fiercely competitive and always strives to win.',
    },
  ],
};

// skills
export const TECHNICAL_SKILL_TYPES = [
  'crossing',
  'dribbling',
  'finishing',
  'first touch',
  'heading',
  'long shots',
  'passing',
  'marking',
  'tackling',
  'technique',
];
export const MENTAL_SKILL_TYPES = [
  'aggression',
  'anticipation',
  'bravery',
  'composure',
  'concentration',
  'decisions',
  'determination',
  'leadership',
  'off the ball',
  'positioning',
  'teamwork',
  'creativity',
];
export const PHYSICAL_SKILL_TYPES = [
  'acceleration',
  'agility',
  'balance',
  'pace',
  'stamina',
  'strength',
  'jumping',
  'natural fitness',
];

export const SKILL_LEVEL_STRONG = 'strong';
export const SKILL_LEVEL_GOOD = 'good';

export const skillLevelParams = {
  strong: {
    type: SKILL_LEVEL_STRONG,
    cost: 2,
  },
  good: {
    type: SKILL_LEVEL_GOOD,
    cost: 1,
  },
};

// positions
export const FIELD_SIDE_POSITION_TYPES = ['left', 'right', 'center'];
export const GK_POSITIONS = ['Goalkeeper'];
export const DEFENSE_POSITIONS = [
  'Central Defender',
  'Ball Playing Defender',
  'Full Back',
  'Wing Back',
];
export const MIDFIELD_POSITIONS = [
  'Defensive Midfielder',
  'Deep Lying Playmaker',
  'Ball Winning Midfielder',
  'Roaming Playmaker',
  'Central Midfielder',
  'Box To Box Midfielder',
  'Advanced Playmaker',
  'Wide Midfielder',
  'Winger',
  'Wide Playmaker',
  'Attacking Midfielder',
];
export const FORWARD_POSITIONS = [
  'Inside Forward',
  'Trequartista',
  'Deep Lying Forward',
  'Advanced Forward',
  'Target Man',
  'Poacher',
  'Complete Forward',
  'False Nine',
];
export const POSITION_TABS = ['gk', 'defense', 'midfield', 'offense'];

export const positionsMap = {
  gk: GK_POSITIONS,
  defense: DEFENSE_POSITIONS,
  midfield: MIDFIELD_POSITIONS,
  offense: FORWARD_POSITIONS,
};

export const POSITION_AREA_TYPES = {
  gk: 'goalkeeper',
  defense: 'defender',
  midfield: 'midfielder',
  offense: 'forward',
};

export const POSITION_AREA_LABELS = {
  goalkeeper: 'gk',
  defender: 'defense',
  midfielder: 'midfield',
  forward: 'offense',
};
