import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ClubLogo, {
  ClubLogoPropTypes,
} from 'common/components/club-logo/ClubLogo';
import Image from '../image';
import { DEFAULT_CLUB_LOGO } from 'common/constants/common-props';
import { formatImage } from 'common/utils/formatting';

// NOTE showing only image until club-logo v4

const SmartLogo = ({ clubLogo }) => {
  const isClubLogo = useMemo(
    () => clubLogo?.shape && clubLogo?.symbol && clubLogo?.color,
    [clubLogo?.color, clubLogo?.shape, clubLogo?.symbol]
  );

  const isImg = typeof clubLogo === 'string';

  const renderLogo = useCallback(
    () =>
      isClubLogo ? (
        <ClubLogo {...clubLogo} />
      ) : (
        <Image {...DEFAULT_CLUB_LOGO} />
      ),
    [clubLogo, isClubLogo]
  );

  // NOTE rendering only team image until club logo v4
  if (isImg) return <Image {...formatImage(clubLogo)} />;
  return <>{renderLogo()}</>;
};

SmartLogo.propTypes = {
  clubLogo: PropTypes.shape(ClubLogoPropTypes),
};

export default SmartLogo;
