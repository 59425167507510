import { useState, useEffect, useMemo, useCallback } from 'react';
import { useApi } from 'common/hooks';
import { useLocation } from 'react-router-dom';
import { splitPath } from 'common/utils/routing';
import { getUserV2 } from 'common/api/userService';
import { useEventsContext } from 'contexts/events';
import {
  USER_IMAGE_UPLOADED_EVENT,
  USER_PLAYER_CARD_UPDATED_EVENT,
} from 'contexts/events/constants';
import {
  PROFILE_USE_USER_IMAGE_SUBSCRIPTION_ID,
  PROFILE_USE_USER_PLAYER_CARD_SUBSCRIPTION_ID,
} from '../constants';

const useUser = () => {
  const location = useLocation();
  const { subscribe, unsubscribe } = useEventsContext();
  const { asyncWrapper } = useApi();

  const [updatedImage, setUpdatedImage] = useState(null);

  const userId = useMemo(
    () => splitPath(location?.pathname)?.[2],
    [location?.pathname]
  );

  const [userProfile, setUserProfile] = useState(null);

  const loadUserProfile = useCallback(() => {
    asyncWrapper(async () => {
      await getUserV2(userId, response => {
        if (typeof response?.data === 'object') {
          setUserProfile(response?.data);
        }
      });
    });
  }, [asyncWrapper, userId]);

  const updateProfileImage = useCallback(
    image => {
      setUserProfile(curr => ({
        ...userProfile,
        image: { url: image, history: [...curr.image.history, image] },
      }));
    },
    [userProfile]
  );

  useEffect(() => {
    loadUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    subscribe(USER_IMAGE_UPLOADED_EVENT, {
      cb: image => {
        setUpdatedImage(image);
      },
      id: PROFILE_USE_USER_IMAGE_SUBSCRIPTION_ID,
    });

    subscribe(USER_PLAYER_CARD_UPDATED_EVENT, {
      cb: user => {
        setUserProfile(user);
      },
      id: PROFILE_USE_USER_PLAYER_CARD_SUBSCRIPTION_ID,
    });

    return () => {
      unsubscribe(
        USER_IMAGE_UPLOADED_EVENT,
        PROFILE_USE_USER_IMAGE_SUBSCRIPTION_ID
      );
      unsubscribe(
        USER_PLAYER_CARD_UPDATED_EVENT,
        PROFILE_USE_USER_PLAYER_CARD_SUBSCRIPTION_ID
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updatedImage) {
      updateProfileImage(updatedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedImage]);

  return {
    userProfile,
    setUserProfile,
  };
};

export default useUser;
