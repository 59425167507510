import PropTypes from 'prop-types';
import style from './IconTemplate.module.scss';

const className = 'c-IconTemplate';

const IconTemplate = ({ icon, alt, children }) => (
  <div className={`${style.wrapper} ${className}`}>
    {icon && <img src={icon} alt={alt} />}
    {children}
  </div>
);

IconTemplate.propTypes = {
  icon: PropTypes.any,
  alt: PropTypes.string.isRequired,
};

export default IconTemplate;
