import { signaturePrefixes, userColors } from 'common/constants/users';
import {
  NICKNAME_SIGNATURE_TYPE,
  NUMBER_SIGNATURE_TYPE,
  NO_SIGNATURE,
} from 'pages/profile/constants';
import PropTypes from 'prop-types';
import './PlayerSignature.scss';

const className = 'c-PlayerSignature';

const PlayerSignature = ({ type, signature, color }) => {
  const signatureClassNameSufix =
    type === NUMBER_SIGNATURE_TYPE ? 'number' : 'nickname';

  const signatureColorClassName = `${className}__signature--${color}`;

  if (!type) return null;
  return (
    <div className={className}>
      <span
        className={`${className}__signature ${`${className}__signature__${signatureClassNameSufix}`} ${signatureColorClassName}`}
      >
        {signaturePrefixes[type]}
        {signature}
      </span>
    </div>
  );
};

export const PlayerSignaturePropTypes = {
  type: PropTypes.oneOf([
    NUMBER_SIGNATURE_TYPE,
    NICKNAME_SIGNATURE_TYPE,
    NO_SIGNATURE,
  ]),
  signature: PropTypes.string.isRequired,
  color: PropTypes.oneOf(userColors),
};

PlayerSignature.propTypes = PlayerSignaturePropTypes;

export default PlayerSignature;
