import PropTypes from 'prop-types';
import './GeneratedLogo.scss';

const className = 'c-GeneratedLogo';

const GeneratedLogo = ({ Symbol }) => {
  return (
    <div className={`${className}`}>
      <div className={`${className}__symbol`}>
        <Symbol />
      </div>
    </div>
  );
};

export const ClubLogoPropTypes = {
  symbol: PropTypes.any,
};

GeneratedLogo.propTypes = ClubLogoPropTypes;

export default GeneratedLogo;
