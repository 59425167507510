/* eslint-disable max-len */
import PropTypes from 'prop-types';

const AddToShortlistIcon = ({ color }) => (
  <svg
    enableBackground='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#fafafacc'
  >
    <rect fill='none' height='24' width='24' />
    <path
      stroke={color}
      d='M21,7h-2v2h-2V7h-2V5h2V3h2v2h2V7z M19,21l-7-3l-7,3V5c0-1.1,0.9-2,2-2l7,0c-0.63,0.84-1,1.87-1,3c0,2.76,2.24,5,5,5 c0.34,0,0.68-0.03,1-0.1V21z'
    />
  </svg>
);

AddToShortlistIcon.defaultProps = { color: '#111314' };
AddToShortlistIcon.propTypes = { color: PropTypes.string };

export default AddToShortlistIcon;
