import { useCallback } from 'react';
import {
  cancelTransferOfferService,
  endClubMembershipService,
  makeTransferOfferService,
  transferOfferResponseService,
} from 'common/api/transfersService';
import { useApi } from 'common/hooks';
import { useMetaContext } from 'contexts/meta';
import {
  CLUB_MEMBERSHIP_ENDED_SUCCESSFULLY_MESSAGE,
  TRANSFER_OFFER_CANCELED_MESSAGE,
  // TRANSFER_OFFER_RESPONSE_SENT_MESSAGE,
  // TRANSFER_OFFER_SENT_MESSAGE,
  TRANSFER_OFFER_SENT_SUCCESSFULLY_MESSAGE,
  TRANSFER_OFFER_SUCCESSFULLY_ANSWERED_MESSAGES,
} from '../constants';
import { useUserSessionContext } from 'contexts/user-session';
import { useEventsContext } from 'contexts/events';
import {
  CLUB_ACCEPTED_TRANSFER_OFFER_EVENT,
  CLUB_CANCELED_TRANSFER_OFFER_EVENT,
  CLUB_ENDED_CLUB_MEMBERSHIP_EVENT,
  CLUB_MADE_TRANSFER_OFFER_EVENT,
  OFFER_RESOLVED_EVENT,
  PLAYER_ACCEPTED_TRANSFER_OFFER_EVENT,
  PLAYER_CANCELED_TRANSFER_OFFER_EVENT,
  PLAYER_ENDED_CLUB_MEMBERSHIP_EVENT,
  PLAYER_MADE_TRANSFER_OFFER_EVENT,
} from 'contexts/events/constants';
import {
  GM_USER_ROLE,
  PLAYER_USER_ROLE,
} from 'contexts/user-session/hooks/constants';

const useTransfers = () => {
  const { setSuccessToastMessage } = useMetaContext();
  const { apiWrapper } = useApi();
  const { refreshRoleData, activeRole } = useUserSessionContext();
  const { emit } = useEventsContext();

  // don't use on the Street
  const filterAvailablePlayers = useCallback(() => {}, []);
  const filterAvailableClubs = useCallback(() => {}, []);

  const makeTransferOffer = useCallback(
    async data => {
      const response = await apiWrapper(
        () => makeTransferOfferService(data),
        null,
        null,
        true
      );

      if (response?.data) {
        const responseData = response?.data;
        const offerId = responseData._id;
        const playerId = responseData?.player;
        const clubId = responseData?.club;
        const status = responseData?.status;
        const playerData = responseData?.playerData;
        const clubData = responseData?.clubData;

        setSuccessToastMessage(TRANSFER_OFFER_SENT_SUCCESSFULLY_MESSAGE);
        const eventToEmit =
          activeRole?.type === PLAYER_USER_ROLE
            ? PLAYER_MADE_TRANSFER_OFFER_EVENT
            : activeRole?.type === GM_USER_ROLE
            ? CLUB_MADE_TRANSFER_OFFER_EVENT
            : null;
        if (eventToEmit) {
          const eventData = {
            offerId,
            playerId,
            clubId,
            status,
            playerData,
            clubData,
          };
          emit(eventToEmit, eventData);
        }
      }

      refreshRoleData();

      // TODO add neutral toast msg if error
    },
    [
      activeRole?.type,
      apiWrapper,
      emit,
      refreshRoleData,
      setSuccessToastMessage,
    ]
  );

  const respondToTransferOffer = useCallback(
    async (id, data) => {
      const response = await apiWrapper(
        () => transferOfferResponseService(id, data),
        null,
        null,
        true
      );

      if (response?.data) {
        const responseData = response?.data;
        setSuccessToastMessage(TRANSFER_OFFER_SUCCESSFULLY_ANSWERED_MESSAGES);
        emit(OFFER_RESOLVED_EVENT, id);
        emit(CLUB_ACCEPTED_TRANSFER_OFFER_EVENT, {
          transferId: responseData?._id,
          clubId: responseData?.club,
          playerId: responseData?.player,
          status: responseData?.status,
          clubData: responseData?.clubData,
          playerData: responseData?.playerData,
        });
        emit(PLAYER_ACCEPTED_TRANSFER_OFFER_EVENT, {
          transferId: responseData?._id,
          clubId: responseData?.club,
          playerId: responseData?.player,
          status: responseData?.status,
          clubData: responseData?.clubData,
          playerData: responseData?.playerData,
        });
      }

      refreshRoleData();
    },
    [apiWrapper, emit, refreshRoleData, setSuccessToastMessage]
  );

  const cancelTransferOffer = useCallback(
    async id => {
      const response = await apiWrapper(
        () => cancelTransferOfferService(id),
        null,
        null,
        true
      );

      const responseData = response?.data;
      const eventData = {
        clubId: responseData?.club,
        playerId: responseData?.player,
        status: responseData?.status,
        transferOfferId: responseData?._id,
      };

      if (response.status === 200) {
        setSuccessToastMessage(TRANSFER_OFFER_CANCELED_MESSAGE);
        const eventToEmit =
          activeRole?.type === PLAYER_USER_ROLE
            ? PLAYER_CANCELED_TRANSFER_OFFER_EVENT
            : activeRole?.type === GM_USER_ROLE
            ? CLUB_CANCELED_TRANSFER_OFFER_EVENT
            : null;
        if (eventToEmit) emit(eventToEmit, eventData);
      }

      refreshRoleData();
    },
    [
      activeRole?.type,
      apiWrapper,
      emit,
      refreshRoleData,
      setSuccessToastMessage,
    ]
  );

  const endClubMembership = useCallback(
    async (clubId, playerId) => {
      const response = await apiWrapper(
        () => endClubMembershipService({ club: clubId, player: playerId }),
        null,
        null,
        true
      );

      if (response?.data) {
        setSuccessToastMessage(CLUB_MEMBERSHIP_ENDED_SUCCESSFULLY_MESSAGE);
        const responseData = response?.data;
        const eventData = {
          clubId: responseData?.club,
          playerId: responseData?.player,
          clubMembershipId: responseData?._id,
        };

        const eventToEmit =
          activeRole?.type === PLAYER_USER_ROLE
            ? PLAYER_ENDED_CLUB_MEMBERSHIP_EVENT
            : activeRole?.type === GM_USER_ROLE
            ? CLUB_ENDED_CLUB_MEMBERSHIP_EVENT
            : null;
        if (eventToEmit) emit(eventToEmit, eventData);
      }

      refreshRoleData();
    },
    [
      activeRole?.type,
      apiWrapper,
      emit,
      refreshRoleData,
      setSuccessToastMessage,
    ]
  );

  return {
    makeTransferOffer,
    filterAvailablePlayers,
    filterAvailableClubs,
    respondToTransferOffer,
    cancelTransferOffer,
    endClubMembership,
  };
};

export default useTransfers;
