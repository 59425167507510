import { Children, useCallback } from 'react';
import PropTypes from 'prop-types';
import './InfoCard.scss';

const className = 'c-InfoCard';

const InfoCard = ({ items }) => {
  const lastItem = items.length - 1;
  const renderSingleItem = useCallback(
    ({ label, value }, i) => {
      const lastItemClassName =
        i === lastItem ? `${className}__item--last-item` : '';

      return (
        <li className={`${className}__item ${lastItemClassName}`}>
          <span className={`${className}__label`}>{label} </span>
          <span className={`${className}__value`}>{value}</span>
        </li>
      );
    },
    [lastItem]
  );

  const renderItems = useCallback(
    () => Children.toArray(items.map(renderSingleItem)),
    [items, renderSingleItem]
  );

  return (
    <div>
      <ul>{renderItems()}</ul>
    </div>
  );
};

InfoCard.defaultProps = {
  items: [],
};

const ItemPropTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
InfoCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes)),
};

export default InfoCard;
