import PropTypes from 'prop-types';
import { useProps } from './hooks';
import FeatureActionButton from 'common/components/feature-action-button';

const TransferButton = ({
  clubId,
  playerId,
  activeMembership,
  pendingTransferOffer,
}) => {
  const { renderProps } = useProps({
    clubId,
    playerId,
    activeMembership,
    pendingTransferOffer,
  });

  return <FeatureActionButton {...renderProps} />;
};

TransferButton.propTypes = {
  clubId: PropTypes.string.isRequired,
  playerId: PropTypes.string.isRequired,
  activeMembership: PropTypes.bool,
  pendingTransferOfferId: PropTypes.string,
};

export default TransferButton;
