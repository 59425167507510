import { useNavigation } from 'common/hooks';
import {
  CLUB_STREET_ITEM_TYPE,
  PLAYER_STREET_ITEM_TYPE,
} from 'pages/street/constants';
import { useCallback } from 'react';

const useOnCardClickHandlers = onMatchCardClick => {
  const { navigateToClubPage, navigateToUserPage } = useNavigation();

  const goToHandler = useCallback(
    (type, id) => {
      if (type === CLUB_STREET_ITEM_TYPE) {
        navigateToClubPage(id);
      } else if (type === PLAYER_STREET_ITEM_TYPE) {
        navigateToUserPage(id);
      }
    },
    [navigateToClubPage, navigateToUserPage]
  );

  const getOnClickHandler = useCallback(
    (type, _id, matchData) =>
      matchData
        ? () => onMatchCardClick(matchData)
        : () => {
            goToHandler(type, _id);
          },
    [goToHandler, onMatchCardClick]
  );

  const passOnClickToCards = useCallback(
    rowData => {
      const formattedCards = rowData?.cards?.map(propsData => {
        const onClick = getOnClickHandler(
          propsData?.props?.type,
          propsData?.props?._id,
          propsData?.props?.matchData
        );

        return {
          Component: propsData?.Component,
          props: { ...propsData?.props, onClick },
        };
      });

      return { ...rowData, cards: formattedCards };
    },
    [getOnClickHandler]
  );

  return {
    passOnClickToCards,
  };
};

export default useOnCardClickHandlers;
