import {
  BLUE_NEON_COLOR,
  GREEN_NEON_COLOR,
  VIOLET_NEON_COLOR,
  YELLOW_NEON_COLOR,
} from 'common/constants/general';
import AlienIcon from './components/icons/alien-icon';
import ColonyIcon from './components/icons/colony-icon';
import CraterIcon from './components/icons/crater-icon';
import ExplorersIcon from './components/icons/explorers-icon';
import PyramidIcon from './components/icons/pyramid-icon';
import SphereIcon from './components/icons/sphere-icon';
import StarshipIcon from './components/icons/starship-icon';
import TesseractIcon from './components/icons/tesseract-icon';

// string literals
export const CONFIRM_LOGO_TITLE = 'Are you sure this is the logo you want?';
export const CONFIRM_LOGO_BUTTON_LABEL = 'mint it 🚀';
export const CANCEL_LOGO_PREVIEW_BUTTON_LABEL = 'let me change it';

export const CLUB_LOGO_MINTED_MESSAGE = 'Team logo minted!';

export const SELECTOR_FORM_SCREEN = 'SELECTOR_FORM_SCREEN';
export const LOGO_PREVIEW_SCREEN = 'LOGO_PREVIEW_SCREEN';

// selector form

// -- selector types
export const SHAPE_SELECTOR_TYPE = 'SHAPE_SELECTOR_TYPE';
export const SYMBOL_SELECTOR_TYPE = 'SYMBOL_SELECTOR_TYPE';
export const PRIMARY_COLOR_SELECTOR_TYPE = 'PRIMARY_COLOR_SELECTOR_TYPE';

export const SHAPE_SELECTOR_LABEL = 'shape';
export const SYMBOL_SELECTOR_LABEL = 'symbol';
export const PRIMARY_COLOR_SELECTOR_LABEL = 'color';

// -- shapes
export const SPHERE_LOGO_SHAPE = 'sphere';
export const PYRAMID_LOGO_SHAPE = 'pyramid';
export const TESSERACT_LOGO_SHAPE = 'tesseract';
export const CRATER_LOGO_SHAPE = 'crater';

// -- symbols
export const ALIEN_LOGO_SYMBOL = 'alien';
export const EXPLORERS_LOGO_SYMBOL = 'explorers';
export const COLONY_LOGO_SYMBOL = 'colony';
export const STARSHIP_LOGO_SYMBOL = 'starship';

// -- main logo colors
export const YELLOW_CLUB_NEON_COLOR = YELLOW_NEON_COLOR;
export const BLUE_CLUB_NEON_COLOR = BLUE_NEON_COLOR;
export const GREEN_CLUB_NEON_COLOR = GREEN_NEON_COLOR;
export const VIOLET_CLUB_NEON_COLOR = VIOLET_NEON_COLOR;

// -- form views
export const CATEGORY_SELECTOR = 'CATEGORY_SELECTOR';
export const OPTION_SELECTOR = 'OPTION_SELECTOR';

// -- icon matchings
export const shapeIconsMatching = {
  [SPHERE_LOGO_SHAPE]: SphereIcon,
  [PYRAMID_LOGO_SHAPE]: PyramidIcon,
  [TESSERACT_LOGO_SHAPE]: TesseractIcon,
  [CRATER_LOGO_SHAPE]: CraterIcon,
};
export const symbolIconsMatching = {
  [ALIEN_LOGO_SYMBOL]: AlienIcon,
  [COLONY_LOGO_SYMBOL]: ColonyIcon,
  [EXPLORERS_LOGO_SYMBOL]: ExplorersIcon,
  [STARSHIP_LOGO_SYMBOL]: StarshipIcon,
};

export const CLUB_COLORS = [
  YELLOW_CLUB_NEON_COLOR,
  BLUE_CLUB_NEON_COLOR,
  GREEN_CLUB_NEON_COLOR,
  VIOLET_CLUB_NEON_COLOR,
];
