import IconWithLabelCTA from 'common/components/icon-with-label-cta';
import ArrowDropdownIcon from 'common/components/icons/arrow-drop-down-icon';
import Text, { TextPropTypes } from 'common/components/text';
import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './HoverView.module.scss';

const HoverView = ({
  dynamicClassNameSufix,
  actionButtons,
  onViewMore,
  showOnViewMoreButton,
  info,
}) => {
  const renderActionButtons = useCallback(
    () =>
      Children.toArray(
        actionButtons?.map(
          ({ StandaloneButton, standaloneButtonProps, ...otherParams }) => (
            <div className={style.actionButtonWrapper}>
              {StandaloneButton ? (
                <StandaloneButton {...standaloneButtonProps} />
              ) : (
                <IconWithLabelCTA {...otherParams} />
              )}
            </div>
          )
        )
      ),
    [actionButtons]
  );

  return (
    <div
      className={`${style.wrapper} ${
        style[`wrapper--${dynamicClassNameSufix}`]
      }`}
    >
      <div className={style.actionsWrapper}>
        {/* Action buttons row */}
        <div className={style.customActionButtons}>{renderActionButtons()}</div>
        {showOnViewMoreButton && (
          <div className={style.viewMoreActionButton}>
            <IconWithLabelCTA
              handler={onViewMore}
              Icon={ArrowDropdownIcon}
              fill='#000000'
            />
          </div>
        )}
      </div>

      {info && (
        <div className={style.info}>
          <Text {...info} />
        </div>
      )}
    </div>
  );
};

export const HoverViewProps = {
  dynamicClassNameSufix: PropTypes.string,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      StandaloneButton: PropTypes.any,
      standaloneButtonProps: PropTypes.object,
      Icon: PropTypes.any,
      handler: PropTypes.func.isRequired,
    })
  ),
  onViewMore: PropTypes.func.isRequired,
  info: PropTypes.shape(TextPropTypes),
  showOnViewMoreButton: PropTypes.bool,
};

HoverView.propTypes = HoverViewProps;

export default HoverView;
