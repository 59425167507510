import { ClubLogoPropTypes } from 'common/components/club-logo/ClubLogo';
import { TextPropTypes } from 'common/components/text';
import { AWAY_TEAM_WIN, DRAW, HOME_TEAM_WIN } from 'features/match/constants';
import PropTypes from 'prop-types';
import TrianglesCard from '../triangles-card';

const FrontFaceViewLogosVariant = ({ infoBottom, matchMeta, onClick }) => (
  <div className={`c-FrontFaceView`} onClick={onClick}>
    <TrianglesCard infoBottom={infoBottom} matchMeta={matchMeta} />
  </div>
);

FrontFaceViewLogosVariant.propTypes = {
  onClick: PropTypes.func.isRequired,
  infoBottom: PropTypes.shape({
    doubleVariantData: PropTypes.arrayOf(
      PropTypes.shape({
        leftSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
        rightSideData: {
          infoOne: PropTypes.shape(TextPropTypes),
          infoTwo: PropTypes.shape(TextPropTypes),
          infoLogo: PropTypes.shape(ClubLogoPropTypes),
        },
      })
    ),
  }),
  matchMeta: PropTypes.shape({
    date: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    outcome: PropTypes.oneOf([AWAY_TEAM_WIN, DRAW, HOME_TEAM_WIN]),
  }),
};

export default FrontFaceViewLogosVariant;
