import { formatImage, formatImageWithWebp } from 'common/utils/formatting';
import { useCallback } from 'react';
import Image from '../image';
import PropTypes from 'prop-types';
import './EventPreviewCard.scss';

const className = 'c-EventPreviewCard';

const EventPreviewCard = ({
  eventBadge,
  coverImgData,
  eventType,
  mainInformation,
  subInformation,
  bg,
  onClick,
}) => {
  const renderBanner = useCallback(() => {
    const { Icon } = eventBadge;
    const formattedImage = coverImgData
      ? formatImageWithWebp(coverImgData)
      : formatImage(bg);

    return (
      <div className={`${className}__banner`}>
        <div className={`${className}__banner__bg`}>
          <div className={`${className}__banner__bg__overlay`}></div>
          <Image {...formattedImage} />
        </div>
        <div className={`${className}__badges`}>
          {eventType && (
            <p className={`${className}__badges__type`}>{eventType}</p>
          )}
          <div className={`${className}__badges__item`}>
            {Icon && <Icon />}
            <p>
              <span>skill level: </span>
              {eventBadge?.label || 'n/a'}
            </p>
          </div>
        </div>
        <div className={`${className}__banner__information`}>
          <p>{mainInformation?.above}</p>
          <div className={`${className}__banner__information__central`}>
            <h3>{mainInformation?.central}</h3>
          </div>
          <div className={`${className}__banner__information__beneath`}>
            <p>{mainInformation?.beneath}</p>
            <div className={`${className}__banner__host-img`}>
              <Image {...formatImage(mainInformation.beneathImg)} />
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    eventBadge,
    coverImgData,
    bg,
    eventType,
    mainInformation?.above,
    mainInformation?.central,
    mainInformation?.beneath,
    mainInformation.beneathImg,
  ]);

  const renderSubInformation = useCallback(() => {
    const itemsToRender = subInformation?.map(({ text, imgURL }, i) => (
      <div className={`${className}__subInformation__item`}>
        {imgURL && (
          <div className={`${className}__subInformation__item__img`}>
            <Image {...formatImage(imgURL)} />
          </div>
        )}
        <p>{text}</p>
      </div>
    ));

    return <div className={`${className}__category`}>{itemsToRender}</div>;
  }, [subInformation]);

  return (
    <div className={className} onClick={onClick}>
      <div className={`${className}__inner-wrapper`}>
        {renderBanner()}

        {renderSubInformation()}
      </div>
    </div>
  );
};

EventPreviewCard.defaultProps = {
  subInformation: [],
  otherInformation: [],
};

EventPreviewCard.propTypes = {
  eventBadge: PropTypes.any,
  coverImgData: PropTypes.any,
  eventType: PropTypes.any,
  mainInformation: PropTypes.any,
  subInformation: PropTypes.any,
  otherInformation: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

export default EventPreviewCard;
