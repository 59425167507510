import { COUNTRIES } from 'common/constants/geoData';
import {
  REACT_GA_INFO_TAB_SELECT_CLUB_PAGE_EVENT,
  REACT_GA_MATCHES_TAB_SELECT_CLUB_PAGE_EVENT,
  REACT_GA_ROSTER_TAB_SELECT_CLUB_PAGE_EVENT,
} from 'common/constants/react-google-analytics/events';
import { formatCountryOptions } from 'common/utils/formatting';
import ReactGA from 'react-ga';

export const INFO_HISTORY_NAV_ITEM = 'Info';
export const ROSTER_NAV_ITEM = 'Roster';
export const MATCHES_NAV_ITEM = 'Games';

export const CLUB_STATUS_SETTINGS = 'Status';
export const DELETE_CLUB_SETTINGS = 'Delete';

export const settingsModalTypes = [CLUB_STATUS_SETTINGS, DELETE_CLUB_SETTINGS];

export const ASIDE_NAV_ITEMS = [
  {
    item: INFO_HISTORY_NAV_ITEM,
    cb: () => {
      ReactGA.event(REACT_GA_INFO_TAB_SELECT_CLUB_PAGE_EVENT);
    },
  },
  {
    item: ROSTER_NAV_ITEM,
    cb: () => {
      ReactGA.event(REACT_GA_ROSTER_TAB_SELECT_CLUB_PAGE_EVENT);
    },
  },
  {
    item: MATCHES_NAV_ITEM,
    cb: () => {
      ReactGA.event(REACT_GA_MATCHES_TAB_SELECT_CLUB_PAGE_EVENT);
    },
  },
];

export const infoSectionFields = [
  {
    key: 'createdAt',
    label: 'founded',
  },
  {
    key: 'createdBy',
    label: 'founder',
  },
  {
    key: 'status',
    label: 'status',
  },
  {
    key: 'players',
    label: 'total players',
  },
  {
    key: 'matches',
    label: 'total matches',
  },
  {
    key: 'winRatio',
    label: 'win ratio',
  },
];

export const CLUB_STATUS_UPDATE_CONFIRM_MESSAGE = [
  { text: 'Are you sure you want to change team status?' },
];
export const DELETE_CLUB_CONFIRM_MESSAGE = [
  { text: 'Are you sure you want to delete your team?', isNewLine: true },
];

export const modals = {
  CLUB_BIO: 'CLUB_BIO_MODAL',
  MATCH_MODAL: 'MATCH_MODAL',
};

export const BIO_FORM_KEYS = ['name', 'city', 'country'];

export const bioFormFields = [
  { key: 'name', label: 'name' },
  { key: 'city', label: 'city' },
  {
    key: 'country',
    label: 'country',
    type: 'select',
    options: formatCountryOptions(COUNTRIES),
  },
];

export const noClubFoundText = [{ text: 'Team not found.' }];

export const editBioModalTitle = [{ text: 'Edit Bio' }];

// Success messages
export const CLUB_SUCCESSFULLY_DELETED_MESSAGE = 'Team deleted.';
export const CLUB_MODE_TURNED_INTO_INACTIVE_MESSAGE = 'Team deactivated.';
export const CLUB_MODE_TURNED_INTO_ACTIVE_MESSAGE = 'Team activated';
export const CLUB_BIO_UPDATED_MESSAGE = 'Team updated.';

export const ACTIVATE_CLUB_BUTTON_LABEL = 'Activate';
export const DEACTIVATE_CLUB_BUTTON_LABEL = 'Deactivate';

export const ADD_TEAM_PROFILE_IMAGE_TOOLTIP = 'add team image/logo';
