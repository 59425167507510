/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import Modal from 'features/modal';
import { CENTER_MODAL_VARIANT, DARK_THEME } from 'features/modal/constants';
import style from './ImageUploadForm.module.scss';
import Button from 'common/components/button';
import Text from 'common/components/text';

const ImageUploadForm = ({
  isMobileView,
  selectedFile,
  uploadButtonDisabled,
  fileSource,
  onFileChange,
  onFileUpload,
  onClose,
  invalidFileErrorMessage,
  reactGAModalView,
}) => (
  <Modal
    fullScreen={isMobileView}
    variant={CENTER_MODAL_VARIANT}
    theme={DARK_THEME}
    onClose={onClose}
    reactGAModalView={reactGAModalView}
  >
    <div className={style.wrapper}>
      <input type='file' onChange={onFileChange} accept='.jpg, .jpeg, .png' />

      {selectedFile ? (
        <div className={style.filePreview}>
          {fileSource && <img src={fileSource} />}
          <Button isDisabled={uploadButtonDisabled} onClick={onFileUpload}>
            Upload
          </Button>
        </div>
      ) : (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      )}

      {invalidFileErrorMessage && (
        <Text data={[{ text: invalidFileErrorMessage }]} />
      )}
    </div>
  </Modal>
);

ImageUploadForm.propTypes = {
  isMobileView: PropTypes.bool,
  fileSource: PropTypes.any,
  selectedFile: PropTypes.object,
  uploadButtonDisabled: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  invalidFileErrorMessage: PropTypes.string,
  reactGAModalView: PropTypes.string,
};

export default ImageUploadForm;
