// categories
const RGA_USER_CATEGORY = 'User';
const RGA_APP_ACTION_CATEGORY = 'App Action';
const RGA_SOCIAL_CATEGORY = 'Social';
const RGA_SHARING_CATEGORY = 'Sharing';

// event props

// -- Landing Page
export const REACT_GA_LOGIN_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Login',
};
export const REACT_GA_VALIDATE_ACCOUNT_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Validate Account',
};
export const REACT_GA_FORGOTTEN_PASSWORD_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Forgotten Password',
};
export const REACT_GA_SIGN_UP_FROM_BANNER_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Banner Sign Up Click',
};
export const REACT_GA_SIGN_UP_FROM_CTA_SECTION_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Bottom CTA Sign Up Click',
};
export const REACT_GA_IG_LINK = {
  category: RGA_SOCIAL_CATEGORY,
  action: 'Instagram Link',
};
export const REACT_GA_TW_LINK = {
  category: RGA_SOCIAL_CATEGORY,
  action: 'Twitter Link',
};
export const REACT_GA_LI_LINK = {
  category: RGA_SOCIAL_CATEGORY,
  action: 'LinkedIn Link',
};

// -- Register Page
export const REACT_GA_SUBMIT_SIGNUP_FORM_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Filled Sign Up Form',
};
export const REACT_GA_CONFIRM_SIGNUP_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Confirmed Sign Up Form',
};

// -- Navbar
export const REACT_GA_CREATE_TEAM_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Create Team',
};
export const REACT_GA_TOGGLE_OFFERS_ASIDE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Offers Aside toggled',
};
export const REACT_GA_OFFERS_CLEARED_NON_USER_EVENT = {
  category: RGA_APP_ACTION_CATEGORY,
  action: 'Offer cleared',
};
export const REACT_GA_TOGGLE_NOTIFICATIONS_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Notifications Dropdown toggled',
};
// export const REACT_GA_NOTIFICATIONS_CLEARED_NON_USER_EVENT = {
//   category: RGA_APP_ACTION_CATEGORY,
//   action: 'Notifications cleared',
// };
export const REACT_GA_SEARCH_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Search',
};
export const REACT_GA_ACTIVE_PLAYER_PROFILE_GO_TO_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Active Player Profile Go To Page',
};
export const REACT_GA_ACTIVE_CLUB_PROFILE_GO_TO_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Active Club Profile Go To Page',
};
export const REACT_GA_SELECT_PLAYER_PROFILE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Player Profile',
};
export const REACT_GA_SELECT_TEAM_PROFILE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Team Profile',
};

// -- Street Page
// -- -- Catalogue
export const REACT_GA_SELECT_GAMES_SCREEN_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Games Screen',
};
export const REACT_GA_SELECT_PLAYERS_SCREEN_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Players Screen',
};
export const REACT_GA_SELECT_TEAMS_SCREEN_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Teams Screen',
};
export const REACT_GA_BACK_TO_STREET_CATALOGUE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Go Back To Street Catalogue',
};

// -- -- Games

// -- -- Players

// -- -- Teams

// -- Settings Page
export const REACT_GA_CHANGE_PASSWORD_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Change Password',
};
export const REACT_GA_DELETE_PROFILE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Delete Profile',
};

// -- Profile Page
export const REACT_GA_UPLOAD_PROFILE_PICTURE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Upload Profile Image',
};
export const REACT_GA_BIO_TAB_SELECT_PROFILE_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select User Bio Tab',
};
export const REACT_GA_BIO_TAB_SELECT_GAME_EVENTS_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: `Select User's Game Event Subscriptions`,
};
export const REACT_GA_CLUBS_TAB_SELECT_PROFILE_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Teams Tab on Profile Page',
};
export const REACT_GA_MATCHES_TAB_SELECT_PROFILE_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Games Tab on Profile Page',
};
export const REACT_GA_SELECT_CALL_SIGN_SIGNATURE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Call Sign Signature',
};
export const REACT_GA_SELECT_NUMBER_SIGNATURE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Number Signature',
};
export const REACT_GA_SELECT_NO_SIGNATURE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select No Signature',
};
export const REACT_GA_REBRAND_SIGNATURE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Rebrand Signature',
};

// -- Club Page
export const REACT_GA_INFO_TAB_SELECT_CLUB_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Club Info Tab',
};
export const REACT_GA_ROSTER_TAB_SELECT_CLUB_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Roster Tab on Club Page',
};
export const REACT_GA_MATCHES_TAB_SELECT_CLUB_PAGE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Select Games Tab on Club Page',
};
export const REACT_GA_DEACTIVATE_CLUB_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Deactivate Club',
};
export const REACT_GA_DELETE_CLUB_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Delete Club',
};

// -- Match Modal

// NOTE if needed make it specific for location share event
export const REACT_GA_FORM_FIELD_VALUE_SHARE_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Share Form Field Value',
};

// -- -- Scheduled Game
export const REACT_GA_CALENDAR_MATCH_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Add Game To Calendar',
};
export const REACT_GA_CLIPBOARD_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Clipboard Share',
};
export const REACT_GA_EMAIL_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Email Share',
};
export const REACT_GA_FB_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Facebook Share',
};
export const REACT_GA_LI_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game LinkedIn Share',
};
export const REACT_GA_TG_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Telegram Share',
};
export const REACT_GA_TW_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Twitter Share',
};
export const REACT_GA_VIBER_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game Viber Share',
};
export const REACT_GA_WA_SHARE_SCHEDULED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Scheduled Game WhatsApp Share',
};

// -- -- Played Game
export const REACT_GA_CLIPBOARD_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Clipboard Share',
};
export const REACT_GA_EMAIL_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Email Share',
};
export const REACT_GA_FB_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Facebook Share',
};
export const REACT_GA_LI_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game LinkedIn Share',
};
export const REACT_GA_TG_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Telegram Share',
};
export const REACT_GA_TW_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Twitter Share',
};
export const REACT_GA_VIBER_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game Viber Share',
};
export const REACT_GA_WA_SHARE_PLAYED_MATCH_EVENT = {
  category: RGA_SHARING_CATEGORY,
  action: 'Played Game WhatsApp Share',
};

// -- Player Card
// TODO after refactoring transfer button to be dynamic on the street
// export const REACT_GA_CLUB_OFFERS_PLAYER_TO_JOIN_EVENT = {
//   category: RGA_USER_CATEGORY,
//   action: 'Club offer to player to join team',
// };
export const REACT_GA_PLAYER_SHORTLIST_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Player Shortlist Action',
};

// -- Team Card
// TODO after refactoring matches to team vs team & pickup games
// export const REACT_GA_OFFER_MATCH_EVENT = {
//   category: RGA_USER_CATEGORY,
//   action: 'Match Offer',
// };
export const REACT_GA_CLUB_SHORTLIST_EVENT = {
  category: RGA_USER_CATEGORY,
  action: 'Club Shortlist Action',
};
// TODO after refactoring transfer button to be dynamic on the street
// export const REACT_GA_PLAYER_ASKS_CLUB_TO_JOIN_EVENT = {
//   category: RGA_USER_CATEGORY,
//   action: 'Player asked to join team',
// };
