import {
  LIVE_MATCH_ABANDONED_EVENT,
  LIVE_MATCH_CANCELED_EVENT,
  LIVE_MATCH_RESOLVED_EVENT,
  LIVE_MATCH_SCHEDULED_EVENT,
  NEW_LIVE_MATCH_OFFER,
  NEW_LIVE_MATCH_RESULT_OFFER,
  NEW_LIVE_TRANSFER_OFFER,
  NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION,
} from 'contexts/events/constants';

export const PLAYER_USER_ROLE = 'player_user_role';
export const GM_USER_ROLE = 'gm_user_role';
export const ACTIVE_ROLE_LOCAL_STORAGE_ITEM = 'active-user-role';
export const ACTIVE_GM_ROLE_CLUB_STORAGE_ITEM = 'active-gm-club-data';

export const notificationsRoleTypes = {
  [GM_USER_ROLE]: 'club',
  [PLAYER_USER_ROLE]: 'player',
};

export const LAST_READ_NOTIFICATION_TIMESTAMP_TYPE =
  'lastReadNotificationTimestamp';
export const LAST_READ_OFFER_TIMESTAMP_TYPE = 'lastReadOfferTimestamp';

// shortlists
// -- types
export const ADD_CLUB_TO_SHORTLIST_ACTION_TYPE =
  'ADD_CLUB_TO_SHORTLIST_ACTION_TYPE';
export const REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE =
  'REMOVE_CLUB_FROM_SHORTLIST_ACTION_TYPE';
export const ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE =
  'ADD_PLAYER_TO_SHORTLIST_ACTION_TYPE';
export const REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE =
  'REMOVE_PLAYER_FROM_SHORTLIST_ACTION_TYPE';

// -- methods
export const ADD_TO_SHORTLIST_METHOD = 'ADD_TO_SHORTLIST_METHOD';
export const REMOVE_FROM_SHORTLIST_METHOD = 'REMOVE_FROM_SHORTLIST_METHOD';

// realtime notifications
// -- rt events
export const ROLE_IN_RT_EVENT = 'ROLE_IN_RT_EVENT';

// -- -- transfers
export const TRANSFER_OFFER_CREATED_RT_EVENT =
  'TRANSFER_OFFER_CREATED_RT_EVENT';
export const TRANSFER_OFFER_RESOLVED_RT_EVENT =
  'TRANSFER_OFFER_RESOLVED_RT_EVENT';

// -- -- matches
export const MATCH_CREATED_RT_EVENT = 'MATCH_CREATED_RT_EVENT';
export const MATCH_RESULT_PROPOSED_EVENT = 'MATCH_RESULT_PROPOSED_EVENT';
export const MATCH_SCHEDULED_RT_EVENT = 'MATCH_SCHEDULED_RT_EVENT';
export const MATCH_RESOLVED_RT_EVENT = 'MATCH_RESOLVED_RT_EVENT';
export const MATCH_CANCELED_RT_EVENT = 'MATCH_CANCELED_RT_EVENT';
export const MATCH_ABANDONED_RT_EVENT = 'MATCH_ABANDONED_RT_EVENT';

export const liveOfferEvents = [
  NEW_LIVE_TRANSFER_OFFER,
  NEW_LIVE_MATCH_OFFER,
  NEW_LIVE_MATCH_RESULT_OFFER,
];

export const standardSubscriptionEvents = [
  LIVE_MATCH_SCHEDULED_EVENT,
  LIVE_MATCH_RESOLVED_EVENT,
  LIVE_MATCH_CANCELED_EVENT,
  LIVE_MATCH_ABANDONED_EVENT,
];

export const realTimeSubscriptionParams = [
  {
    subEvent: TRANSFER_OFFER_CREATED_RT_EVENT,
    emitEvent: NEW_LIVE_TRANSFER_OFFER,
  },
  {
    subEvent: TRANSFER_OFFER_RESOLVED_RT_EVENT,
    emitEvent: NEW_LIVE_TRANSFER_RESOLVED_NOTIFICATION,
  },
  {
    subEvent: MATCH_CREATED_RT_EVENT,
    emitEvent: NEW_LIVE_MATCH_OFFER,
  },
  {
    subEvent: MATCH_RESULT_PROPOSED_EVENT,
    emitEvent: NEW_LIVE_MATCH_RESULT_OFFER,
  },
  {
    subEvent: MATCH_SCHEDULED_RT_EVENT,
    emitEvent: LIVE_MATCH_SCHEDULED_EVENT,
  },
  {
    subEvent: MATCH_RESOLVED_RT_EVENT,
    emitEvent: LIVE_MATCH_RESOLVED_EVENT,
  },
  {
    subEvent: MATCH_CANCELED_RT_EVENT,
    emitEvent: LIVE_MATCH_CANCELED_EVENT,
  },
  {
    subEvent: MATCH_ABANDONED_RT_EVENT,
    emitEvent: LIVE_MATCH_ABANDONED_EVENT,
  },
];
