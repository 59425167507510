import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ORGANIZED_GAME_EVENTS_TAB,
  GAME_EVENTS_SUBSCRIPTIONS_TAB,
} from '../constants';
import { getFormattedGameEvents } from 'features/base-3-part-card/formatters';
import { getGameEventsService } from 'common/api/gameEventsService';
import { useApi, useThrottle } from 'common/hooks';
import { API_LONG_THROTTLING_DELAY_MS } from 'common/constants/integers';

// NOTE assume that your are using this feature only for Profile page, for the active user
const useProps = () => {
  const { asyncWrapper } = useApi();
  const { callActionWithThrottle } = useThrottle();

  // tabs
  const [activeTab, setActiveTab] = useState(GAME_EVENTS_SUBSCRIPTIONS_TAB);
  const tabsData = useMemo(
    () => [
      {
        label: GAME_EVENTS_SUBSCRIPTIONS_TAB,
        onClick: () => {
          setActiveTab(GAME_EVENTS_SUBSCRIPTIONS_TAB);
        },
        isSelected: activeTab === GAME_EVENTS_SUBSCRIPTIONS_TAB,
      },
      {
        label: ORGANIZED_GAME_EVENTS_TAB,
        onClick: () => {
          setActiveTab(ORGANIZED_GAME_EVENTS_TAB);
        },
        isSelected: activeTab === ORGANIZED_GAME_EVENTS_TAB,
      },
    ],
    [activeTab]
  );

  // game events
  const [gameEventsData, setGameEventsData] = useState({});
  const [selectedGameEvent, setSelectedGameEvent] = useState(null);
  const closeGameEvent = useCallback(() => {
    setSelectedGameEvent(null);
  }, []);

  const loadGameEventsData = useCallback(() => {
    asyncWrapper(async () => {
      const response = await getGameEventsService(`userGameEvents=true`)();

      if (response.statusText === 'OK') {
        setGameEventsData({
          subscriptions: response.data.gameEvents.subscriptions,
          hosted: response.data.gameEvents.hosted,
        });
      }
    });
  }, [asyncWrapper]);

  useEffect(() => {
    callActionWithThrottle(loadGameEventsData, API_LONG_THROTTLING_DELAY_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadGameEventsData, activeTab]);

  const formattedGameEventItems = useMemo(() => {
    if (
      activeTab === GAME_EVENTS_SUBSCRIPTIONS_TAB &&
      !!gameEventsData.subscriptions
    ) {
      return getFormattedGameEvents(
        gameEventsData?.subscriptions,
        setSelectedGameEvent
      );
    } else if (
      activeTab === ORGANIZED_GAME_EVENTS_TAB &&
      !!gameEventsData.hosted
    ) {
      return getFormattedGameEvents(
        gameEventsData?.hosted,
        setSelectedGameEvent
      );
    } else {
      return [];
    }
  }, [activeTab, gameEventsData]);

  return {
    // tabs
    activeTab,
    tabsData,
    formattedGameEventItems,

    // game events
    selectedGameEvent,
    closeGameEvent,
  };
};

export default useProps;
