import { MATCH_RESOLVED_STATUS } from 'features/match/constants';
import { formatMatchDateDD_MM_YYYY, formatOutcome } from 'features/match/utils';
import { useCallback, useMemo, useState } from 'react';
import { PLAYED_MATCHES_TAB, SCHEDULED_MATCHES_TAB } from '../constants';

const useProps = ({ matchItems }) => {
  // tabs
  const [activeTab, setActiveTab] = useState(SCHEDULED_MATCHES_TAB);
  const tabsData = useMemo(
    () => [
      {
        label: SCHEDULED_MATCHES_TAB,
        onClick: () => {
          setActiveTab(SCHEDULED_MATCHES_TAB);
        },
        isSelected: activeTab === SCHEDULED_MATCHES_TAB,
      },
      {
        label: PLAYED_MATCHES_TAB,
        onClick: () => {
          setActiveTab(PLAYED_MATCHES_TAB);
        },
        isSelected: activeTab === PLAYED_MATCHES_TAB,
      },
    ],
    [activeTab]
  );

  // matches
  const [selectedMatch, setSelectedMatch] = useState(null);
  const closeMatchModal = useCallback(() => {
    setSelectedMatch(null);
  }, []);

  const formatMatchItems = useCallback((filterOutPastDates, items = []) => {
    let formattedItems = items?.map(item => {
      let matchSymbol = 'vs';

      if (item.outcome && item?.status === MATCH_RESOLVED_STATUS) {
        matchSymbol = formatOutcome(item);
      }

      return {
        _date: item?.matchDate?.dayKey,
        date: formatMatchDateDD_MM_YYYY(item?.matchDate?.dayKey),
        homeTeam: {
          color: item?.homeTeam?.logo?.color,
          imageURL: item?.homeTeam?.imageURL,
          name: item?.homeTeam?.name,
          isPlayerMember: item?.homeTeam?.isPlayerMember,
        },
        awayTeam: {
          color: item?.awayTeam?.logo?.color,
          imageURL: item?.awayTeam?.imageURL,
          name: item?.awayTeam?.name,
          isPlayerMember: item?.awayTeam?.isPlayerMember,
        },
        matchSymbol,
        onClick: () => {
          setSelectedMatch(item);
        },
      };
    });

    if (filterOutPastDates) {
      formattedItems = formattedItems.filter(item => {
        const matchDate = new Date(item?._date);
        return matchDate > Date.now();
      });
    }

    formattedItems = formattedItems.sort((a, b) => {
      const dateA = new Date(a?._date);
      const dateB = new Date(b?._date);
      return dateA < dateB ? -1 : 1;
    });

    return formattedItems;
  }, []);

  const formattedMatchItems = useMemo(() => {
    if (activeTab === SCHEDULED_MATCHES_TAB) {
      return formatMatchItems(false, matchItems?.scheduledMatches);
    } else if (activeTab === PLAYED_MATCHES_TAB) {
      return formatMatchItems(false, matchItems?.resolvedMatches);
    } else {
      return [];
    }
  }, [
    activeTab,
    formatMatchItems,
    matchItems?.resolvedMatches,
    matchItems?.scheduledMatches,
  ]);

  return {
    // tabs
    activeTab,
    tabsData,
    formattedMatchItems,

    // matches
    selectedMatch,
    closeMatchModal,
  };
};

export default useProps;
