import PropTypes from 'prop-types';
import { Children, useCallback } from 'react';
import style from './ClickView.module.scss';

const ClickView = ({ dynamicClassNameSufix, rowsData }) => {
  const renderCards = useCallback(
    cards =>
      Children.toArray(
        cards?.map(({ Component, props }) => (
          <div className={`${style.cardWrapper}`}>
            <Component {...props} />
          </div>
        ))
      ),
    []
  );

  const renderRows = useCallback(
    () =>
      Children.toArray(
        rowsData?.map(({ label, cards }) =>
          cards.length > 0 ? (
            <div className={style['category']}>
              <h3>{label}</h3>
              <div className={style['row-wrapper']}>
                <div className={style['row']}>{renderCards(cards)}</div>
              </div>
            </div>
          ) : null
        )
      ),
    [renderCards, rowsData]
  );

  return (
    <div
      className={`${style.wrapper} ${
        style[`wrapper--${dynamicClassNameSufix}`]
      }`}
    >
      {renderRows()}
    </div>
  );
};

export const ClickViewPropTypes = {
  dynamicClassNameSufix: PropTypes.string,
  rowsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      cards: PropTypes.arrayOf(PropTypes.any),
    })
  ),
};

ClickView.propTypes = ClickViewPropTypes;

export default ClickView;
