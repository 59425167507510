import { useCallback, useState } from 'react';

const useThrottle = () => {
  const [isThrottled, setIsThrottled] = useState(false);

  const callActionWithThrottle = useCallback(
    (action, delayMS) => {
      if (!isThrottled) {
        action();
        setIsThrottled(true);
        const timeout = setTimeout(() => {
          setIsThrottled(false);
          clearTimeout(timeout);
        }, delayMS);
      }
    },
    [isThrottled]
  );

  return {
    callActionWithThrottle,
  };
};

export default useThrottle;
