import { useWindowSizeContext } from 'contexts/window-size';
import Modal from 'features/modal';
import { CENTER_MODAL_VARIANT, DARK_THEME } from 'features/modal/constants';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import './Base3PartCard.scss';
import ClickView, { ClickViewPropTypes } from './components/click-view';
import FrontFaceView, {
  FrontFaceViewPropTypes,
} from './components/front-face-view';
import FrontFaceViewLogosVariant from './components/front-face-view-logos-variant';
import HoverView, { HoverViewProps } from './components/hover-view';
import {
  CLICK_VIEW,
  dynamicClassNames,
  FRONT_FACE_VIEW,
  FRONT_FACE_VIEW_LOGOS_VARIANT,
} from './constants';
import { useProps } from './hooks';

const className = 'c-Base3PartCard';

const Base3PartCard = ({
  frontFaceViewProps,
  hoverViewProps,
  clickViewProps,
  onlyFrontFaceView,
  isSmallCard,
  preventFrontFaceViewOnClickOverride,
  reactGAModalView,
}) => {
  const {
    // active card view
    activeView,
    setFrontFaceView,
    setHoverView,
    setClickView,
    onCloseClickView,

    // dynamic class names
    dynamicClassNamesSufix,
  } = useProps();
  const { isMobileView, isDesktopView } = useWindowSizeContext();

  const initCardDynamicClassNamesSufix =
    activeView === CLICK_VIEW
      ? dynamicClassNames[FRONT_FACE_VIEW]
      : dynamicClassNamesSufix;
  const cloneCardDynamicClassNamesSufix = dynamicClassNames[CLICK_VIEW];

  const onMouseEnterHandler = useMemo(
    () =>
      onlyFrontFaceView || isSmallCard || isMobileView
        ? () => {}
        : setHoverView,
    [isMobileView, isSmallCard, onlyFrontFaceView, setHoverView]
  );

  const getDynamicFrontFaceViewProps = useCallback(() => {
    const dynamicFrontFaceViewProps = { ...frontFaceViewProps };

    if (
      (!isDesktopView && !preventFrontFaceViewOnClickOverride) ||
      isSmallCard
    ) {
      dynamicFrontFaceViewProps.onClick = setClickView;
    }

    return dynamicFrontFaceViewProps;
  }, [
    frontFaceViewProps,
    isDesktopView,
    isSmallCard,
    preventFrontFaceViewOnClickOverride,
    setClickView,
  ]);

  const FrontFaceViewComponent = useMemo(
    () =>
      frontFaceViewProps?.frontFaceViewVariant === FRONT_FACE_VIEW_LOGOS_VARIANT
        ? FrontFaceViewLogosVariant
        : FrontFaceView,
    [frontFaceViewProps?.frontFaceViewVariant]
  );

  const smallCarrdOuterWrapperClassName = useMemo(
    () => (isSmallCard ? `${className}--outer-wrapper--small` : ''),
    [isSmallCard]
  );

  const smallCardClassName = useMemo(
    () => (isSmallCard ? `${className}--small` : ''),
    [isSmallCard]
  );

  return (
    <>
      <div
        className={`${className}--outer-wrapper ${smallCarrdOuterWrapperClassName}`}
      >
        <div
          className={`${className} ${smallCardClassName} ${className}--${initCardDynamicClassNamesSufix}`}
          onMouseLeave={setFrontFaceView}
        >
          <div onMouseEnter={onMouseEnterHandler}>
            <FrontFaceViewComponent
              {...getDynamicFrontFaceViewProps()}
              dynamicClassNameSufix={initCardDynamicClassNamesSufix}
            />
          </div>

          {!onlyFrontFaceView && (
            <HoverView
              dynamicClassNameSufix={initCardDynamicClassNamesSufix}
              onViewMore={setClickView}
              showOnViewMoreButton={activeView !== CLICK_VIEW}
              {...hoverViewProps}
            />
          )}
        </div>
      </div>

      {/* Click View */}
      {activeView === CLICK_VIEW && (
        <Modal
          fullScreen={isMobileView}
          variant={CENTER_MODAL_VARIANT}
          theme={DARK_THEME}
          onClose={onCloseClickView}
          withoutWrapper
          customClassName={`${className}__custom-modal`}
          reactGAModalView={reactGAModalView}
        >
          <div
            className={`${className} ${className}--${cloneCardDynamicClassNamesSufix}`}
            onMouseLeave={setFrontFaceView}
          >
            <div onMouseEnter={setHoverView}>
              <FrontFaceViewComponent
                {...frontFaceViewProps}
                dynamicClassNameSufix={cloneCardDynamicClassNamesSufix}
              />
            </div>

            <HoverView
              dynamicClassNameSufix={cloneCardDynamicClassNamesSufix}
              {...hoverViewProps}
            />

            <ClickView
              dynamicClassNameSufix={cloneCardDynamicClassNamesSufix}
              {...clickViewProps}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

Base3PartCard.propTypes = {
  frontFaceViewProps: PropTypes.shape(FrontFaceViewPropTypes),
  hoverViewProps: PropTypes.shape(HoverViewProps),
  clickViewProps: PropTypes.shape(ClickViewPropTypes),
  onlyFrontFaceView: PropTypes.bool,
  isSmallCard: PropTypes.bool,
  preventFrontFaceViewOnClickOverride: PropTypes.bool,
  reactGAModalView: PropTypes.string,
};

export default Base3PartCard;
