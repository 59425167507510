import { useAuthContext } from 'contexts/auth';
import ImageUploader from 'features/image-uploader';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ClubLogo, { ClubLogoPropTypes } from '../club-logo/ClubLogo';
import HeadingWithSubtext from '../heading-with-subtext';
import Image, { ImagePropTypes } from '../image';

import './HeroHeading.scss';

const className = 'c-HeroHeading';

const HeroHeading = ({
  img,
  heading,
  subtext,
  subtext2,
  withFlag,
  imageUploadProps,
}) => {
  const { isProfileOwner } = useAuthContext();
  // NOTE smart image rendering due to mixed img types
  const renderImg = useCallback(
    () => (
      <div className={`${className}__img-wrapper`}>
        {img?.shape ? <ClubLogo {...img} /> : <Image {...img} />}
        {imageUploadProps && (isProfileOwner || imageUploadProps?.isUserGM) && (
          <ImageUploader {...imageUploadProps} />
        )}
      </div>
    ),
    [imageUploadProps, img, isProfileOwner]
  );
  return (
    <div className={className}>
      {renderImg()}
      <HeadingWithSubtext
        heading={heading}
        subtext={subtext}
        subtext2={subtext2}
        withFlag={withFlag}
      />
    </div>
  );
};

HeroHeading.propTypes = {
  img: PropTypes.oneOf([
    PropTypes.shape(ImagePropTypes),
    PropTypes.shape(ClubLogoPropTypes),
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  subtext2: PropTypes.string,
  withFlag: PropTypes.string,
  imageUploadProps: PropTypes.object,
};

export default HeroHeading;
