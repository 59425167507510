import {
  NUMERICAL_PLAYER_EXPERIENCE_LEVELS,
  PlayerExperienceTypes,
} from 'common/constants/users';
import {
  SKILL_LEVEL_STRONG,
  SKILL_LEVEL_GOOD,
} from 'features/player-form/constants';

export const mapSkillsType = skills => {
  const strong = skills?.strong || [];
  const good = skills?.good || [];
  const formattedStrongSkills = strong?.map(skill => [
    skill,
    SKILL_LEVEL_STRONG,
  ]);
  const formattedGoodSkills = good?.map(skill => [skill, SKILL_LEVEL_GOOD]);

  return [...formattedStrongSkills, ...formattedGoodSkills].slice(0, 3);
};

export const sortSkillsByRate = skills => {
  const strong = [];
  const good = [];

  skills.forEach(([skill, rate]) => {
    if (rate === SKILL_LEVEL_STRONG) {
      strong.push([skill, rate]);
    } else {
      good.push([skill, rate]);
    }
  });

  return [...strong, ...good].slice(0, 3);
};

export const mapExperienceToStars = experience => {
  const numericalExperienceLevel =
    NUMERICAL_PLAYER_EXPERIENCE_LEVELS[experience];

  let starsString = '';

  for (let i = 0; i < numericalExperienceLevel; i++) {
    starsString += '⭐';
  }

  return starsString;
};

export const mapExperienceToClassName = experience => {
  switch (experience) {
    case PlayerExperienceTypes.BEGINNER:
      return 'skillLevel--beginner';
    case PlayerExperienceTypes.AMATEUR:
      return 'skillLevel--amateur';
    case PlayerExperienceTypes.SEMI_PRO:
      return 'skillLevel--semi-pro';
    case PlayerExperienceTypes.PROFESSIONAL:
      return 'skillLevel--professional';
    case PlayerExperienceTypes.INTERNATIONAL:
      return 'skillLevel--international';
    case PlayerExperienceTypes.WORLD_CLASS:
      return 'skillLevel--world-class';
    default:
      return '';
  }
};
