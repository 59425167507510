import {
  ARRANGING_MATCH_STATUS,
  MATCH_ABANDONED_STATUS,
  MATCH_CANCELED_STATUS,
  MATCH_NOT_ARRANGED_STATUS,
  MATCH_RESOLVED_STATUS,
  MATCH_SCHEDULED_STATUS,
} from 'features/match/constants';

export const FRONT_FACE_VIEW = 'FRONT_FACE_VIEW';
export const HOVER_VIEW = 'HOVER_VIEW';
export const CLICK_VIEW = 'CLICK_VIEW';

export const dynamicClassNames = {
  [FRONT_FACE_VIEW]: 'for-front-face-view',
  [HOVER_VIEW]: 'for-hover-view',
  [CLICK_VIEW]: 'for-click-view',
};

// front face view
export const INFO_TOP_TYPE_TEXT = 'text';
export const INFO_TOP_TYPE_IMAGE = 'image';

export const INFO_BOTTOM_TYPE_SINGLE = 'single';
export const INFO_BOTTOM_TYPE_DOUBLE = 'double';

export const FRONT_FACE_VIEW_STANDARD_VARIANT =
  'FRONT_FACE_VIEW_STANDARD_VARIANT';
export const FRONT_FACE_VIEW_LOGOS_VARIANT = 'FRONT_FACE_VIEW_LOGOS_VARIANT';
export const FRONT_FACE_VIEW_PLAYER_VARIANT = 'FRONT_FACE_VIEW_PLAYER_VARIANT';

export const matchStatusLabels = {
  [MATCH_SCHEDULED_STATUS]: 'scheduled',
  [MATCH_CANCELED_STATUS]: 'canceled',
  [MATCH_ABANDONED_STATUS]: 'abandoned',
  [MATCH_NOT_ARRANGED_STATUS]: 'not arranged',
  [ARRANGING_MATCH_STATUS]: 'arranging',
  [MATCH_RESOLVED_STATUS]: 'resolved',
};
