import { getSearchResults } from 'common/api/searchService';
import { REACT_GA_SEARCH_EVENT } from 'common/constants/react-google-analytics/events';
import { DEFAULT_USER_IMAGE } from 'common/constants/common-props';
import { useApi, useNavigation } from 'common/hooks';
import { formatImage } from 'common/utils/formatting';
import { useEventsContext } from 'contexts/events';
import { OUTSIDE_CLICK_EVENT } from 'contexts/events/constants';
import { usePrivateNavContext } from 'contexts/private-nav';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CATEGORY_GROUP,
  CITY_SEARCH_FILTER,
  CLUBS_SEARCH_FILTER,
  COUNTRY_SEARCH_FILTER,
  LOCATION_GROUP,
  PLAYERS_SEARCH_FILTER,
  SEARCH_TIME_TRESHOLD,
} from '../constants';
import { isClubDocument } from '../utils';
import ReactGA from 'react-ga';

const useProps = () => {
  const { apiWrapper } = useApi();
  const { searchOpened, openSearch, closeSearch } = usePrivateNavContext();
  const { navigateToClubPage, navigateToUserPage } = useNavigation();
  const { subscribe } = useEventsContext();

  const [searchLocked, setSearchLocked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchItemsData, setSearchItemsData] = useState([]);

  const categoryOptions = useMemo(
    () => ({
      name: CATEGORY_GROUP,
      options: [
        {
          isSelected: selectedCategory === PLAYERS_SEARCH_FILTER,
          text: PLAYERS_SEARCH_FILTER,
          onClick: () => {
            setSelectedCategory(curr =>
              curr === null ? PLAYERS_SEARCH_FILTER : null
            );
            setSelectedLocation(null);
          },
        },
        {
          isSelected: selectedCategory === CLUBS_SEARCH_FILTER,
          text: CLUBS_SEARCH_FILTER,
          onClick: () => {
            setSelectedCategory(curr =>
              curr === null ? CLUBS_SEARCH_FILTER : null
            );
            setSelectedLocation(null);
          },
        },
      ],
    }),
    [selectedCategory]
  );

  const locationOptions = useMemo(
    () => ({
      name: LOCATION_GROUP,
      options: [
        {
          isSelected: selectedLocation === CITY_SEARCH_FILTER,
          text: CITY_SEARCH_FILTER,
          onClick: () => {
            setSelectedLocation(curr =>
              curr === null ? CITY_SEARCH_FILTER : null
            );
            setSelectedCategory(null);
          },
        },
        {
          isSelected: selectedLocation === COUNTRY_SEARCH_FILTER,
          text: COUNTRY_SEARCH_FILTER,
          onClick: () => {
            setSelectedLocation(curr =>
              curr === null ? COUNTRY_SEARCH_FILTER : null
            );
            setSelectedCategory(null);
          },
        },
      ],
    }),
    [selectedLocation]
  );

  const clearSearchQueries = useCallback(() => {
    setSearchQuery('');
    setSelectedCategory(null);
    setSelectedLocation(null);
    setSearchItemsData([]);
  }, []);

  const formatItemsData = useCallback(
    items =>
      items.map(item => {
        let cardProps;
        if (isClubDocument(item)) {
          cardProps = {
            message: [
              { text: item?.name, isBolder: true },
              { text: ` ${item?.city}, ${item?.country}`, isSmaller: true },
            ],
            cardLevelClickHandler: () => navigateToClubPage(item?._id),
            // img: item?.logo || DEFAULT_CLUB_LOGO,
            img: formatImage(item?.image.url),
          };
        } else {
          // NOTE assuming it is a player doc
          cardProps = {
            message: [
              { text: `${item?.firstName} ${item?.lastName}`, isBolder: true },
              { text: ` ${item?.city}, ${item?.country}`, isSmaller: true },
            ],
            cardLevelClickHandler: () => navigateToUserPage(item?._id),
            img: item?.image?.url
              ? formatImage(item?.image?.url)
              : DEFAULT_USER_IMAGE,
          };
        }

        return cardProps;
      }),
    [navigateToClubPage, navigateToUserPage]
  );

  const triggerSearch = useCallback(
    query =>
      apiWrapper(
        async () => {
          const apiResponse = await getSearchResults(
            query,
            selectedCategory,
            selectedLocation
          );
          if (typeof apiResponse === 'object')
            setSearchItemsData(formatItemsData(apiResponse?.data));
        },
        null,
        null,
        true
      ),
    [apiWrapper, formatItemsData, selectedCategory, selectedLocation]
  );

  const handleSearch = useCallback(
    e => {
      const latestQuery = e.target.value;
      setSearchQuery(latestQuery);
      if (!searchLocked) {
        triggerSearch(latestQuery);
        setSearchLocked(true);
        const lockTimeout = setTimeout(() => {
          setSearchLocked(false);
          clearTimeout(lockTimeout);
        }, SEARCH_TIME_TRESHOLD);

        ReactGA.event(REACT_GA_SEARCH_EVENT);
      }
    },
    [searchLocked, triggerSearch]
  );

  useEffect(() => {
    subscribe(OUTSIDE_CLICK_EVENT, closeSearch);
  }, [closeSearch, subscribe]);

  return {
    searchQuery,
    onSearchQueryChange: handleSearch,
    clearSearchQueries,

    tagOptions: [categoryOptions, locationOptions],

    searchItemsData,

    searchOpened,
    openSearch,
  };
};

export default useProps;
