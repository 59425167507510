import {
  firstName,
  lastName,
  // birthDate,
  city,
  country,
} from 'common/schemas/user';

const yup = require('yup');

export const editBioSchema = yup.object().shape({
  firstName,
  lastName,
  country,
  city,
});

export const editSignatureSchema = yup.object().shape({
  number: yup.number().min(0).max(99),
  nickname: yup.string(),
});
