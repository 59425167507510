import { goToWithReload } from 'common/utils/routing';
import { useCallback } from 'react';
import Headline from '../headline';
import Text from '../text';
import { columnOneLinks, columnTwoLinks, socialMediaData } from './constants';
import './Footer.scss';

const className = 'c-Footer';

const moto = [{ text: 'For The Love Of The Game' }];
const madeInText = [
  {
    // eslint-disable-next-line max-len
    text: 'made from Earth, the Solar System, Oort Cloud, Milky Way Galaxy, Local Group, Virgo Supercluster',
    isSmaller: true,
  },
];

const Footer = () => {
  const renderLinks = useCallback(links => {
    return links.map(({ label, link, toRender }) => {
      const onClick = () => {
        if (link) {
          goToWithReload(link);
        }
      };

      return (
        <li key={link} className={`${className}__link`} onClick={onClick}>
          {toRender ? toRender() : <span>{label}</span>}
        </li>
      );
    });
  }, []);

  const renderSocialMediaLinks = useCallback(
    () =>
      socialMediaData.map(({ Icon, link, cb }) => (
        <li
          key={link}
          className={`${className}__social-media-item`}
          onClick={cb}
        >
          <a href={link} target='_blank' rel='noreferrer'>
            <Icon />
          </a>
        </li>
      )),
    []
  );

  return (
    <footer className={className}>
      <div className={`${className}__social-media-row`}>
        <ul>{renderSocialMediaLinks(socialMediaData)}</ul>
      </div>
      <div className={`${className}__links-wrapper`}>
        <ul className={`${className}__links`}>
          {/* Links */}
          {renderLinks(columnOneLinks)}
        </ul>
        <ul className={`${className}__links`}>
          {/* Links */}
          {renderLinks(columnTwoLinks)}
        </ul>
      </div>
      <div className={`${className}__content-wrapper`}>
        <Headline mainText={moto} size={3} variant='primary' />
        <Text data={madeInText} color='light' />
      </div>
    </footer>
  );
};

export default Footer;
