/* eslint-disable max-len */
import { ICON_DARK_THEME } from 'common/constants/theming';
import PropTypes from 'prop-types';

const PersonIcon = ({ color }) => (
  <svg
    className='c-PersonIcon'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill='#000000'
  >
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill={color}
      d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z'
    />
  </svg>
);

PersonIcon.defaultProps = { color: ICON_DARK_THEME };

PersonIcon.propTypes = {
  color: PropTypes.string,
};

export default PersonIcon;
