import Club3PartCard from 'common/components/club-3-part-card';
import EventPreviewCard from 'common/components/event-preview-card';
import StarIcon from 'common/components/icons/star-icon';
import Match3PartCard from 'common/components/match-3-part-card';
import Player3PartCard from 'common/components/player-3-part-card';
import { COUNTRY_CODES } from 'common/constants/geoData';
import {
  formatTimeslotForCalendar,
  getMatchDateItems,
  getMatchDate__DD_MM,
} from 'common/utils/dates';
import { formatImage } from 'common/utils/formatting';
import {
  GM_USER_ROLE,
  PLAYER_USER_ROLE,
} from 'contexts/user-session/hooks/constants';
import FrontFaceView from 'features/base-3-part-card/components/front-face-view';
import {
  FRONT_FACE_VIEW_LOGOS_VARIANT,
  FRONT_FACE_VIEW_STANDARD_VARIANT,
  INFO_BOTTOM_TYPE_DOUBLE,
  INFO_BOTTOM_TYPE_SINGLE,
  INFO_TOP_TYPE_IMAGE,
  INFO_TOP_TYPE_TEXT,
  matchStatusLabels,
} from 'features/base-3-part-card/constants';
import CreateMatchButton from 'features/create-match-button';
import {
  AWAY_TEAM_WIN,
  DRAW,
  HOME_TEAM_WIN,
  MATCH_RESOLVED_STATUS,
  MATCH_SCHEDULED_STATUS,
} from 'features/match/constants';
import { formatOutcome } from 'features/match/utils';
import ShortlistButton from 'features/shortlist-button';
import TransferButton from 'features/transfer-button';
import {
  NICKNAME_SIGNATURE_TYPE,
  NO_SIGNATURE,
  NUMBER_SIGNATURE_TYPE,
} from 'pages/profile/constants';
import {
  CLUB_STREET_ITEM_TYPE,
  PLAYER_STREET_ITEM_TYPE,
} from 'pages/street/constants';

export const getStaticClubFrontFaceViewProps = (
  data,
  smallFrontFaceCardVariant
) => ({
  type: CLUB_STREET_ITEM_TYPE,
  smallFrontFaceCardVariant,
  _id: data?._id,
  infoCenter: {
    type: INFO_TOP_TYPE_IMAGE,
    // value: data?.logo,
    value: data?.imageURL,
  },
  infoBottom: {
    type: INFO_BOTTOM_TYPE_SINGLE,
    singleVariantData: {
      infoOne: { data: [{ text: data?.name }] },
      infoTwo: { data: [{ text: `${data?.city}, ${data?.country}` }] },
    },
  },
});

const getSignatureProps = data => {
  const type = data?.signatureType;
  if (type === NO_SIGNATURE) return null;

  let formattedType;
  let signature;

  if (type === NUMBER_SIGNATURE_TYPE) {
    signature = data?.shirtNumber;
    formattedType = 'number';
  } else if (type === NICKNAME_SIGNATURE_TYPE) {
    signature = data?.nickname;
    formattedType = 'nickname';
  }

  return {
    type: formattedType,
    signature,
    color: data?.color,
  };
};

export const getStaticPlayerFrontFaceViewProps = (
  data,
  smallFrontFaceCardVariant
) => ({
  type: PLAYER_STREET_ITEM_TYPE,
  smallFrontFaceCardVariant,
  _id: data?._id,
  signature: getSignatureProps(data),
  infoCenter: {
    type: INFO_TOP_TYPE_IMAGE,
    image: formatImage(data?.imageURL),
  },
  infoBottom: {
    type: INFO_BOTTOM_TYPE_SINGLE,
    singleVariantData: {
      infoOne: {
        data: [{ text: `${data?.firstName} ${data?.lastName}` }],
      },
      infoTwo: {
        renderCountryFlag: data?.country,
        data: [
          { text: `📍 ${data?.city}`, isNewLine: true },
          { flag: COUNTRY_CODES?.[data?.country] },
          { text: ` ${data?.country}` },
        ],
      },
    },
  },
});

export const getStaticMatchFrontFaceViewProps = data => {
  const [centerText, centerTextColor] =
    data?.status === MATCH_SCHEDULED_STATUS
      ? [
          `⏱ ${formatTimeslotForCalendar(
            data?.matchDate?.timeslot
          )}, ${getMatchDate__DD_MM(data?.matchDate?.dayKey)}`,
          'light',
        ]
      : data?.status === MATCH_RESOLVED_STATUS
      ? [formatOutcome(data), 'light']
      : [matchStatusLabels[data?.status], 'dark'];

  const homeTeam = data?.homeTeam;

  const awayTeam = data?.awayTeam;

  const frontFaceViewProps = {
    type: data?.type,
    _id: data?._id,
    matchData: data,
    matchMeta: data?.matchMeta,
    frontFaceViewVariant: data?.frontFaceViewVariant,
    infoTop: {
      data: [{ text: `${data?.city}, ${data?.country}`, isBolder: true }],
    },
    infoCenter: {
      type: INFO_TOP_TYPE_TEXT,
      value: { data: [{ text: centerText, color: centerTextColor }] },
    },
    infoBottom: {
      type: INFO_BOTTOM_TYPE_DOUBLE,
      doubleVariantData: {
        leftSideData: {
          infoOne: { data: [{ text: homeTeam?.name }] },
          infoTwo: {
            data: [{ text: `${homeTeam?.city}, ${homeTeam?.country}` }],
          },
          // infoLogo: homeTeam?.logo,
          infoLogo: homeTeam?.imageURL,
        },
        rightSideData: {
          infoOne: { data: [{ text: awayTeam?.name }] },
          infoTwo: {
            data: [{ text: `${awayTeam?.city}, ${awayTeam?.country}` }],
          },
          // infoLogo: awayTeam?.logo,
          infoLogo: awayTeam?.imageURL,
        },
      },
    },
  };

  return frontFaceViewProps;
};

export const formatInlineCards = (Component, data, cardFormatter) =>
  data?.map(dataItem => {
    return { Component, props: cardFormatter(dataItem) };
  });

export const formatClubCard = (
  data,
  activeRole,
  activeRoleType,
  onMatchOffer,
  activeMembership,
  smallFrontFaceCardVariant,
  shortlistCallbacks
) => {
  const actionButtons = [];

  if (activeRoleType === PLAYER_USER_ROLE) {
    actionButtons.push({
      StandaloneButton: TransferButton,
      standaloneButtonProps: {
        clubId: data?._id,
        playerId: activeRole,
        activeMembership,
      },
    });
  }

  if (activeRoleType === GM_USER_ROLE && activeRole !== data?._id) {
    actionButtons.push({
      StandaloneButton: CreateMatchButton,
      standaloneButtonProps: {
        onClick: () => {
          onMatchOffer(data);
        },
      },
    });
  }

  actionButtons.push({
    StandaloneButton: ShortlistButton,
    standaloneButtonProps: {
      clubId: data?._id,
      ...shortlistCallbacks,
    },
  });
  return {
    Component: Club3PartCard,
    props: {
      frontFaceViewProps: getStaticClubFrontFaceViewProps(
        data,
        smallFrontFaceCardVariant
      ),
      hoverViewProps: {
        actionButtons,
      },
      clickViewProps: {
        rowsData: [
          {
            label: 'Matches',
            cards: formatInlineCards(
              FrontFaceView,
              data?.playedMatches,
              getStaticMatchFrontFaceViewProps
            ),
          },
          {
            label: 'Players',
            cards: formatInlineCards(
              FrontFaceView,
              data?.players,
              getStaticPlayerFrontFaceViewProps
            ),
          },
        ],
      },
    },
  };
};

export const formatPlayerCard = (
  data,
  activeRole,
  activeRoleType,
  activeMembership,
  smallFrontFaceCardVariant,
  shortlistCallbacks
) => {
  const actionButtons = [];

  if (activeRoleType === GM_USER_ROLE) {
    actionButtons.push({
      StandaloneButton: TransferButton,
      standaloneButtonProps: {
        playerId: data?._id,
        clubId: activeRole,
        activeMembership,
      },
    });
  }

  actionButtons.push({
    StandaloneButton: ShortlistButton,
    standaloneButtonProps: {
      playerId: data?._id,
      ...shortlistCallbacks,
    },
  });

  return {
    Component: Player3PartCard,
    props: {
      frontFaceViewProps: getStaticPlayerFrontFaceViewProps(
        data,
        smallFrontFaceCardVariant
      ),
      hoverViewProps: {
        actionButtons,
      },
      clickViewProps: {
        rowsData: [
          // NOTE FAS switching off Team memberships view as a part of the switching off the Club feature
          // {
          //   label: 'Teams',
          //   cards: formatInlineCards(
          //     FrontFaceView,
          //     data?.clubs,
          //     getStaticClubFrontFaceViewProps
          //   ),
          // },
        ],
      },
    },
  };
};

export const formatMatchCard = data => {
  const bgVariant =
    data?.status === MATCH_SCHEDULED_STATUS ? 'dark' : 'neutral';

  const frontFaceViewVariant =
    data?.frontFaceViewVariant === FRONT_FACE_VIEW_LOGOS_VARIANT
      ? FRONT_FACE_VIEW_LOGOS_VARIANT
      : FRONT_FACE_VIEW_STANDARD_VARIANT;

  const matchMeta =
    data?.frontFaceViewVariant === FRONT_FACE_VIEW_LOGOS_VARIANT
      ? {
          date: getMatchDate__DD_MM(data?.matchDate?.dayKey),
          city: data?.city,
          outcome: data?.outcome,
        }
      : undefined;

  const _date = data?.matchDate?.dayKey;

  return {
    _date,
    Component: Match3PartCard,
    props: {
      bgVariant,
      frontFaceViewProps: getStaticMatchFrontFaceViewProps({
        ...data,
        frontFaceViewVariant,
        matchMeta,
      }),
    },
  };
};

export const getFormattedClubs = (
  data,
  activeRole,
  activeRoleType,
  onMatchOffer,
  smallFrontFaceCardVariant,
  shortlistCallbacks
) =>
  data?.map(item =>
    formatClubCard(
      item,
      activeRole,
      activeRoleType,
      onMatchOffer,
      false,
      smallFrontFaceCardVariant,
      shortlistCallbacks
    )
  );

export const getFormattedPlayers = (
  data,
  activeRole,
  activeRoleType,
  activeMembership,
  smallFrontFaceCardVariant,
  shortlistCallbacks
) => {
  return data?.map(item =>
    formatPlayerCard(
      item,
      activeRole,
      activeRoleType,
      activeMembership,
      smallFrontFaceCardVariant,
      shortlistCallbacks
    )
  );
};

const sortItemsByFirstMatchDate = (a, b) => {
  const dateA = new Date(a?._date);
  const dateB = new Date(b?._date);
  return dateA < dateB ? -1 : 1;
};

const sortItemsByLastMatchDate = (a, b) => {
  const dateA = new Date(a?._date);
  const dateB = new Date(b?._date);
  return dateA > dateB ? -1 : 1;
};

export const getFormattedScheduledOrPlayedMatches = (
  data,
  frontFaceViewVariant,
  sortBy
) => {
  const sortFn =
    sortBy === 'first-date'
      ? sortItemsByFirstMatchDate
      : sortBy === 'last-date'
      ? sortItemsByLastMatchDate
      : null;

  let formattedData = data?.map(item =>
    formatMatchCard({ ...item, frontFaceViewVariant })
  );

  if (sortFn && formattedData) formattedData = formattedData?.sort(sortFn);

  return formattedData;
};

// --------
// TODO delete before the release
// const s3Assets = 'https://c-11-app-assets.s3.me-central-1.amazonaws.com';

// const mockProps = {
//   mainInformation: {
//     above: 'Game Host',
//     central: 'The Palm',
//     beneath: 'Today, 8:00pm',
//   },
//   subInformation: [
//     {
//       text: '02/11',
//       // imgURL: organizer?.imgURL,
//     },
//     { text: `📍 Dubai` },
//     // { text: 'The Palm' },
//   ],
//   otherInformation: [
//     `contact organizer: aaa.bulovic@gmail.com`,
//     `60 minutes`,
//     `7 Aside`,
//   ],
//   eventType: 'Pickup game',
//   eventBadge: { Icon: StarIcon, label: 'Pro' },
//   isPublic: true,
//   coverImgData: {
//     alt: 'grass-field',
//     jpgURL: `${s3Assets}/jpg/grass.jpg`,
//     jpgDesktopURL: `${s3Assets}/jpg/grass-desktop.jpg`,
//     webpURL: `${s3Assets}/webp/grass.webp`,
//     webpDesktopURL: `${s3Assets}/webp/grass-desktop.webp`,
//   },
// };

//
export const getFormattedGameEvents = (data, onCardClick, sortCriteria) => {
  return data?.map(data => {
    const { date, dayInWeek, time } = getMatchDateItems({
      dayKey: data?.matchDate?.dateKey,
      timeslot: data?.matchDate?.timeslot,
    });

    const hostName = `${data?.organizer?.firstName || 'N/A'} ${
      data?.organizer?.lastName || ''
    }`;
    const gameEventProps = {
      mainInformation: {
        above: data?.location,
        central: `${dayInWeek} @ ${time}`,
        beneath: `hosted by ${hostName}`,
        beneathImg: data?.organizer?.image,
      },
      subInformation: [
        {
          text: date,
        },
        { text: `📍 ${data?.city}` },
      ],
      otherInformation: [
        `contact organizer: ${data?.organizer?.contact}`,
        `${data?.duration} minutes`, // TODO format this on BE?
        `${data?.numberOfPlayers} Aside`, // TODO format this on BE?
      ],
      eventType: data?.gameType,
      eventBadge: { Icon: StarIcon, label: data?.skillLevel },
      isPublic: true, // TODO get from BE
      coverImgData: data?.coverImgData,
    };
    const formattedItem = {
      _date: data?.date,
      Component: EventPreviewCard,
      props: {
        ...gameEventProps,
        onClick: () => {
          onCardClick(data);
        },
      },
    };

    return formattedItem;
  });
};

export const getFormattedAllMatches = (data, profileId) =>
  data?.map(item => {
    const status = item?.status;
    let bgVariant;

    if (status === MATCH_SCHEDULED_STATUS) {
      bgVariant = 'neutral';
    } else if (status === MATCH_RESOLVED_STATUS) {
      if (item?.outcome === DRAW) {
        bgVariant = 'draw';
      } else {
        if (item?.homeTeam?._id === profileId) {
          bgVariant = item?.outcome === HOME_TEAM_WIN ? 'win' : 'lose';
        } else {
          bgVariant = item?.outcome === AWAY_TEAM_WIN ? 'win' : 'lose';
        }
      }
    } else {
      bgVariant = 'dark';
    }

    return {
      Component: Match3PartCard,
      props: {
        bgVariant,
        frontFaceViewProps: getStaticMatchFrontFaceViewProps(item),
      },
    };
  });
