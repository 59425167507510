import { Children, useCallback } from 'react';
import PropTypes from 'prop-types';
import './AsideNav.scss';

const className = 'c-AsideNav';

const AsideNav = ({ listItems }) => {
  const renderListItems = useCallback(
    () =>
      Children.toArray(
        listItems.map(({ onClick, label, isActive }) => {
          const activeClassName = isActive ? `${className}__item--active` : '';

          return (
            <li
              className={`${className}__item ${activeClassName}`}
              onClick={onClick}
              key={label}
            >
              {label}
            </li>
          );
        })
      ),
    [listItems]
  );

  return (
    <aside className={className}>
      <ul className={`${className}__list`}>{renderListItems()}</ul>
    </aside>
  );
};

const AsideListItemPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
});

AsideNav.propTypes = {
  listItems: PropTypes.arrayOf(AsideListItemPropTypes),
};

export default AsideNav;
