/* eslint-disable max-len */
import IconTemplate from '../icon-template';

const SphereIcon = () => (
  <IconTemplate>
    <svg
      width='200'
      zoomAndPan='magnify'
      viewBox='0 0 810 809.999993'
      height='200'
      preserveAspectRatio='xMidYMid meet'
      version='1.0'
    >
      <defs>
        <clipPath id='d94f8eeca1'>
          <path
            d='M 169.902344 169.902344 L 640.152344 169.902344 L 640.152344 640.152344 L 169.902344 640.152344 Z M 169.902344 169.902344 '
            clip-rule='nonzero'
          />
        </clipPath>
      </defs>
      <g clip-path='url(#d94f8eeca1)'>
        <path
          fill='#ffffff'
          d='M 405.082031 169.847656 C 275.226562 169.847656 169.953125 275.117188 169.953125 404.972656 C 169.953125 534.828125 275.226562 640.101562 405.082031 640.101562 C 534.9375 640.101562 640.207031 534.828125 640.207031 404.972656 C 640.207031 275.117188 534.9375 169.847656 405.082031 169.847656 '
          fill-opacity='1'
          fill-rule='evenodd'
        />
      </g>
      <path
        fill='#111314'
        d='M 405.082031 177.253906 C 279.316406 177.253906 177.359375 279.207031 177.359375 404.972656 C 177.359375 530.738281 279.316406 632.695312 405.082031 632.695312 C 530.847656 632.695312 632.800781 530.738281 632.800781 404.972656 C 632.800781 279.207031 530.847656 177.253906 405.082031 177.253906 '
        fill-opacity='1'
        fill-rule='evenodd'
      />
      <path
        fill='#ffffff'
        d='M 322.449219 192.710938 C 263.152344 215.804688 215.914062 263.039062 192.816406 322.34375 L 487.710938 617.238281 C 547.015625 594.140625 594.25 546.902344 617.34375 487.605469 L 322.449219 192.710938 '
        fill-opacity='1'
        fill-rule='evenodd'
      />
      <path
        fill='#ffffff'
        d='M 405.082031 253.058594 C 321.1875 253.058594 253.167969 321.078125 253.167969 404.972656 C 253.167969 488.867188 321.1875 556.886719 405.082031 556.886719 C 488.976562 556.886719 556.996094 488.867188 556.996094 404.972656 C 556.996094 321.078125 488.976562 253.058594 405.082031 253.058594 Z M 405.082031 261.4375 C 325.808594 261.4375 261.546875 325.699219 261.546875 404.972656 C 261.546875 484.246094 325.808594 548.507812 405.082031 548.507812 C 484.355469 548.507812 548.617188 484.246094 548.617188 404.972656 C 548.617188 325.699219 484.355469 261.4375 405.082031 261.4375 '
        fill-opacity='1'
        fill-rule='evenodd'
      />
    </svg>
  </IconTemplate>
);

export default SphereIcon;
