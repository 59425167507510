import {
  DATE_YYYY_MONTH_D_FORMAT,
  DAY_FORMAT,
  MONTHS,
  MONTH_NAME_FORMAT,
  NUMERIC_STANDARD_DATE,
} from 'common/constants/dateData';
import dateFormat from 'date-fns/format';

export const formatCountryOptions = countries =>
  countries.map(({ country, name }) => ({ value: country, text: name }));

export const getShortNameMonths = () => MONTHS.map(item => item.slice(0, 3));

export const getDescriptiveDateWithoutYear = date =>
  dateFormat(date, DAY_FORMAT);

export const getDefaultDateFormattedString = date => {
  try {
    return dateFormat(new Date(date), DATE_YYYY_MONTH_D_FORMAT);
  } catch (err) {
    return 'NA';
  }
};

export const getNumericDateString = date =>
  dateFormat(date, NUMERIC_STANDARD_DATE);

export const getMonthName = date => dateFormat(date, MONTH_NAME_FORMAT);

export const getTextFormat = text => [{ text }];

export const maskString = str => {
  const length = str.length;
  const circle = String.fromCharCode(9679);
  let masked = '';

  for (let i = 0; i < length; i++) {
    masked += circle;
  }

  return masked;
};

export const formatKeyValuePairsIntoAPIQuery = items =>
  items?.reduce((curr, active) => {
    const { key, value } = active;
    const apiQueryItem = `${key}=${value}&`;

    return (curr += apiQueryItem);
  }, '?');

export const formatImage = url => ({
  alt: 'c-11 logo',
  mobileSrc: [
    {
      srcSet: url,
      media: '(min-width: 120px)',
    },
  ],
  desktopSrc: [
    {
      srcSet: url,
      media: '(min-width: 400px)',
    },
  ],
});

export const formatImageWithWebp = ({
  jpgURL,
  jpgDesktopURL,
  webpURL,
  webpDesktopURL,
  alt,
}) => ({
  alt,
  mobileSrc: [
    {
      srcSet: webpURL,
      type: 'image/webp',
      media: '(min-width: 120px)',
    },
    {
      srcSet: jpgURL,
      type: 'image/jpg',
      media: '(min-width: 120px)',
    },
  ],
  desktopSrc: [
    {
      srcSet: webpDesktopURL,
      type: 'image/webp',
      media: '(min-width: 400px)',
    },
    {
      srcSet: jpgDesktopURL,
      type: 'image/jpg',
      media: '(min-width: 400px)',
    },
  ],
});
