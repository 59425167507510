import PropTypes from 'prop-types';
import { useCallback } from 'react';
import style from './GameEventsList.module.scss';

const GameEventsList = ({ items }) => {
  const renderItems = useCallback(() => {
    const itemsToRender = items?.map(({ Component, props }) => (
      <div className={style.item}>
        <Component {...props} />
      </div>
    ));

    return <ul className={style.list}>{itemsToRender}</ul>;
  }, [items]);

  return <div>{renderItems()}</div>;
};

const TeamDataPropTypes = {
  symbol: PropTypes.string,
  name: PropTypes.string,
  isPlayerMember: PropTypes.bool,
};

const MatchItemPropTypes = {
  date: PropTypes.string.isRequired,
  homeTeam: PropTypes.shape(TeamDataPropTypes),
  awayTeam: PropTypes.shape(TeamDataPropTypes),
  matchSymbol: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

GameEventsList.defaultProps = {
  items: [],
};

GameEventsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(MatchItemPropTypes)),
};

export default GameEventsList;
