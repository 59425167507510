import { PASSWORD_PATTERN } from 'common/constants/regex-patterns';
import {
  INVALID_PASSWORD_FORMAT_ERR_MSG,
  MISSING_CONFIRMED_PASSWORD_ERR_MSG,
  MISSING_PASSWORD_ERR_MSG,
  PASSWORDS_NOT_MATCHING_ERR_MSG,
} from 'common/constants/validations';

const yup = require('yup');

export const updatePasswordSchema = yup.object().shape({
  password: yup.string().required(MISSING_PASSWORD_ERR_MSG),
  newPassword: yup
    .string()
    .matches(PASSWORD_PATTERN, INVALID_PASSWORD_FORMAT_ERR_MSG)
    .required(MISSING_PASSWORD_ERR_MSG),
  confirmedNewPassword: yup
    .string()
    .required(MISSING_CONFIRMED_PASSWORD_ERR_MSG)
    .oneOf([yup.ref('newPassword')], PASSWORDS_NOT_MATCHING_ERR_MSG),
});

export const deleteProfileSchema = yup.object().shape({
  password: yup.string().required(MISSING_PASSWORD_ERR_MSG),
});
