import PropTypes from 'prop-types';
import { Children, useCallback, useEffect } from 'react';
import style from './StreetV2.module.scss';
import Text from 'common/components/text';
import Tabs from 'features/tabs';
import BlankScreenPlaceholder from 'common/components/blank-screen-placeholder';
import { blankSpaceText } from 'pages/street/constants';
import GoBackIcon from 'common/components/icons/go-back-icon';
import Button, { BUTTON_LIGHT_VARIANT } from 'common/components/button';
import { useCommonClassNames } from 'common/hooks';
import { useMetaContext } from 'contexts/meta';

const StreetV2 = ({
  standardRows,
  players,
  onMatchCardClick,
  goToHandler,
  tabs,
  goBackHandler,
  tips,
}) => {
  const { animationClassName } = useCommonClassNames();
  const getOnClickHandler = useCallback(
    (type, _id, matchData) =>
      matchData
        ? () => onMatchCardClick(matchData)
        : () => {
            goToHandler(type, _id);
          },
    [goToHandler, onMatchCardClick]
  );

  const passOnClickToCards = useCallback(
    rowData => {
      const formattedCards = rowData?.cards?.map(propsData => {
        const onClick = getOnClickHandler(
          propsData?.props?.type,
          propsData?.props?._id,
          propsData?.props?.matchData
        );

        return {
          Component: propsData?.Component,
          props: { ...propsData?.props, onClick },
        };
      });

      return { ...rowData, cards: formattedCards };
    },
    [getOnClickHandler]
  );

  const renderCards = useCallback(
    (data, withFlexDisplay, isSmallCardsRow) => {
      const cards = data?.map(({ Component, props }) => {
        if (props.isPlaceholderCard) {
          return (
            <div className={`${style.cardWrapper}`}>
              <div className={`${style.cardPlaceholder}`}></div>
            </div>
          );
        }

        const onClick = getOnClickHandler(
          props?.frontFaceViewProps?.type,
          props?.frontFaceViewProps?._id,
          props?.frontFaceViewProps?.matchData
        );

        const { frontFaceViewProps, clickViewProps, ...otherProps } = props;
        const dynamicFrontFaceViewProps = { ...frontFaceViewProps, onClick };
        const dynamicClickViewRowsDataProps =
          clickViewProps?.rowsData?.map(passOnClickToCards);
        const dynamicClickViewProps = {
          ...clickViewProps,
          rowsData: dynamicClickViewRowsDataProps,
        };

        const wrapperClassName = withFlexDisplay
          ? style.cardWrapperFlex
          : style.cardWrapper;

        const key = frontFaceViewProps?._id || Math.random() + Math.random();

        return (
          <div className={wrapperClassName} key={key}>
            <Component
              {...otherProps}
              frontFaceViewProps={dynamicFrontFaceViewProps}
              clickViewProps={dynamicClickViewProps}
              isSmallCard={isSmallCardsRow}
            />
          </div>
        );
      });
      return [cards];
    },
    [getOnClickHandler, passOnClickToCards]
  );

  const renderRows = useCallback(
    ({ fixed, nonFixed }) =>
      Children.toArray(
        standardRows?.map(
          ({
            label,
            items,
            isFixedHeightRow,
            symbol,
            rowPositionType,
            isRowRenderStatic,
          }) => {
            const rowWrapperClassName = isFixedHeightRow
              ? style.fixedHeightRowWrapper
              : style.standardRowCardsWrapper;

            const staticRowRenderClassName = isRowRenderStatic
              ? style.staticRowRender
              : '';

            if (items?.length === 0) return null;

            if (items?.length === 1 && rowPositionType !== 'fixed')
              items.push({
                props: {
                  isPlaceholderCard: true,
                },
              });

            const renderSingleRow = () => (
              <div className={`${style.categoryRow}`}>
                {label && (
                  <h2 className={`${style.rowLabel}`}>
                    <Text {...label} />
                    <div className={style.rowUnderline}></div>
                  </h2>
                )}
                <div
                  className={`${rowWrapperClassName} ${staticRowRenderClassName}`}
                >
                  {symbol && (
                    <div className={`${style.labelSymbol}`}>{symbol}</div>
                  )}
                  {isFixedHeightRow && (
                    <div className={style.fixedHeightRowInnerWrapper}>
                      {renderCards(items, true)}
                    </div>
                  )}
                  {!isFixedHeightRow && renderCards(items, false)}
                </div>
              </div>
            );

            if (!items?.length) return null;

            if (fixed && isFixedHeightRow) {
              return renderSingleRow();
            } else if (nonFixed && !isFixedHeightRow) {
              return renderSingleRow();
            } else {
              return null;
            }
          }
        )
      ),
    [renderCards, standardRows]
  );

  const shouldShowBlankScreenPlaceholder = useCallback((data = []) => {
    if (data && data?.length > 0) {
      const [, ...dataToConsider] = data;
      const itemsToConsider = dataToConsider?.reduce((acc, curr) => {
        const anyItems = curr?.items && curr?.items?.length > 0;
        return anyItems ? acc + 1 : acc;
      }, 0);

      return itemsToConsider < 1;
    } else {
      return true;
    }
  }, []);

  const { setNeutralToastMessage } = useMetaContext();

  useEffect(() => {
    tips?.forEach((tip, i) => {
      const timeout = setTimeout(() => {
        setNeutralToastMessage(tip);
        clearTimeout(timeout);
      }, 2000 + i * 2000);
    });
  }, [setNeutralToastMessage, tips]);

  return (
    <div
      className={`${style.wrapper} ${style[`wrapper${animationClassName}`]}`}
    >
      <div className={style.goBackWrapper}>
        <Button
          variant={BUTTON_LIGHT_VARIANT}
          standardWidth
          onClick={goBackHandler}
          withRipples
        >
          <GoBackIcon color='#f1daff' />
          go back
        </Button>
      </div>
      <Tabs tabs={tabs} />
      <div className={style.fixedRowWrapper}>
        {renderRows({ fixed: true, nonFixed: false })}
      </div>
      <div className={style.rowsWrapper}>
        {renderRows({ fixed: false, nonFixed: true })}
      </div>

      {shouldShowBlankScreenPlaceholder(standardRows) && (
        <BlankScreenPlaceholder {...blankSpaceText} />
      )}
    </div>
  );
};

StreetV2.defaultProps = {
  standardRows: [],
  players: [],
  tips: [],
};

StreetV2.propTypes = {
  standardRows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      symbol: PropTypes.any,
      items: PropTypes.array,
      isFixedHeightRow: PropTypes.bool,
    })
  ),
  players: PropTypes.arrayOf(
    PropTypes.shape({
      // TODO: add player shape
    })
  ),
  onMatchCardClick: PropTypes.func.isRequired,
  goToHandler: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  goBackHandler: PropTypes.func.isRequired,
  tips: PropTypes.arrayOf(PropTypes.string),
};

export default StreetV2;
