import AlienIcon from 'features/logo-creator/components/icons/alien-icon';
import ColonyIcon from 'features/logo-creator/components/icons/colony-icon';
import CraterIcon from 'features/logo-creator/components/icons/crater-icon';
import ExplorersIcon from 'features/logo-creator/components/icons/explorers-icon';
import { useCallback, Children } from 'react';

const componentsToPreload = [CraterIcon, AlienIcon, ExplorersIcon, ColonyIcon];

const usePreload = images => {
  const preloadComponents = useCallback(() => {
    const toPreload = Children.toArray(
      componentsToPreload?.map(Component => <Component />)
    );

    return <div>{toPreload}</div>;
  }, []);

  return { preloadComponents };
};

export default usePreload;
