import {
  getFormattedClubs,
  getFormattedGameEvents,
  // getFormattedPlayers,
} from 'features/base-3-part-card/formatters';
import PlayerCardV2 from 'features/player-card-v2';
import { TRANSFER_ACTIVE_TYPE } from 'features/transfer-button/constants';
import TransferRibbon from 'features/transfer-ribbon';
import {
  TRANSFER_IN_TYPE,
  TRANSFER_OUT_TYPE,
} from 'features/transfer-ribbon/constants';

const formatTransferRibbon = data => {
  const player = data?.player;
  const club = data?.club;

  return {
    Component: TransferRibbon,
    props: {
      player: {
        name: `${player?.firstName} ${player?.lastName}`,
        img: player?.img,
        _id: player?._id,
        imageURL: player?.imageURL,
      },
      club: {
        name: club?.name,
        city: club?.city,
        logo: club?.logo,
        imageURL: club?.imageURL,
        _id: club?._id,
      },
      transferType:
        data?.status === TRANSFER_ACTIVE_TYPE
          ? TRANSFER_IN_TYPE
          : TRANSFER_OUT_TYPE,
    },
  };
};

const getFormattedTransfers = data => data?.map(formatTransferRibbon);

export const formatStreetV2Cards = (
  data,
  activeRole,
  activeRoleType,
  onMatchOffer,
  playersShortlistCallbacks,
  clubsShortlistCallbacks,
  selectGameEvent
) => {
  const formattedData = {
    standardRows: [
      {
        symbol: '📰',
        rowPositionType: 'fixed',
        items: getFormattedTransfers(data?.transfers),
        isFixedHeightRow: true,
      },
      {
        label: { data: [{ text: 'Upcoming Game Events' }] },
        items: getFormattedGameEvents(
          data?.gameEvents,
          selectGameEvent,
          'first-date'
        ),
        isRowRenderStatic: true,
      },
      //
      // {
      //   label: { data: [{ text: 'Upcoming Team Games' }] },
      //   items: getFormattedScheduledOrPlayedMatches(
      //     data?.scheduledMatches,
      //     undefined,
      //     'first-date'
      //   ),
      //   isRowRenderStatic: true,
      // },
      // {
      //   label: { data: [{ text: 'Played Team Games' }] },
      //   items: getFormattedScheduledOrPlayedMatches(
      //     data?.playedMatches,
      //     FRONT_FACE_VIEW_LOGOS_VARIANT,
      //     'last-date'
      //   ),
      //   isRowRenderStatic: true,
      // },
      {
        label: { data: [{ text: 'Teams' }] },
        items: getFormattedClubs(
          data?.generalClubs,
          activeRole,
          activeRoleType,
          onMatchOffer,
          false,
          clubsShortlistCallbacks
        ),
      },
      {
        label: { data: [{ text: 'Shortlist Teams' }] },
        items: getFormattedClubs(
          data?.shortlistClubs,
          activeRole,
          activeRoleType,
          onMatchOffer,
          false,
          clubsShortlistCallbacks
        ),
      },
      {
        label: { data: [{ text: 'Players' }] },
        items: data?.generalPlayers?.map(player => {
          return {
            Component: PlayerCardV2,
            props: {
              data: player,
              type: 'player-card', // TODO refactor into constant
            },
          };
        }),
      },
      // {
      //   label: { data: [{ text: 'Players' }] },
      //   items: getFormattedPlayers(
      //     data?.generalPlayers,
      //     activeRole,
      //     activeRoleType,
      //     false,
      //     false,
      //     playersShortlistCallbacks
      //   ),
      // },
      // {
      //   label: { data: [{ text: 'Shortlist Players' }] },
      //   items: getFormattedPlayers(
      //     data?.shortlistPlayers,
      //     activeRole,
      //     activeRoleType,
      //     false,
      //     false,
      //     playersShortlistCallbacks
      //   ),
      // },
    ],
  };

  return formattedData;
};
