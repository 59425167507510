import { apiPatch, apiPost } from './apiWrapper';
import {
  DELETE_PROFILE_ENDPOINT,
  UDPATE_PASSWORD_ENDPOINT,
  UPDATE_EMAIL_ENDPOINT,
} from './constants';

export const updateEmailService = apiPatch(UPDATE_EMAIL_ENDPOINT);

export const updatePasswordService = apiPatch(UDPATE_PASSWORD_ENDPOINT);

export const deleteProfileService = apiPost(DELETE_PROFILE_ENDPOINT);
