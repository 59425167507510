import CreateGameScreenIcon from 'common/components/icons/create-game-screen-icon';
import GamesIcon from 'common/components/icons/games-icon';
import PlayersScreenIcon from 'common/components/icons/players-screen-icon';
import {
  REACT_GA_BACK_TO_STREET_CATALOGUE_EVENT,
  REACT_GA_SELECT_GAMES_SCREEN_EVENT,
  REACT_GA_SELECT_PLAYERS_SCREEN_EVENT,
} from 'common/constants/react-google-analytics/events';
import { useCallback, useMemo, useState } from 'react';
import {
  CREATE_PICKUP_GAME_TEXT,
  CREATE_GAME_STREET_SCREEN,
  GAMES_SCREEN_TEXT,
  GAMES_STREET_DATA_TYPE,
  GAMES_STREET_SCREEN,
  playersScreenStaticProps,
  PLAYERS_STREET_DATA_TYPE,
  PLAYERS_STREET_SCREEN,
} from '../constants';
import ReactGA from 'react-ga';

const useStreetNavigation = () => {
  const [selectedScreen, setSelectedScreen] = useState(null);

  const selectEntranceScreen = useCallback(() => {
    setSelectedScreen(null);
    ReactGA.event(REACT_GA_BACK_TO_STREET_CATALOGUE_EVENT);
  }, []);

  const selectCreateGameScreen = useCallback(() => {
    setSelectedScreen(CREATE_GAME_STREET_SCREEN);
  }, []);

  const selectGamesScreen = useCallback(() => {
    setSelectedScreen(GAMES_STREET_SCREEN);
  }, []);

  // const selectTeamsScreen = useCallback(() => {
  //   setSelectedScreen(TEAMS_STREET_SCREEN);
  // }, []);

  const selectPlayersScreen = useCallback(() => {
    setSelectedScreen(PLAYERS_STREET_SCREEN);
  }, []);

  const streetNavigationPropsSet = useMemo(
    () => [
      {
        ...CREATE_PICKUP_GAME_TEXT,
        Icon: CreateGameScreenIcon,
        isComingSoon: false,
        onClick: () => {
          selectCreateGameScreen();
          // ReactGA.event(REACT_GA_CREATE_GAME_EVENT) // TODO
        },
      },
      {
        ...GAMES_SCREEN_TEXT,
        Icon: GamesIcon,
        onClick: () => {
          selectGamesScreen();
          ReactGA.event(REACT_GA_SELECT_GAMES_SCREEN_EVENT);
        },
        dataType: GAMES_STREET_DATA_TYPE,
      },
      {
        ...playersScreenStaticProps,
        Icon: PlayersScreenIcon,
        onClick: () => {
          selectPlayersScreen();
          ReactGA.event(REACT_GA_SELECT_PLAYERS_SCREEN_EVENT);
        },
        dataType: PLAYERS_STREET_DATA_TYPE,
      },
      // NOTE FAS switched off Teams Screen from the Catalogue
      // {
      //   ...TEAMS_SCREEN_TEXT,
      //   Icon: TeamsIcon,
      //   onClick: () => {
      //     selectTeamsScreen();
      //     ReactGA.event(REACT_GA_SELECT_TEAMS_SCREEN_EVENT);
      //   },
      //   dataType: CLUBS_STREET_DATA_TYPE,
      // },
    ],
    [selectCreateGameScreen, selectGamesScreen, selectPlayersScreen]
  );

  return {
    selectedScreen,
    selectEntranceScreen,
    streetNavigationPropsSet,
  };
};

export default useStreetNavigation;
