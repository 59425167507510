import { useCallback, useState } from 'react';

import style from '../Navbar.module.scss';

const useClassName = () => {
  const [visibilityTopClassName, setVisibilityTopClassName] = useState(
    style['visibility-on--top']
  );
  const [visibilityBottomClassName, setVisibilityBottomClassName] = useState(
    style['visibility-on--bottom']
  );

  const setNavbarVisibilityOnClassName = useCallback(() => {
    setVisibilityTopClassName(style['visibility-on--top']);
    setVisibilityBottomClassName(style['visibility-on--bottom']);
  }, []);

  const setNavbarVisibilityOffClassName = useCallback(() => {
    setVisibilityTopClassName(style['visibility-off--top']);
    setVisibilityBottomClassName(style['visibility-off--bottom']);
  }, []);

  return {
    visibilityTopClassName,
    visibilityBottomClassName,
    setNavbarVisibilityOnClassName,
    setNavbarVisibilityOffClassName,
  };
};

export default useClassName;
