import Footer from 'common/components/footer';
import PublicNav from 'common/components/public-nav';
import Banner from './components/banner';
import CTASection from './components/cta-section';
import InfoSectionsV2 from './components/info-sections-v2';
import PlayModesSection from './components/play-modes-section';
import { staticProps } from './constants';
import style from './LandingPage.module.scss';
import Form from 'common/components/form';
import Modal, { DARK_THEME } from 'features/modal';
import { useProps } from './hooks';
import { useWindowSizeContext } from 'contexts/window-size';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { REACT_GA_STREET_AND_LANDING_PAGE } from 'common/constants/react-google-analytics/pages';

const LandingPage = () => {
  const {
    loginModalActiveMode,
    loginCTAProps,
    signUpCTAPropsBanner,
    signUpCTAPropsCTASection,
    getInputProps,
    confirmLogin,
    loginModalProps,
  } = useProps();

  const { isMobileView } = useWindowSizeContext();

  useEffect(() => {
    ReactGA.pageview(REACT_GA_STREET_AND_LANDING_PAGE);
  }, []);

  return (
    <div className={style.wrapper}>
      <PublicNav loginCTA={loginCTAProps} />

      {/* Content */}
      <div>
        <Banner
          {...staticProps.banner}
          signUpCTA={signUpCTAPropsBanner}
          description={
            isMobileView
              ? staticProps.mainMessageSection.mobile
              : staticProps.mainMessageSection.desktop
          }
        />

        <InfoSectionsV2 sectionsSetProps={staticProps.infoSectionV2} />

        <PlayModesSection {...staticProps.playModesSection} />

        {/* <NFTSection {...staticProps.nftSection} /> */}

        {/* The Conclude Message section */}
        <CTASection
          {...staticProps.ctaSection}
          cta={signUpCTAPropsCTASection}
        />
      </div>

      <Footer />

      {/* Modals -- */}
      {!!loginModalActiveMode && (
        <Modal {...loginModalProps} theme={DARK_THEME}>
          <Form inputs={getInputProps()} onConfirm={confirmLogin} />
        </Modal>
      )}
    </div>
  );
};

export default LandingPage;
