import SearchCards from './components/search-cards';
import Tags from './components/tags';
import { useProps } from './hooks';
import style from './Search.module.scss';

const Search = () => {
  const {
    searchQuery,
    onSearchQueryChange,
    clearSearchQueries,
    tagOptions,
    searchItemsData,
    searchOpened,
    openSearch,
  } = useProps();

  return (
    <div onClick={e => e.stopPropagation()} className={style.wrapper}>
      <div className={style.searchInputWrapper}>
        <input
          type='text'
          placeholder='search'
          value={searchQuery}
          onClick={e => {
            e.stopPropagation();
            openSearch();
          }}
          className={style.input}
          onChange={onSearchQueryChange}
        />
        {searchOpened && <button onClick={clearSearchQueries}>x</button>}
      </div>

      {searchOpened && (
        <div className={style.dropdown}>
          <div className={style.tags}>
            <Tags tagGroups={tagOptions} />
          </div>

          <div className={style.cards}>
            <SearchCards items={searchItemsData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
