import PropTypes from 'prop-types';
import { useCallback } from 'react';
import style from './MatchList.module.scss';
import PersonIcon from 'common/components/icons/person-icon';
import Image from 'common/components/image';
import { formatImage } from 'common/utils/formatting';

const MatchList = ({ items, isDesktopView }) => {
  const renderTeamItem = useCallback(
    ({ name, imageURL, isPlayerMember }, reverseLogo) => {
      const renderLogo = () =>
        imageURL && (
          <div className={style.logoWrapper}>
            <Image {...formatImage(imageURL)} />
          </div>
        );

      const clubReverseClassName = reverseLogo ? style['club--reverse'] : '';

      return (
        <div className={`${style.club} ${clubReverseClassName}`}>
          <div className={`${style['club--name-wrapper']}`}>
            {(reverseLogo || !isDesktopView) && renderLogo()}
            <label>{name}</label>
            {isPlayerMember && <PersonIcon />}
            {!reverseLogo && isDesktopView && renderLogo()}
          </div>
        </div>
      );
    },
    [isDesktopView]
  );
  const renderSingleItem = useCallback(
    ({ date, homeTeam, awayTeam, onClick, matchSymbol }) => (
      <li className={style.item}>
        <button onClick={onClick}>
          <div className={style.teamsWrapper}>
            <div className={style['teamsWrapper--homeTeamWrapper']}>
              {renderTeamItem(homeTeam)}
            </div>
            {isDesktopView && (
              <span className={style['teamsWrapper--matchSymbol']}>
                {matchSymbol}
              </span>
            )}
            <div className={style['teamsWrapper--awayTeamWrapper']}>
              {renderTeamItem(awayTeam, true)}
            </div>
          </div>

          {!isDesktopView && (
            <div className={style['match-result-wrapper']}>
              <span className={style['result-label']}>result: </span>
              <span className={style['teamsWrapper--matchSymbol']}>
                {matchSymbol}
              </span>
            </div>
          )}
          <span className={style['item--date']}>{date}</span>
        </button>
      </li>
    ),
    [isDesktopView, renderTeamItem]
  );

  const renderItems = useCallback(() => {
    const itemsToRender = items?.map(renderSingleItem);

    return <ul className={style.list}>{itemsToRender}</ul>;
  }, [items, renderSingleItem]);

  return <div>{renderItems()}</div>;
};

const TeamDataPropTypes = {
  symbol: PropTypes.string,
  name: PropTypes.string,
  isPlayerMember: PropTypes.bool,
};

const MatchItemPropTypes = {
  date: PropTypes.string.isRequired,
  homeTeam: PropTypes.shape(TeamDataPropTypes),
  awayTeam: PropTypes.shape(TeamDataPropTypes),
  matchSymbol: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

MatchList.defaultProps = {
  items: [],
};

MatchList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(MatchItemPropTypes)),
  isDesktopView: PropTypes.bool,
};

export default MatchList;
