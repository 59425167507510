import { ClubLogoPropTypes } from 'common/components/generated-logo/GeneratedLogo';
import Image, { ImagePropTypes } from 'common/components/image';
import SmartLogo from 'common/components/smart-logo';
import Text from 'common/components/text';
import { useNavigation } from 'common/hooks';
import { formatImage } from 'common/utils/formatting';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { TRANSFER_IN_TYPE, TRANSFER_OUT_TYPE } from './constants';
import './TransferRibbon.scss';

const className = 'c-TransferRibbon';

const TransferRibbon = ({ player, club, transferType }) => {
  const { navigateToClubPage, navigateToUserPage } = useNavigation();

  const handleClickOnPlayer = useCallback(() => {
    navigateToUserPage(player?._id);
  }, [navigateToUserPage, player?._id]);

  const handleClickOnClub = useCallback(() => {
    navigateToClubPage(club?._id);
  }, [club?._id, navigateToClubPage]);

  return (
    <div className={className}>
      <div
        onClick={handleClickOnPlayer}
        className={`${className}__ribbon-section ${className}__ribbon-section--link`}
      >
        {player?.imageURL && <Image {...formatImage(player?.imageURL)} />}
        <Text data={[{ text: player?.name, isBolder: true }]} />
      </div>
      <div className={`${className}__ribbon-section`}>
        <Text data={[{ text: ` ${transferType}` }]} />
      </div>
      <div
        onClick={handleClickOnClub}
        className={`${className}__ribbon-section ${className}__ribbon-section--link`}
      >
        <Text
          data={[{ text: ` ${club?.name}, ${club?.city}`, isBolder: true }]}
        />
        <SmartLogo clubLogo={club?.imageURL} />
      </div>
    </div>
  );
};

TransferRibbon.propTypes = {
  player: PropTypes.shape({
    img: PropTypes.shape(ImagePropTypes),
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    imageURL: PropTypes.string,
  }),
  club: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    logo: PropTypes.shape(ClubLogoPropTypes),
    imageURL: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }),
  transferType: PropTypes.oneOf([TRANSFER_IN_TYPE, TRANSFER_OUT_TYPE]),
};

export default TransferRibbon;
