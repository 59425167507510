import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ScreenCardV2, { ScreenCardV2PropTypes } from '../screen-card-v2';
import style from './Catalogue.module.scss';

const Catalogue = ({ screenCards, loadData }) => {
  const renderScreenCards = useCallback(
    () =>
      screenCards?.map((item, i) => (
        <div className={style.item}>
          <ScreenCardV2
            {...item}
            onClick={() => {
              item?.onClick();
              if (item?.dataType) loadData(item?.dataType);
            }}
            coverVariant={i}
            key={item?.name}
          />
        </div>
      )),
    [loadData, screenCards]
  );

  return <div className={style.wrapper}>{renderScreenCards()}</div>;
};

Catalogue.defaultProps = {
  screenCards: [],
};

Catalogue.propTypes = {
  screenCards: PropTypes.arrayOf(ScreenCardV2PropTypes),
  loadData: PropTypes.func.isRequired,
};

export default Catalogue;
