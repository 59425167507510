import Form from 'common/components/form';
import { useActiveNavItem, useCommonClassNames } from 'common/hooks';
import { useAuthContext } from 'contexts/auth';
import { useWindowSizeContext } from 'contexts/window-size';
import Modal, { DARK_THEME } from 'features/modal';
import { PrivatePageWrapper } from 'features/page-wrapper';
import React, { useCallback, useEffect, useState } from 'react';
import {
  BIO_VIEW,
  CLUBS_VIEW,
  editBioModalTitle,
  MY_GAME_EVENTS,
  staticProps,
} from './constants';
import SignatureSettings from './components/signature-settings';
import BasePageTemplate from 'common/components/base-page-template';
import { useModalAndTogglers, usePropsV2 } from './hooks';
import ActiveSection from './components/active-section';
import style from './Profile.module.scss';
import ReactGA from 'react-ga';
import { REACT_GA_USER_PROFILE_PAGE } from 'common/constants/react-google-analytics/pages';
import { REACT_GA_EDIT_PROFILE_BIO_MODAL } from 'common/constants/react-google-analytics/modals';
import {
  REACT_GA_BIO_TAB_SELECT_GAME_EVENTS_PAGE_EVENT,
  REACT_GA_BIO_TAB_SELECT_PROFILE_PAGE_EVENT,
} from 'common/constants/react-google-analytics/events';
import PlayerForm from 'features/player-form';
import ButtonFloating from 'common/components/button-floating';
import Banner from 'common/components/banner';

const Profile = () => {
  const { isProfileOwner } = useAuthContext();

  const { isMobileView } = useWindowSizeContext();

  const {
    isSignatureSettingsExpanded,
    toggleSignatureSettings,
    isBioModalOpened,
    closeModal,
    openBioModal,
    modalOpened,

    // matches
    // selectedClubForMatchOffer,
    // setSelectedClubForMatchOffer,
  } = useModalAndTogglers();

  const { animationClassName } = useCommonClassNames(modalOpened);

  const {
    initSignatureForm,
    signatureSaveCTAProps,
    signatureSettingsCTAProps,
    getSignatureInputProps,
    refreshSignatureFormFieldValues,

    initBioForm,
    bioItems,
    refreshFormFieldValues,
    getInputProps,
    confirmBioUpdates,
    // pageLevelTransferButtonProps,
    headingProps,
    clubsDataProps,
    matchItemProps,

    getActiveTabFromURL,

    userProfile,
  } = usePropsV2({
    closeModal,
  });

  const ASIDE_NAV_ITEMS = [
    {
      item: BIO_VIEW,
      cb: () => {
        ReactGA.event(REACT_GA_BIO_TAB_SELECT_PROFILE_PAGE_EVENT);
      },
    },
    {
      item: MY_GAME_EVENTS,
      cb: () => {
        ReactGA.event(REACT_GA_BIO_TAB_SELECT_GAME_EVENTS_PAGE_EVENT);
      },
    },
  ];

  const { getNavItemsList, activeNavItem } = useActiveNavItem(
    ASIDE_NAV_ITEMS,
    getActiveTabFromURL
  );

  useEffect(() => {
    refreshFormFieldValues(initBioForm);
    refreshSignatureFormFieldValues(initSignatureForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initBioForm]);

  // NOTE switching off signature settings
  const asideSettingsToRender = useCallback(
    () =>
      isProfileOwner &&
      false && (
        <SignatureSettings
          onToggle={toggleSignatureSettings}
          active={userProfile?.signatureType}
          isExpanded={isSignatureSettingsExpanded}
          label={staticProps.signatureSettings.label}
          ctaProps={signatureSettingsCTAProps}
          onSave={signatureSaveCTAProps}
          inputs={getSignatureInputProps()}
        />
      ),
    [
      getSignatureInputProps,
      isProfileOwner,
      isSignatureSettingsExpanded,
      signatureSaveCTAProps,
      signatureSettingsCTAProps,
      toggleSignatureSettings,
      userProfile?.signatureType,
    ]
  );

  const getFilteredNavItemsList = useCallback(() => {
    const list = getNavItemsList()
      .map(item => {
        if (
          item.label === CLUBS_VIEW &&
          (clubsDataProps?.length === 0 || !clubsDataProps)
        ) {
          return null;
        } else {
          return item;
        }
      })
      .filter(Boolean);

    return list;
  }, [clubsDataProps, getNavItemsList]);

  useEffect(() => {
    ReactGA.pageview(REACT_GA_USER_PROFILE_PAGE);
  }, []);

  // TODO subscribe to game events after the games tab is updated

  const [profileSetupInProgress, setProfileSetupInProgress] = useState(false);
  return (
    <PrivatePageWrapper>
      {isProfileOwner && (
        <ButtonFloating onClick={() => setProfileSetupInProgress(true)} />
      )}
      <Banner img={staticProps.playerBannerImgProps} />
      <div className={`${style[`wrapper${animationClassName}`]}`}>
        <BasePageTemplate
          headingProps={headingProps}
          theme={userProfile?.color}
          asideNavItems={getFilteredNavItemsList()}
          asideSettingsToRender={asideSettingsToRender}
        >
          <ActiveSection
            activeNavItem={activeNavItem}
            isProfileOwner={isProfileOwner}
            infoProps={{
              tooltip: 'edit bio',
              openBioModal,
              bioItems,
            }}
            clubsDataProps={clubsDataProps}
            matchItemProps={matchItemProps}
            userProfile={userProfile}
          />
        </BasePageTemplate>
      </div>

      {isBioModalOpened() && (
        <Modal
          fullScreen={isMobileView}
          title={editBioModalTitle}
          onClose={closeModal}
          variant='center'
          theme={DARK_THEME}
          reactGAModalView={REACT_GA_EDIT_PROFILE_BIO_MODAL}
        >
          <Form inputs={getInputProps()} onConfirm={confirmBioUpdates} />
        </Modal>
      )}
      {profileSetupInProgress && (
        <PlayerForm
          onClose={() => {
            setProfileSetupInProgress(false);
          }}
        />
      )}
    </PrivatePageWrapper>
  );
};

export default Profile;
