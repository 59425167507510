import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { userColors } from 'common/constants/users';
import AsideNav from '../aside-nav';
import HeroHeading from '../hero-heading';
import { ImagePropTypes } from '../image';
import './BasePageTemplate.scss';

const className = 'c-BasePageTemplate';

const BasePageTemplate = ({
  headingProps,
  theme,
  asideNavItems,
  asideSettingsToRender,
  children,
  customClassNames,
}) => {
  const { customContentClassName, customInnerContentClassName } =
    customClassNames;
  const wrapperClassName = useMemo(
    () => (theme ? `${className}__wrapper--${theme}` : `${className}__wrapper`),
    [theme]
  );

  return (
    <div className={wrapperClassName}>
      <HeroHeading {...headingProps} />

      {/* Content */}
      <div className={`${className}__content ${customContentClassName}`}>
        <div>
          {asideNavItems && <AsideNav heading={''} listItems={asideNavItems} />}
          {asideSettingsToRender()}
        </div>
        <section className={`${className}__mainContent`}>
          <div
            className={`${className}__innerContentWrapper ${customInnerContentClassName}`}
          >
            {children}
          </div>
        </section>
      </div>
    </div>
  );
};

BasePageTemplate.defaultProps = {
  asideSettingsToRender: () => null,
  signatureToRender: () => null,
  customClassNames: {
    customContentClassName: '',
    customInnerContentClassName: '',
  },
};

const BasePageTemplateHeadingPropTypes = {
  img: PropTypes.shape(ImagePropTypes),
  heading: PropTypes.string,
  subtext: PropTypes.string,
  imageUploadProps: PropTypes.object,
};

BasePageTemplate.propTypes = {
  theme: PropTypes.oneOf(userColors),
  headingProps: PropTypes.shape(BasePageTemplateHeadingPropTypes),
  asideNavItems: PropTypes.shape({}),
  asideSettingsToRender: PropTypes.func,
  customClassNames: PropTypes.object,
};

export default BasePageTemplate;
