/* eslint-disable max-len */
import PropTypes from 'prop-types';

const AddPhotoIcon = ({ color }) => (
  <svg
    enable-background='new 0 0 24 24'
    height='24px'
    viewBox='0 0 24 24'
    width='24px'
    fill={color}
  >
    <rect fill='none' height='24' width='24' />
    <path d='M3,4V1h2v3h3v2H5v3H3V6H0V4H3z M6,10V7h3V4h7l1.83,2H21c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V10H6z M13,19c2.76,0,5-2.24,5-5s-2.24-5-5-5s-5,2.24-5,5S10.24,19,13,19z M9.8,14c0,1.77,1.43,3.2,3.2,3.2s3.2-1.43,3.2-3.2 s-1.43-3.2-3.2-3.2S9.8,12.23,9.8,14z' />
  </svg>
);

AddPhotoIcon.defaultProps = {
  color: '#000000',
};

AddPhotoIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AddPhotoIcon;
