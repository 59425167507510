import { useState, useCallback } from 'react';

// const itemsArg = [String, String, { item: String, cb: Func }]

const useActiveNavItem = (items, defaultActiveNavItem) => {
  const [activeNavItem, setActiveNavItem] = useState(defaultActiveNavItem);

  const selectSettingsItem = useCallback(
    settingsItem => () => {
      if (typeof settingsItem === 'object') {
        setActiveNavItem(settingsItem?.item);
        if (settingsItem?.cb) settingsItem?.cb();
      } else {
        setActiveNavItem(settingsItem);
      }
    },
    []
  );

  const getNavItemsList = useCallback(
    () =>
      items.map(item => ({
        label: typeof item === 'string' ? item : item?.item,
        onClick: selectSettingsItem(item),
        isActive:
          typeof item === 'string'
            ? item === activeNavItem
            : item?.item === activeNavItem,
      })),
    [activeNavItem, items, selectSettingsItem]
  );

  return {
    activeNavItem,
    getNavItemsList,
  };
};

export default useActiveNavItem;
