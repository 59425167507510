import GameEventPage from 'pages/game-event-page';
import MatchPage from 'pages/match-page';
import Profile from 'pages/profile';
import Settings from 'pages/settings';
import Street from 'pages/street';
import commonPages from './common';
import {
  GAME_EVENT_PAGE_PATH,
  MATCH_PAGE_PATH,
  SETTINGS_PATH,
  STREET_PATH,
  USER_PROFILE_MY_GAMES_PATH,
  USER_PROFILE_PATH,
} from './constants';

const data = [
  {
    path: STREET_PATH,
    element: <Street />,
  },
  ...commonPages,
  {
    path: SETTINGS_PATH,
    element: <Settings />,
  },
  {
    path: USER_PROFILE_PATH,
    element: <Profile />,
  },
  {
    path: USER_PROFILE_MY_GAMES_PATH,
    element: <Profile />,
  },
  // NOTE FAS switching off Club page
  // {
  //   path: CLUB_PAGE_PATH,
  //   element: <Club />,
  // },
  {
    path: MATCH_PAGE_PATH,
    element: <MatchPage />,
  },
  {
    path: GAME_EVENT_PAGE_PATH,
    element: <GameEventPage />,
  },
];

export default data;
