import { uploadClubProfileImage } from 'common/api/clubsService';
import { uploadUserProfileImage } from 'common/api/userService';
import {
  CLUB_PROFILE_IMAGE_UPLOAD_TYPE,
  USER_PROFILE_IMAGE_UPLOAD_TYPE,
} from '../constants';

export const uploadServices = {
  [USER_PROFILE_IMAGE_UPLOAD_TYPE]: uploadUserProfileImage,
  [CLUB_PROFILE_IMAGE_UPLOAD_TYPE]: uploadClubProfileImage,
};
