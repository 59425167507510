import EndClubMembershipIcon from 'common/components/icons/end-club-membership-icon';
import PendingIcon from 'common/components/icons/pending-icon';
import TransferOfferIcon from 'common/components/icons/transfer-offer-icon';
import { useUserSessionContext } from 'contexts/user-session';
import { useMemo } from 'react';
import {
  roleTypeMapping,
  TRANFER_BUTTON_CANCEL_OFFER_TOOLTIP,
  TRANSFER_BUTTON_CANCEL_OFFER_TYPE,
  TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TOOLTIP,
  TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TYPE,
  TRANSFER_BUTTON_MAKE_OFFER_TOOLTIP,
  TRANSFER_BUTTON_MAKE_OFFER_TYPE,
} from '../constants';
import useTransfers from './useTransfers';

const useProps = ({
  clubId,
  playerId,
  activeMembership,
  pendingTransferOffer,
}) => {
  const { activeRole } = useUserSessionContext();
  const { makeTransferOffer, cancelTransferOffer, endClubMembership } =
    useTransfers();

  const transferButtonProps = useMemo(
    () => ({
      [TRANSFER_BUTTON_MAKE_OFFER_TYPE]: {
        tooltip: TRANSFER_BUTTON_MAKE_OFFER_TOOLTIP,
        onClick: () => {
          makeTransferOffer({
            club: clubId,
            player: playerId,
            roleType: roleTypeMapping[activeRole?.type],
          });
        },
        renderIcon: () => <TransferOfferIcon />,
      },
      [TRANSFER_BUTTON_CANCEL_OFFER_TYPE]: {
        tooltip: TRANFER_BUTTON_CANCEL_OFFER_TOOLTIP,
        onClick: () => {
          cancelTransferOffer(pendingTransferOffer?.id);
        },
        renderIcon: () => <PendingIcon />,
      },
      [TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TYPE]: {
        tooltip: TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TOOLTIP,
        onClick: () => {
          // TODO add confirm modal for this
          endClubMembership(clubId, playerId);
        },
        renderIcon: () => <EndClubMembershipIcon />,
      },
    }),
    [
      activeRole?.type,
      cancelTransferOffer,
      clubId,
      endClubMembership,
      makeTransferOffer,
      pendingTransferOffer?.id,
      playerId,
    ]
  );

  const buttonType = useMemo(
    () =>
      activeMembership
        ? TRANSFER_BUTTON_END_CLUB_MEMBERSHIP_TYPE
        : pendingTransferOffer
        ? TRANSFER_BUTTON_CANCEL_OFFER_TYPE
        : TRANSFER_BUTTON_MAKE_OFFER_TYPE,
    [activeMembership, pendingTransferOffer]
  );

  const renderProps = useMemo(
    () => ({
      renderIcon: transferButtonProps[buttonType]?.renderIcon,
      onClick: transferButtonProps[buttonType]?.onClick,
      tooltip: transferButtonProps[buttonType]?.tooltip,
    }),
    [buttonType, transferButtonProps]
  );

  return {
    renderProps,
  };
};

export default useProps;
