import ElementWithTooltip from 'common/components/element-with-tooltip';
import PropTypes from 'prop-types';
import './FeatureActionButton.scss';

const className = 'c-FeatureActionButton';

const FeatureActionButton = ({ renderIcon, onClick, tooltip }) => (
  <div className={className}>
    <ElementWithTooltip tooltip={tooltip}>
      <div onClick={onClick} className={`${className}__innerWrapper`}>
        {renderIcon()}
      </div>
    </ElementWithTooltip>
  </div>
);

FeatureActionButton.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default FeatureActionButton;
